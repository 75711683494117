import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {AppConstants} from '../../app-config/app-constants';
declare function $(s: string);
@Component({
  selector: 'app-test-app',
  templateUrl: './test-app.component.html',
  styleUrls: ['./test-app.component.css']
})
export class TestAppComponent implements OnInit {

  constructor(
    public commonService: CommonService,
    public appConstants: AppConstants
  ) { }
  datePicker = new FormControl('');
  btnLoading: boolean = false;
  tableData: any = [];
  ngOnInit(): void {
    this.fetchTableData();
  }
  fetchTableData () {
    // for (let i=1; i < 50; i++) {
    //   this.tableData.push({name: "Name "+i, email: "name_"+i+"@gmail.com", phone: "78653214"+i, address: "Panna MP", status: "Active"});
    // }
   this.commonService.fetchUsersTest({dbname: this.appConstants.DATABASE}).subscribe(
      Response => {
        console.log("apiResponse :", Response);
        const apiResponse: any = Response;
        for (let data of apiResponse.data) {
          this.tableData.push({name: data.first_name, email: data.user_email, phone: "786532140", address: "Panna MP", status: "Active"});
        }
      }
    );

  }
  checkFunction() {
    console.log("datePicker 1:", this.datePicker.value);
    console.log("datePicker 2:", $('#datePicker').val());
  }
}
