<div class="nk-sidebar-element nk-sidebar-head">
  <div class="nk-sidebar-brand">
    <a href="admin/properties" class="logo-link nk-sidebar-logo">
      <!-- <img class="logo-light logo-img" src="assets/images/logo.png" srcset="assets/images/logo2x.png 2x" alt="logo">
      <img class="logo-dark logo-img" src="assets/images/logo-dark.png" srcset="assets/images/logo-dark2x.png 2x" alt="logo-dark"> -->
      <img class="logo-light logo-img logo-img-lg" style="width: 150px;" src="assets/images/logo2x.png" srcset="assets/images/logo2x.png 2x" alt="logo">
      <img class="logo-dark logo-img" src="assets/images/logo2x.png" srcset="assets/images/logo2x.png 2x" alt="logo-dark">
    </a>
  </div>
  <div class="nk-menu-trigger mr-n2">
    <a href="javascript:void(0)" class="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em class="icon ni ni-arrow-left"></em></a>
  </div>
</div><!-- .nk-sidebar-element -->
<div class="nk-sidebar-element">
  <div class="nk-sidebar-content">
    <div class="nk-sidebar-menu" data-simplebar>
      <ul class="nk-menu">
        <li class="nk-menu-item">
          <a href="admin/dashboard" class="nk-menu-link">
            <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
            <span class="nk-menu-text">{{'Dashboard' | translate}}</span>
          </a>
        </li>
        <li class="nk-menu-item">
          <a class="nk-menu-link" href="admin/properties">
            <span class="nk-menu-icon"><em class="icon ni ni-building-fill"></em></span>
            <span class="nk-menu-text">{{'Properties' | translate}}</span>
          </a>
        </li><!-- .nk-menu-item -->
        <li class="nk-menu-item">
          <a href="admin/tenants" class="nk-menu-link">
            <span class="nk-menu-icon"><em class="icon ni ni-users-fill"></em></span>
            <span class="nk-menu-text">{{'Tenants' | translate}}</span>
          </a>
        </li><!-- .nk-menu-item -->
        <li class="nk-menu-item">
          <a href="admin/rent/0/0" class="nk-menu-link">
            <span class="nk-menu-icon"><em class="icon ni ni-coin-alt-fill"></em></span>
            <span class="nk-menu-text">{{'Rent' | translate}}</span>
          </a>
        </li><!-- .nk-menu-item -->
        <li class="nk-menu-item">
          <a href="admin/expense" class="nk-menu-link">
            <span class="nk-menu-icon"><em class="icon ni ni-coins"></em></span>
            <span class="nk-menu-text">{{'Expenses' | translate}}</span>
          </a>
        </li><!-- .nk-menu-item -->
        <li class="nk-menu-item" style="display: none;">
          <a href="admin/utility" class="nk-menu-link">
            <span class="nk-menu-icon"><em class="icon ni ni-money"></em></span>
            <span class="nk-menu-text">{{'Utility' | translate}}</span>
          </a>
        </li><!-- .nk-menu-item -->
        <li class="nk-menu-item">
          <a href="admin/settings" class="nk-menu-link">
            <span class="nk-menu-icon"><em class="icon ni ni-opt-alt-fill"></em></span>
            <span class="nk-menu-text">{{'Settings' | translate}}</span>
          </a>
        </li><!-- .nk-menu-item -->
      </ul><!-- .nk-menu -->
    </div><!-- .nk-sidebar-menu -->
  </div><!-- .nk-sidebar-content -->
</div><!-- .nk-sidebar-element -->
