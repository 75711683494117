import {FormGroup} from '@angular/forms';
import {split} from 'ts-node';
import { HttpUrlEncodingCodec } from '@angular/common/http';

export class CommonHelper {

  codec = new HttpUrlEncodingCodec;

  public randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  public convertToStringByKey (key, data) {
    if (data != null && data != "" && data.length > 0) {
      const dataArray = [];
      data.forEach(dataValue => {
        dataArray.push(dataValue[key]);
      })
      const dataString = dataArray.toString();
      return dataString;
    }
    else {
      return "0";
    }
  }
  public getNameThumbnail (value: string) {
      const firstChar = value.charAt(0);
      const valSplit = value.split(" ");
      let secondChar = "";
      if (valSplit.length > 1)
        secondChar = valSplit[1].charAt(0);
      const finalChar = firstChar+secondChar;
      return finalChar.toUpperCase();
  }
  public getThumbnailBG (value: string) {
    let firstChar = value.charAt(0);
    firstChar = firstChar.toUpperCase();
    if (firstChar === "A" || firstChar === "H" || firstChar === "P" || firstChar === "W")
      return "bg-primary";
    else if (firstChar === "B" || firstChar === "J" || firstChar === "Q" || firstChar === "X")
      return "bg-success";
    else if (firstChar === "C" || firstChar === "K" || firstChar === "R" || firstChar === "Y")
      return "bg-info";
    else if (firstChar === "D" || firstChar === "L" || firstChar === "S" || firstChar === "Z")
      return "bg-danger";
    else if (firstChar === "E" || firstChar === "M" || firstChar === "T")
      return "bg-purple";
    else if (firstChar === "F" || firstChar === "N" || firstChar === "U")
      return "bg-dark";
    else if (firstChar === "G" || firstChar === "O" || firstChar === "V")
      return "bg-warning";
    else
      return "bg-primary-dim";
  }
  public getMonths () {
      return [
        {id: "01", name: "January"},
        {id: "02", name: "February"},
        {id: "03", name: "March"},
        {id: "04", name: "April"},
        {id: "05", name: "May"},
        {id: "06", name: "June"},
        {id: "07", name: "July"},
        {id: "08", name: "August"},
        {id: "09", name: "September"},
        {id: "10", name: "October"},
        {id: "11", name: "November"},
        {id: "12", name: "December"}
        ];
  }
  public getMonthsWithoutZero () {
      const monthList =  [
        {id: "1", name: "January"},
        {id: "2", name: "February"},
        {id: "3", name: "March"},
        {id: "4", name: "April"},
        {id: "5", name: "May"},
        {id: "6", name: "June"},
        {id: "7", name: "July"},
        {id: "8", name: "August"},
        {id: "9", name: "September"},
        {id: "10", name: "October"},
        {id: "11", name: "November"},
        {id: "12", name: "December"}
        ];
      return monthList;
  }
  public getYears() {
    const yearArray = [];
    const currentYear = new Date().getFullYear();
    for (let i = 2018; i <= currentYear+1; i++) {
      yearArray.push({id: i, name: i});
    }
    return yearArray;
  }
  public getMonthName (number) {
    const monthObject = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    };
    const monthNumber = number.split(",");
    const monthArray = [];
    monthNumber.forEach(value => {
      monthArray.push(monthObject[parseInt(value)])
    });
    return monthArray.toString();
  }
  confirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  checkElementInArray(ele, value) {
    if (value == null)
      return  false;
    const valueArray = value.split(",");
    return !!valueArray.includes(ele);
  }
  public getSingleMonthName (number) {
    number  = parseInt(number);
    const monthObject = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    };
    return monthObject[number];
  }
  public encodeURIParam(param) {
    return btoa(param);
  }
  public decodeURIParam(param) {
    return atob(param);
  }
  public getFileExtentionByType(fileType) {
    const typeArray: any = {
                      hqx:['application/mac-binhex40'],
                      cpt:['application/mac-compactpro'],
                      csv:['text/x-comma-separated-values', 'text/comma-separated-values', 'application/octet-stream', 'application/vnd.ms-excel', 'application/x-csv', 'text/x-csv', 'text/csv', 'application/csv', 'application/excel', 'application/vnd.msexcel'],
                      bin:['application/macbinary'],
                      dms:['application/octet-stream'],
                      lha:['application/octet-stream'],
                      lzh:['application/octet-stream'],
                      exe:['application/octet-stream', 'application/x-msdownload'],
                      class:['application/octet-stream'],
                      psd:['application/x-photoshop'],
                      so:['application/octet-stream'],
                      sea:['application/octet-stream'],
                      dll:['application/octet-stream'],
                      oda:['application/oda'],
                      pdf:['application/pdf', 'application/x-download'],
                      ai:['application/postscript'],
                      eps:['application/postscript'],
                      ps:['application/postscript'],
                      smi:['application/smil'],
                      smil:['application/smil'],
                      mif:['application/vnd.mif'],
                      xls:['application/excel', 'application/vnd.ms-excel', 'application/msexcel'],
                      ppt:['application/powerpoint', 'application/vnd.ms-powerpoint'],
                      wbxml:['application/wbxml'],
                      wmlc:['application/wmlc'],
                      dcr:['application/x-director'],
                      dir:['application/x-director'],
                      dxr:['application/x-director'],
                      dvi:['application/x-dvi'],
                      gtar:['application/x-gtar'],
                      gz:['application/x-gzip'],
                      php:['application/x-httpd-php'],
                      php4:['application/x-httpd-php'],
                      php3:['application/x-httpd-php'],
                      phtml:['application/x-httpd-php'],
                      phps:['application/x-httpd-php-source'],
                      js:['application/x-javascript'],
                      swf:['application/x-shockwave-flash'],
                      sit:['application/x-stuffit'],
                      tar:['application/x-tar'],
                      tgz:['application/x-tar', 'application/x-gzip-compressed'],
                      xhtml:['application/xhtml+xml'],
                      xht:['application/xhtml+xml'],
                      zip:[ 'application/x-zip', 'application/zip', 'application/x-zip-compressed'],
                      mid:['audio/midi'],
                      midi:['audio/midi'],
                      mpga:['audio/mpeg'],
                      mp2:['audio/mpeg'],
                      mp3:['audio/mpeg', 'audio/mpg', 'audio/mpeg3', 'audio/mp3'],
                      aif:['audio/x-aiff'],
                      aiff:['audio/x-aiff'],
                      aifc:['audio/x-aiff'],
                      ram:['audio/x-pn-realaudio'],
                      rm:['audio/x-pn-realaudio'],
                      rpm:['audio/x-pn-realaudio-plugin'],
                      ra:['audio/x-realaudio'],
                      rv:['video/vnd.rn-realvideo'],
                      wav:['audio/x-wav', 'audio/wave', 'audio/wav'],
                      bmp:['image/bmp', 'image/x-windows-bmp'],
                      gif:['image/gif'],
                      jpeg:['image/jpeg', 'image/pjpeg'],
                      jpg:['image/jpeg', 'image/pjpeg'],
                      jpe:['image/jpeg', 'image/pjpeg'],
                      png:['image/png',  'image/x-png'],
                      tiff:['image/tiff'],
                      tif:['image/tiff'],
                      css:['text/css'],
                      html:['text/html'],
                      htm:['text/html'],
                      shtml:['text/html'],
                      txt:['text/plain'],
                      text:['text/plain'],
                      log:['text/plain', 'text/x-log'],
                      rtx:['text/richtext'],
                      rtf:['text/rtf'],
                      xml:['text/xml'],
                      xsl:['text/xml'],
                      mpeg:['video/mpeg'],
                      mpg:['video/mpeg'],
                      mpe:['video/mpeg'],
                      qt:['video/quicktime'],
                      mov:['video/quicktime'],
                      avi:['video/x-msvideo'],
                      movie:['video/x-sgi-movie'],
                      doc:['application/msword'],
                      docx:['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/zip'],
                      xlsx:['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/zip'],
                      word:['application/msword', 'application/octet-stream'],
                      xl:['application/excel'],
                      eml:['message/rfc822'],
                      json:['application/json', 'text/json'],
                      odt: ['application/vnd.oasis.opendocument.text']
                  }
    let fileExtention = "NA";
    Object.keys(typeArray).forEach((typeKey) => {
      const typeValue = typeArray[typeKey];
      // typeValue.forEach((mimeType))
      for (let i = 0; i <= typeValue.length; i++) {
        if (typeValue[i] == fileType)
          fileExtention = typeKey;
      }
    });
    console.log(fileType + ":" + fileExtention);
    return fileExtention;
  }
  public getLastMonthArrayByCount(number) {
    const monthArray = [
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December"
                        ];
    const lastMonths = [];
    for (let i = 11; i >= (12-number); i--) {
      lastMonths.push(monthArray[i]);
    }
    return lastMonths;
  }
  public graphBackgroundColor(number) {
      const colorArray = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)',
        'rgba(63, 191, 127, 0.5)',
        'rgba(133, 233, 161, 0.1)',
        'rgba(185, 133, 233, 0.1)',
        'rgba(165, 233, 133, 0.1)',
        'rgba(242, 241, 176, 0.1)'
      ];
      const colorData = [];
      for (let i = 11; i >= (12-number); i--) {
        colorData.push(colorArray[i]);
      }
      return colorData;
  }
  public graphBorderColor(number) {
      const colorArray = [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)',
        'rgb(63, 191, 127)',
        'rgba(63, 191, 127, 0.5)',
        'rgba(63, 93, 191, 0.5)',
        'rgba(63, 184, 191, 0.5)',
        'rgba(191, 187, 63, 0.5)'
      ];
      const colorData = [];
      for (let i = 11; i >= (12-number); i--) {
        colorData.push(colorArray[i]);
      }
      return colorData;
  }
}
