<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Expense' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p>{{ 'Total expense amount on property' | translate}} - <b>{{expenseTotal}}<em class="icon ni ni-sign-inr"></em></b>.</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="admin/addExpense/{{commonHelper.encodeURIParam(0)}}" class="btn btn-primary"><em class="icon ni ni-plus"></em><span>{{'New Expense' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-stretch">
          <div class="card-inner-group">
            <div class="card-inner position-relative card-tools-toggle">
              <div class="card-title-group">
                  <form [formGroup]="expFilter">
                    <div class="row">
                      <div class="col-md-3">
                        <select [formControl]="expFilterControls.expProperty" name="expProperty" id="expProperty" class="form-select form-control form-control-lg" data-search="on">
                          <option value="0" disabled>Select Property</option>
                          <option *ngFor="let property of propertyList.data" [value]="property.prop_id">{{property.prop_name}}</option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <div class="form-icon form-icon-left">
                          <em class="icon ni ni-calendar"></em>
                        </div>
                        <input type="text" [formControl]="expFilterControls.expFromDate" name="expFromDate" id="expFromDate" class="form-control date-picker" placeholder="From Date" data-date-format="yyyy-mm-dd">
                      </div>
                      <div class="col-md-2">
                        <div class="form-icon form-icon-left">
                          <em class="icon ni ni-calendar"></em>
                        </div>
                        <input type="text" [formControl]="expFilterControls.expToDate" name="expToDate" id="expToDate" class="form-control date-picker" placeholder="To Date" data-date-format="yyyy-mm-dd">
                      </div>
                      <div class="col-md-4">
                        <span class="d-md-block">
                          <button type="button" data-style="zoom-in" (click)="fetchExpenseData('view')" class="btn btn-primary" [ladda]="viewBtnLoader"><em class="icon ni ni-check"></em> <span>{{'Apply' | translate}}</span></button>
                          <button type="button" data-style="zoom-in" (click)="fetchExpenseData('excel')" class="btn btn-outline-secondary mg-left-10px" [ladda]="excelBtnLoader"><em class="icon ni ni-download"></em> <span>{{'Export' | translate}}</span></button>
                        </span>
                      </div>
                    </div><!-- .form-inline -->
                  </form>
              </div><!-- .card-title-group -->
            </div><!-- .card-inner -->
            <div class="card-inner" style="overflow-x: scroll">
              <table *ngIf="expenseData.numRows > 0" class="nk-tb-list nk-tb-ulist table-bordered" id="expense_table" data-auto-responsive="true">
                <thead>
                  <tr class="nk-tb-item nk-tb-head">
                    <th class="nk-tb-col">#</th>
                    <th class="nk-tb-col">{{'Property Name' | translate}}</th>
                    <th class="nk-tb-col">{{'Tenant Name' | translate}}</th>
                    <th class="nk-tb-col">{{'Expense Type' | translate}}</th>
                    <th class="nk-tb-col">{{'Date' | translate}}</th>
                    <th class="nk-tb-col">{{'Amount' | translate}}</th>
                    <th class="nk-tb-col">{{'Paid By' | translate}}</th>
                    <th class="nk-tb-col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let expense of expenseData.data; let sno = index;" class="nk-tb-item">
                      <td class="nk-tb-col">{{sno+1}}</td>
                      <td class="nk-tb-col font-weight-bold">{{expense.prop_name}}</td>
                      <td class="nk-tb-col">{{expense.tenant_name}}</td>
                      <td class="nk-tb-col">{{expense.exp_head_name}}</td>
                      <td class="nk-tb-col">{{expense.exp_date | date: 'dd/MM/yyyy'}}</td>
                      <td class="nk-tb-col">
                        {{expense.exp_amount}}
                      </td>
                      <td class="nk-tb-col">
                        <span *ngIf="expense.is_done_on_tenant_behalf == 1 else paidByElse" class="text-danger font-weight-bold">{{'Tenant' | translate}}</span>
                        <ng-template #paidByElse>
                          <span class="text-warning font-weight-bold">{{'Property Staff' | translate}}</span>
                        </ng-template>
                      </td>
                      <td class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1">
                          <li>
                            <div class="drodown">
                              <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <ul class="link-list-opt no-bdr">
                                  <li><a href="admin/viewExpense/{{commonHelper.encodeURIParam(expense.exp_id)}}"><em class="icon ni ni-eye"></em><span>{{'View Details' | translate}}</span></a></li>
                                  <li><a href="admin/editExpense/{{commonHelper.encodeURIParam(expense.exp_id)}}"><em class="icon ni ni-edit-alt"></em><span>{{'Edit Details' | translate}}</span></a></li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </td>
                  </tr>
                  <tr>
                    <td colspan="4"></td>
                    <td class="nk-tb-col font-weight-bold">Total</td>
                    <td class="nk-tb-col font-weight-bold">{{expenseData.extra}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div><!-- .card-inner -->
          </div><!-- .card-inner-group -->
        </div><!-- .card -->
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
