<div class="card-inner card-inner-lg">
  <div class="nk-block-head">
    <div class="nk-block-head-content">
      <h5 class="nk-block-title">Reset password</h5>
      <div class="nk-block-des">
        <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="form-label-group">
      <label class="form-label">Email<span class="text-danger">*</span></label>
    </div>
    <input type="text" [formControl]="email" class="form-control form-control-lg" placeholder="Enter your email address">
    <span id="fv-resetemail-error" class="invalid" *ngIf="email.dirty || email.touched">
      <span *ngIf="email.hasError('required')">Email {{appConst.EMPTY_ERROR}}</span>
      <span *ngIf="email.hasError('email')">{{appConst.EMAIL_ERROR}}</span>
    </span>

  </div>
  <div class="form-group">
    <button class="btn btn-lg btn-primary btn-block" (click)="sendForgotPassword()" [ladda]="isLoading" data-style="slide-down" [disabled]="!email.valid">Send Reset Link</button>
  </div>
  <div class="form-note-s2 text-center pt-4">
    <a (click)="route.navigateByUrl('/')"><strong>Return to login</strong></a>
  </div>
</div>
