import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '../../app-config/app-constants';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  //For transfer data from parent (admin component) to child (footer component)
  @Input() pData: number[] = [];
  @Output() sendData = new EventEmitter;

  public data2: string[] = ['a', 'b'];

  constructor(public appConst: AppConstants,
              public translate: TranslateService
  ) {
    translate.setDefaultLang("en");
  }

  ngOnInit(): void {
      this.translate.use(localStorage.getItem("lang"));
      console.log("pData :::", this.pData);
      this.sendData.emit(this.data2);
  }

}
