<h2 mat-dialog-title class="modal-header">{{'Assign Tenant' | translate}}</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-md-12" style="overflow-x: scroll;">
      <form [formGroup]="assignTenantForm" *ngIf="data.actionType === 'assign' else popupElseBlock">
        <div class="row gy-4">
          <div class="col-sm-12">
              <div class="form-group">
                <label class="form-label">{{'Tenant Name' | translate}} <span class="text-danger">*</span></label>
                <div class="form-control-wrap">
                  <ng-select [formControl]="assignTenantFormControls.tenant_id" name="tenant_id" id="tenant_id" placeholder="Select Tenant">
                    <ng-container *ngFor="let tenant of tenantsList.data">
                      <ng-option *ngIf="tenant.property_id == null" [value]="tenant.tenant_id">{{ tenant.tenant_name }}</ng-option>
                    </ng-container>
                  </ng-select>
                  <span class="invalid-select" *ngIf="assignTenantFormControls.tenant_id.hasError('required') && (assignTenantFormControls.tenant_id.touched || assignTenantFormControls.tenant_id.dirty)">
                    {{'Tenant Name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                  </span>
                </div>
              </div>
          </div>
          <div class="col-sm-12">
                <div class="form-group">
                  <label class="form-label">{{'Rent' | translate}} <span class="text-danger">*</span></label>
                  <div class="form-control-wrap">
                    <div class="form-icon form-icon-right">
                      <em class="icon ni ni-sign-inr"></em>
                    </div>
                    <input type="text" class="form-control" placeholder="Rent" value="{{data.propertyData.prop_rent}}" [formControl]="assignTenantFormControls.rent_payable" name="rent_payable">
                    <span class="invalid-select" *ngIf="assignTenantFormControls.rent_payable.hasError('required') && (assignTenantFormControls.rent_payable.touched || assignTenantFormControls.rent_payable.dirty)">
                      {{'Rent' | translate}} {{appConst.EMPTY_ERROR | translate}}
                    </span>
                  </div>
                </div>
          </div>
          <div class="col-sm-12">
              <div class="form-group">
                <label class="form-label">{{'Start Date' | translate}} <span class="text-danger">*</span></label>
                <div class="form-control-wrap">
                  <div class="form-icon form-icon-right">
                    <em class="icon ni ni-calendar-alt"></em>
                  </div>
                  <input type="text" class="form-control date-picker" placeholder="Start Date" id="start_date" [formControl]="assignTenantFormControls.start_date" name="start_date" data-date-format="yyyy-mm-dd">
                  <span class="invalid-select" *ngIf="assignTenantFormControls.start_date.hasError('required') && (assignTenantFormControls.start_date.touched || assignTenantFormControls.start_date.dirty)">
                    {{'Start Date' | translate}} {{appConst.EMPTY_ERROR | translate}}
                  </span>
                </div>
              </div>
          </div>
          <div class="col-sm-12">
              <div class="form-group">
                <label class="form-label">{{'End Date' | translate}}</label>
                <div class="form-control-wrap">
                  <div class="form-icon form-icon-right">
                    <em class="icon ni ni-calendar-alt"></em>
                  </div>
                  <input type="text" class="form-control date-picker" placeholder="End Date" id="end_date" [formControl]="assignTenantFormControls.end_date" name="end_date" data-date-format="yyyy-mm-dd">
                </div>
              </div>
          </div>
        </div>
      </form>
      <ng-template #popupElseBlock>
      <table class="datatable-init nk-tb-list nk-tb-ulist" *ngIf="data.actionType==='unassign'" style="width: 100%;">
        <thead>
          <tr class="nk-tb-item nk-tb-head">
            <th class="nk-tb-col"></th>
            <th class="nk-tb-col">{{'Tenant Name' | translate}}</th>
            <th class="nk-tb-col">{{'Rent' | translate}}</th>
            <th class="nk-tb-col">{{'Start Date' | translate}}</th>
            <th class="nk-tb-col">{{'End Date' | translate}}</th>
          </tr>
        </thead>
        <tr class="nk-tb-item" *ngIf="tenantsList.numRows > 0">
          <td class="nk-tb-col">
            <div class="form-group">
              <div class="g">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" [formControl]="unAssignCheckBox" class="custom-control-input" value="0" id="tenant_checkbox" (change)="unAssignTenantFromProperty(data.propertyId, tenantsList.data[0].tenant_id)">
                  <label class="custom-control-label" for="tenant_checkbox"></label>
                </div>
              </div>
            </div>
          </td>
          <td class="nk-tb-col">{{tenantsList.data[0].tenant_name}}</td>
          <td class="nk-tb-col">{{tenantsList.data[0].rent_payable}}</td>
          <td class="nk-tb-col">{{tenantsList.data[0].start_date | date: 'dd/MM/yyyy'}}</td>
          <td class="nk-tb-col">
            <div class="form-group">
              <div class="form-control-wrap">
                <div class="form-icon form-icon-right">
                  <em class="icon ni ni-calendar-alt"></em>
                </div>
                <input type="text" class="form-control date-picker" placeholder="End Date" id="ua_end_date" [formControl]="unAssignEndDate" name="ua_end_date" data-date-format="yyyy-mm-dd">
              </div>
            </div>
          </td>
        </tr>
      </table>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="modal-footer">
  <a href="admin/addTenant/{{commonHelper.encodeURIParam(0)}}" class="btn btn-info"><em class="icon ni ni-plus"></em> <span>{{'New Tenant' | translate}}</span></a>
  <button *ngIf="data.actionType==='assign' else actionTypeElse" class="btn btn-primary" data-style="zoom-in" [ladda]="saveBtnLoading" (click)="assignTenantToProperty(data.propertyId)" [disabled]="!assignTenantForm.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
  <ng-template #actionTypeElse><button *ngIf="data.actionType==='unassign' && showUnAssignBtn" class="btn btn-primary" data-style="zoom-in" [ladda]="saveBtnLoading" (click)="unAssignTenantFromProperty(data.propertyId, tenantsList.data[0].tenant_id)" [disabled]="!unAssignCheckBox.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button></ng-template>
  <button class="btn btn-outline-secondary" mat-dialog-close><em class="icon ni ni-cross"></em> <span>{{'Cancel' | translate}}</span></button>
</mat-dialog-actions>
