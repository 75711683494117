import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AppConstants} from '../../app-config/app-constants';
import {CommonHelper} from '../../app-config/common-helper';
import {CommonService} from '../../services/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject} from 'rxjs';
import {GridLayout, Image, PlainGalleryConfig, PlainGalleryStrategy} from '@ks89/angular-modal-gallery';

declare function $(s: string);

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css']
})
export class ExpenseComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private urlRoute: ActivatedRoute,
    public datePipe: DatePipe,
    public ngxSpinner: NgxSpinnerService
  ) { translate.setDefaultLang("en"); }

  expFilter = new FormGroup({
    expFromDate: new FormControl('', [Validators.required]),
    expToDate: new FormControl('', [Validators.required]),
    expProperty: new FormControl('0',[Validators.required])
  });
  propertyList: any = this.appConst.API_RESPONSE;
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  viewBtnLoader: boolean = false;
  excelBtnLoader: boolean = false;
  expenseData: any = this.appConst.API_RESPONSE;
  expenseTotal: number = 0;
  dtTrigger: Subject<any> = new Subject();
  ngOnInit(): void {
    this.ngxSpinner.show();
    this.translate.use(localStorage.getItem("lang"));
    this.fetchProperty();
    this.fetchExpenseTotal();
    this.ngxSpinner.hide();
  }
  get expFilterControls() {
      return this.expFilter.controls;
  }
  fetchProperty() {
    const paramData: any = {
        dbname: this.appConst.DATABASE
    };
    if (this.localUser.user_role == "TENANT")
      paramData.propertyId = this.localUser.prop_id;
    else if (this.localUser.user_role == "OWNER")
      paramData.userId = this.localUser.userid;
    else
      paramData.isDeleted = 0;

    this.commonService.fetchPropertyService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("fetchProperty :", apiResponse);
        this.propertyList = apiResponse;
      },
      Error => {
        console.log("fetchProperty :", Error);
      }
    );
  }
  fetchExpenseData(type) {
    this.expFilter.controls.expProperty.setValue($("#expProperty").val());
    this.expFilter.controls.expFromDate.setValue($("#expFromDate").val());
    this.expFilter.controls.expToDate.setValue($("#expToDate").val());
    if (this.expFilter.status !== "VALID") {
      Swal.fire("", this.translate.instant("Please select all fields"), "warning").then();
      return false;
    }
    const paramData: any = {
        dbname: this.appConst.DATABASE,
        data: this.expFilter.value,
        type: type
    };
    if (this.localUser.user_role == "TENANT")
      paramData.data.tenantId = this.localUser.tenant_id;
    paramData.data.propertyName = $("#expProperty option:selected").text();
    console.log("expFilter :", paramData);
    this.viewBtnLoader = true;
    this.excelBtnLoader = true;
    this.commonService.fetchExpenseDataService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("expenseData :", apiResponse);
        if (type === "excel") {
            if (apiResponse.opcode === 1)
              window.open(this.appConst.API_END + "common/download?file_path="+apiResponse.data.filePath);
            else
              Swal.fire("", this.translate.instant("Excel file not downloaded"), "error");
        }
        else {
            if (apiResponse.opcode === 1) {
              this.expenseData = apiResponse;
              this.expenseData.extra = this.expenseData.data.map(t => t.exp_amount).reduce((a, value) => a + value, 0);
            }
            else
              Swal.fire("", this.translate.instant("No records found."), "error");
        }
        this.viewBtnLoader = false;
        this.excelBtnLoader = false;
      },
      Error => {
        console.log("fetchExpenseData Error :", Error.error);
        this.viewBtnLoader = false;
        this.excelBtnLoader = false;
      }
    );
  }
  fetchExpenseTotal() {
    const paramData: any = {
      dbname: this.appConst.DATABASE
    };
    if (this.localUser.user_role == "OWNER")
      paramData.expenseOwnerId = this.localUser.userid;
    else if (this.localUser.user_role == "TENANT")
      paramData.tenantId = this.localUser.tenant_id;
    else
      paramData.isDeleted = 0;
    this.commonService.fetchExpenseTotalService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("fetchRentTotal :", apiResponse);
        if (apiResponse.opcode === 1)
          this.expenseTotal = apiResponse.data[0].exp_total;
      },
      Error => {
        console.log("fetchRentTotal Error:", Error.error);
      }
    );
  }
}

@Component({
  selector: 'app-add-expense',
  templateUrl: './add-expense.component.html',
  styleUrls: ['./expense.component.css']
})
export class AddExpenseComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private urlRoute: ActivatedRoute,
    public datePipe: DatePipe,
    public ngxSpinner: NgxSpinnerService
  ) { translate.setDefaultLang("en"); }
  propertyList: any = this.appConst.API_RESPONSE;
  tenantList: any = this.appConst.API_RESPONSE;
  expenseHeadList: any = this.appConst.API_RESPONSE;
  expenseFilesList: any = [];
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  expenseAddForm = new FormGroup({
    exp_prop_id: new FormControl(null,[Validators.required]),
    exp_tenant_id: new FormControl('0'),
    exp_head: new FormControl(null,[Validators.required]),
    exp_date: new FormControl(this.datePipe.transform(Date.now(), "yyyy-MM-dd"),[Validators.required]),
    exp_amount: new FormControl('',[Validators.required]),
    exp_description: new FormControl('',[Validators.required]),
    is_done_on_tenant_behalf: new FormControl('0')
  });
  manageFlag: string = 'add';
  isBtnLoading: boolean = false;
  expFileLoader: boolean = false;
  expTypeCtrl= new FormControl('', [Validators.required]);
  expBtnLoading: boolean = false;

  ngOnInit(): void {
    this.ngxSpinner.show();
    this.translate.use(localStorage.getItem("lang"));
    const expenseId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    console.log("expenseId :", expenseId);
    if (expenseId != '0') {
      this.manageFlag = 'edit';
      this.editExpenseDetails(expenseId);
    }
    this.fetchProperty();
    this.fetchExpenseHead();
    this.ngxSpinner.hide()
  }
  get expenseAddFormControls() {
    return this.expenseAddForm.controls;
  }
  fetchProperty() {
    const paramData: any = {
      dbname: this.appConst.DATABASE
    };
    if (this.localUser.user_role == "TENANT")
      paramData.propertyId = this.localUser.prop_id;
    else if (this.localUser.user_role == "OWNER")
      paramData.userId = this.localUser.userid;
    else
      paramData.isDeleted = 0;
    this.commonService.fetchPropertyService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("fetchProperty :", apiResponse);
        this.propertyList = apiResponse;
      },
      Error => {
        console.log("fetchProperty :", Error);
      }
    );
  }
  fetchTenant(propertyId) {
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      propertyId: ""+propertyId
    };
    if (this.localUser.user_role == "TENANT")
      paramData.tenantId = this.localUser.tenant_id;
    this.ngxSpinner.show();
    this.commonService.fetchTenantsService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("fetchTenant apiResponse:", apiResponse);
        if (apiResponse.numRows > 0)
          this.expenseAddForm.controls.exp_tenant_id.setValue(apiResponse.data[0].tenant_id);
        this.tenantList = apiResponse;
        this.ngxSpinner.hide();
      },
      Error => {
        console.log("fetchTenant Error:", Error);
        this.ngxSpinner.hide();
      }
    );
  }
  fetchExpenseHead() {
    const paramData: any = {
      dbname: this.appConst.DATABASE,
    };
    this.commonService.fetchExpenseHeadService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("fetchExpenseHead apiResponse:", apiResponse);
        this.expenseHeadList = apiResponse;
      },
      Error => {
        console.log("fetchExpenseHead Error:", Error);
      }
    );
  }
  addExpenseData (manageFlag) {
    console.log("expenseFilesList :", this.expenseFilesList);
    const fileId = this.commonHelper.convertToStringByKey("id", this.expenseFilesList);
    this.expenseAddForm.controls.exp_date.setValue($("#exp_date").val());
    if (this.expenseAddForm.status != "VALID") {
        Swal.fire("", this.translate.instant("Please fill all mandatory fields"), "warning");
        return false;
    }
    const expenseData = this.expenseAddForm.value;
    expenseData.exp_month = this.datePipe.transform(expenseData.exp_date,"MM");
    expenseData.exp_year = this.datePipe.transform(expenseData.exp_date,"yyyy");
    expenseData.expense_owner_id = this.localUser.userid;
    const data = {
      dbname: this.appConst.DATABASE,
      data: expenseData,
      fileId: fileId,
      expenseId: this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"))
    };
    delete data.data.prop_file;
    this.isBtnLoading = true;
    console.log("expenseAddForm data:", data);
    this.commonService.manageExpenseService(data, manageFlag).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant("Expense") + " " + this.translate.instant("data successfully saved"), "success").then((r) => {
            if (manageFlag === 'add')
              window.location.href = 'admin/expense';
          });
        }
        else {
          Swal.fire("", this.translate.instant("Expense") + " " + this.translate.instant("data not saved") + this.translate.instant(".") + this.translate.instant("Please try again"), "error");
        }
        this.isBtnLoading = false;
      },
      Error => {
        console.log("addExpenseData ", Error);
        Swal.fire("", this.translate.instant("Connection error") + this.translate.instant(".") + this.translate.instant("Please try again"), "error");
        this.isBtnLoading = false;
      }
    );
  }
  expenseFilesUpload (event) {
    const formData = new FormData();
    if (event.target.files.length > 0) {
      const fileData = event.target.files[0];
      console.log("fileData Size:", fileData.size);
      if (fileData.size > 25000000) {
          Swal.fire("", this.translate.instant("File memory size should be less than 25MB"), "warning");
          return false;
      }
      formData.append("prop_file", event.target.files[0]);
      formData.append("dbname", this.appConst.DATABASE);
      formData.append("sessionUserId", this.localUser.userid);
      this.expFileLoader = true;
      this.ngxSpinner.show();
      this.commonService.uploadExpenseFilesService(formData).subscribe(
        Response => {
          console.log("Response :", Response);
          const apiResponse: any = Response;
          if (apiResponse.opcode === 1997) {
            Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
              localStorage.clear();
              window.location.href = "/";
            });
          }
          if (apiResponse.opcode === 1) {
            const fileType = apiResponse.data.file_type.split("/");
            const fileExt = this.commonHelper.getFileExtentionByType(apiResponse.data.file_type);
            const fileData = {
              id: apiResponse.data.exp_file_id,
              type: fileType[0],
              path: this.appConst.API_END + apiResponse.extra.folderPath + apiResponse.data.file_name,
              ext: fileExt,
              original_name: apiResponse.data.original_name
            }
            this.expenseFilesList.push(fileData);
          }
          else {
            Swal.fire("",this.translate.instant("file data not saved") + this.translate.instant(".") + this.translate.instant("Please try again"), "error").then();
          }
          this.expFileLoader = false;
          this.ngxSpinner.hide();
        },
        Error => {
          console.log("Error :", Error);
          this.expFileLoader = false;
          this.ngxSpinner.hide();
        }
      );
    }
  }
  deleteExpenseFile(id) {
    console.log("File Id :", id);
    this.expFileLoader = true;
    this.commonService.deleteExpenseFileService({dbname: this.appConst.DATABASE, id: id}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        if (apiResponse.opcode === 1) {
          const removeItem = this.expenseFilesList.filter((item) => item.id !== id);
          this.expenseFilesList = removeItem;
        }
        else {
          Swal.fire("", this.translate.instant("Connection error"), "error").then();
        }
        this.expFileLoader = false;
      },
      Error => {
        console.log("Error :", Error);
        this.expFileLoader = false;
      }
    );
  }
  editExpenseDetails(expenseId) {
    this.commonService.fetchExpenseDataService({dbname: this.appConst.DATABASE, data: {expenseId: expenseId}}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("editExpenseDetails apiResponse :", apiResponse);
        if (apiResponse.opcode === 1) {
          const data = apiResponse.data[0];
          this.expenseAddForm.controls.exp_prop_id.setValue(data.exp_prop_id);
          this.fetchTenant(data.exp_prop_id);
          this.expenseAddForm.controls.exp_tenant_id.setValue(data.exp_tenant_id);
          this.expenseAddForm.controls.exp_head.setValue(data.exp_head);
          this.expenseAddForm.controls.exp_date.setValue(data.exp_date);
          this.expenseAddForm.controls.exp_amount.setValue(data.exp_amount);
          this.expenseAddForm.controls.exp_description.setValue(data.exp_description);
          this.expenseAddForm.controls.is_done_on_tenant_behalf.setValue(''+data.is_done_on_tenant_behalf);
          this.commonService.fetchExpenseFilesService({dbname: this.appConst.DATABASE, expenseId: expenseId}).subscribe(
            Response => {
              const apiFilesResponse: any = Response;
              if (apiFilesResponse.numRows > 0) {
                apiFilesResponse.data.forEach(fileValue => {
                  const fileType = fileValue.file_type.split("/");
                  const fileExt = this.commonHelper.getFileExtentionByType(fileValue.file_type);
                  const fileData = {
                    id: fileValue.prop_file_id,
                    type: fileType[0],
                    path: this.appConst.API_END + apiFilesResponse.extra.folderPath + fileValue.file_name,
                    ext: fileExt,
                    original_name: fileValue.original_name
                  }
                  this.expenseFilesList.push(fileData);
                });
              }
            }
          );
        }
      },
      Error => {
        console.log("editExpenseDetails Error :", Error);
      }
    );
  }
  addNewExpenseType() {
    const expenseType = this.expTypeCtrl.value;
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      data: {
        exp_head_name: expenseType,
        added_datetime: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
      }
    };
    console.log("addNewExpenseType paramData :", paramData);
    this.expBtnLoading = true;
    this.commonService.addExpenseHeadService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant(apiResponse.message), "success").then((r) => {
           this.fetchExpenseHead();
           $("#expense_type_modal").modal('hide');
          });
        }
        else {
          Swal.fire("", this.translate.instant(apiResponse.message), "error");
        }
        this.expBtnLoading = false;
      },
      Error => {
        console.log("addNewExpenseType ", Error);
        Swal.fire("", this.translate.instant("Connection error") + this.translate.instant(".") + this.translate.instant("Please try again"), "error");
        this.expBtnLoading = false;
      }
    );
  }
}
@Component({
  selector: 'app-view-expense',
  templateUrl: './view-expense.component.html',
  styleUrls: ['./expense.component.css']
})

export class ViewExpenseComponent implements OnInit {
  constructor(public translate: TranslateService,
              public route: Router,
              public appConst: AppConstants,
              private commonService: CommonService,
              public commonHelper: CommonHelper,
              private urlRoute: ActivatedRoute
  ) {
    translate.setDefaultLang("en");
  }
  expenseDetails: any;
  plainGalleryGrid: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.GRID,
    layout: new GridLayout({ width: '80px', height: '80px' }, { length: 3, wrap: true })
  };
  expenseFiles: Image[] = [];
  lat = 51.678418;
  lng = 7.809007;
  otherFiles: any = [];
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    const expenseId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    this.viewExpenseDetails(expenseId);
    this.fetchExpenseFiles(expenseId);
  }
  viewExpenseDetails(expenseId) {
    this.commonService.fetchExpenseDataService({dbname: this.appConst.DATABASE, data: {expenseId: expenseId}}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("viewExpenseDetails apiResponse :", apiResponse);
        this.expenseDetails = apiResponse.data[0];
      },
      Error => {
        console.log("Error :", Error);
      }
    );
  }
  fetchExpenseFiles (expenseId) {
    this.commonService.fetchExpenseFilesService({dbname: this.appConst.DATABASE, expenseId: expenseId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        if (apiResponse.numRows > 0) {
          let fileCount: number = 0;
          apiResponse.data.forEach(fileValue => {
            const fileType = fileValue.file_type.split("/");
            const fileExt = this.commonHelper.getFileExtentionByType(fileValue.file_type);
            if (fileType[0].includes('image')) {
              this.expenseFiles.push(new Image(fileCount, {
                img: this.appConst.API_END + apiResponse.extra.folderPath + fileValue.file_name,
                description: 'Property File'
              }));
              fileCount++;
            }
            else {
              this.otherFiles.push(
                {
                  id: fileValue.prop_file_id,
                  type: fileType[0],
                  path: this.appConst.API_END + apiResponse.extra.folderPath + fileValue.file_name,
                  ext: fileExt,
                  original_name: fileValue.original_name
                }
              );
            }
            fileCount++;
          });
          console.log("expenseFiles :", this.expenseFiles);
        }
      }
    );
  }
}
