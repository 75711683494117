<h2 mat-dialog-title class="modal-header">{{'Change Password' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="passwordForm" style="margin-bottom: 20px;">
    <div class="form-group">
      <label class="form-label" for="oldPassword">{{'Old Password' | translate}}</label>
      <div class="form-control-wrap">
        <div class="form-icon form-icon-right"><em class="icon ni ni-lock"></em></div>
        <input type="password" [formControl]="passwordChangeControl.oldPassword" class="form-control" id="oldPassword" placeholder="Enter Old Password">
        <span class="invalid" *ngIf="(passwordChangeControl.oldPassword.dirty || passwordChangeControl.oldPassword.touched)">
            <span *ngIf="passwordChangeControl.oldPassword.hasError('required')">{{'Old Password' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
        </span>
        <span class="invalid" *ngIf="oldPassError!=''">{{oldPassError | translate}}</span>
      </div>
    </div>
    <div class="form-group">
      <label class="form-label" for="newPassword">{{'New Password' | translate}}</label>
      <div class="form-control-wrap">
        <div class="form-icon form-icon-right"><em class="icon ni ni-lock"></em></div>
        <input type="password" #newPassword [formControl]="passwordChangeControl.newPassword" class="form-control" id="newPassword" placeholder="Enter New Password">
        <span class="invalid" *ngIf="(passwordChangeControl.newPassword.dirty || passwordChangeControl.newPassword.touched)">
            <span *ngIf="passwordChangeControl.newPassword.hasError('required')">{{'New Password' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
            <span *ngIf="passwordChangeControl.newPassword.hasError('minlength')">{{'Password minimum length should be 6' | translate}}</span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label class="form-label" for="cnfPassword">{{'Confirm Password' | translate}}</label>
      <div class="form-control-wrap">
        <div class="form-icon form-icon-right"><em class="icon ni ni-lock"></em></div>
        <input type="text" #cnfPassword (blur)="checkPassword(newPassword.value, cnfPassword.value)" [formControl]="passwordChangeControl.cnfPassword" class="form-control" id="cnfPassword" placeholder="Enter Confirm Password">
        <span class="invalid" *ngIf="(passwordChangeControl.cnfPassword.dirty || passwordChangeControl.cnfPassword.touched)">
            <span *ngIf="passwordMatch&&passwordChangeControl.cnfPassword.hasError('required')">{{'Confirm Password' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
            <span *ngIf="!passwordMatch">{{'Please make sure your passwords match' | translate}}</span>
        </span>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="modal-footer">
  <button class="btn btn-outline-secondary" mat-dialog-close><em class="icon ni ni-cross"></em> <span>{{'Cancel' | translate}}</span></button>
  <button class="btn btn-primary" data-style="zoom-in" (click)="saveChangePassword(localUser.userid)" [ladda]="saveBtnLoading" [disabled]="!passwordForm.valid || !passwordMatch" style="margin-left: 10px;"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
</mat-dialog-actions>
