<h2 mat-dialog-title class="modal-header">{{'Care Taker' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="careTakerForm" *ngIf="!showCareTakerList" style="margin-bottom: 20px;">
    <div class="row gy-4">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="form-label">{{'First Name' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getCareTakerFormControls.care_taker_first_name" name="care_taker_f_name" class="form-control" id="care_taker_f_name" placeholder="Enter care taker first name">
            <span class="invalid" *ngIf="getCareTakerFormControls.care_taker_first_name.hasError('required') && (getCareTakerFormControls.care_taker_first_name.touched || getCareTakerFormControls.care_taker_first_name.dirty)">
              {{'Care taker first name' | translate}} {{appConst.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="form-label">{{'Last Name' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getCareTakerFormControls.care_taker_last_name" name="care_taker_l_name" class="form-control" id="care_taker_l_name" placeholder="Enter care taker last name">
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="form-label">{{'Email' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getCareTakerFormControls.care_taker_email" name="care_taker_email" class="form-control" id="care_taker_email" placeholder="Enter care taker email">
            <span class="invalid" *ngIf="(getCareTakerFormControls.care_taker_email.touched || getCareTakerFormControls.care_taker_email.dirty)">
              <span *ngIf="getCareTakerFormControls.care_taker_email.hasError('required')">{{'Care taker email' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
              <span *ngIf="getCareTakerFormControls.care_taker_email.hasError('email')">{{'Email should be valid' | translate}}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="form-label">{{'Contact No' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getCareTakerFormControls.care_taker_phone" name="care_taker_phone" class="form-control" id="care_taker_phone" placeholder="Enter care taker phone number">
            <span class="invalid" *ngIf="(getCareTakerFormControls.care_taker_phone.touched || getCareTakerFormControls.care_taker_phone.dirty)">
              <span *ngIf="getCareTakerFormControls.care_taker_phone.hasError('required')">{{'Care taker phone number' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
              <span *ngIf="getCareTakerFormControls.care_taker_phone.hasError('minlength') || getCareTakerFormControls.care_taker_phone.hasError('maxlength')">{{'Contact No should have at-least 10 digit' | translate}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row gy-4" *ngIf="showCareTakerList">
    <div class="col-12">
      <div class="preview-block">
        <span class="preview-title overline-title">{{'Select one care taker' | translate}}</span>
        <div class="g-4 align-center flex-wrap">
          <table class="table">
            <tr *ngFor="let careTaker of careTakerList.data">
              <div class="g" style="padding: 10px 0px 0px 10px;">
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" [formControl]="assignCareTaker" name="radioSize" id="care_taker_id_{{careTaker.care_taker_id}}" [value]="careTaker.care_taker_id">
                  <label class="custom-control-label" for="care_taker_id_{{careTaker.care_taker_id}}">{{careTaker.care_taker_first_name}}<span *ngIf="careTaker.care_taker_last_name != null">{{' ' + careTaker.care_taker_last_name}}</span></label>
                </div>
              </div>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="modal-footer">
  <button class="btn btn-success" (click)="showCareTakerList=false" *ngIf="showCareTakerList"><em class="icon ni ni-plus"></em> <span>{{'Add New' | translate}}</span></button>
  <button class="btn btn-success" (click)="showCareTakerList=true" *ngIf="!showCareTakerList"><em class="icon ni ni-list"></em> <span>{{'Show List' | translate}}</span></button>
  <button class="btn btn-primary" data-style="zoom-in" *ngIf="!showCareTakerList" [ladda]="saveBtnLoading" [disabled]="!careTakerForm.valid" (click)="saveCareTakerDetails(data.propertyId)"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
  <button class="btn btn-primary" data-style="zoom-in" *ngIf="showCareTakerList" [ladda]="saveBtnLoading" [disabled]="!assignCareTaker.valid" (click)="assignCareTakerToProperty(data.propertyId)"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
  <button class="btn btn-outline-secondary" mat-dialog-close><em class="icon ni ni-cross"></em> <span>{{'Cancel' | translate}}</span></button>
</mat-dialog-actions>
