<div class="nk-block nk-block-middle nk-auth-body">
  <div class="nk-block-head" *ngIf="isSuccess else errorMessage">
    <div class="nk-block-head-content">
      <h5 class="nk-block-title">Thank you for verifying. </h5>
      <div class="nk-block-des text-success">
        <p>Your email id is successfully verified. You may go ahead and login using email id and password.</p>
      </div>
    </div>
  </div>
  <ng-template #errorMessage>
    <div class="nk-block nk-block-middle nk-auth-body">
      <div class="nk-block-head">
        <div class="nk-block-head-content">
          <h5 class="nk-block-title">Oops. Looks like the activation link is expired </h5>
          <div class="nk-block-des text-danger">
            <p>It looks like you are using a expired activation link. If you have activated your account and forgot the password, you may click on "Forgot password" and generate reset link. Alternatively, if you remember your password you may go ahead and login using that.</p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="form-note-s2 text-center pt-4">
    <a (click)="route.navigateByUrl('/')" class="btn btn-primary"><strong>Go to login</strong></a>
  </div>
</div>
