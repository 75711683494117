<h2 mat-dialog-title>Change Profile Picture</h2>
<mat-dialog-content>
  <div class="avatar-upload">
    <div class="avatar-edit">
      <input type='file' name="profile_image" id="imageUpload" (change)="previewProfileImage($event)" accept=".png, .jpg, .jpeg" />
      <label for="imageUpload"><em class="icon ni ni-pen2" style="position: absolute;top: 9px;right: 9px;"></em></label>
    </div>
    <div class="avatar-preview">
      <div id="imagePreview" style="background-image: url({{bgImageProfile}});">
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="btn-group">
      <button class="btn btn-dim btn-secondary" mat-dialog-close>Cancel</button>
      <button class="btn btn-primary" style="margin-left: 10px;">Save</button>
  </div>
</mat-dialog-actions>
