<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Tenants' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p>{{'Fill new tenant details' | translate}}</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="admin/tenants" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-arrow-left"></em><span>{{'Back' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
          <div class="card-inner">
            <div class="preview-block">
              <span class="preview-title-lg overline-title"></span>
              <form [formGroup]="tenantAddForm" (submit)="addTenantDetails(manageFlag)">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="avatar-upload">
                      <div class="avatar-edit">
                        <input type='file' name="tenant_profile" [formControl]="tenantFormControls.tenant_profile" id="imageUpload" (change)="previewProfileImage($event)" accept=".png, .jpg, .jpeg" />
                        <label for="imageUpload"><em class="icon ni ni-pen2" style="position: absolute;top: 9px;right: 9px;"></em></label>
                      </div>
                      <div class="avatar-preview">
                        <div id="imagePreview" style="background-image: url({{bgImageProfile}});">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <!--<div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="prop_id">{{'Property' | translate}}</label>
                      <div class="form-control-wrap">
                        <ng-select [formControl]="tenantFormControls.prop_id" name="prop_id" id="prop_id" placeholder="Select Property">
                          <ng-option *ngFor="let property of propertyList.data" [value]="property.prop_id">{{ property.prop_name}}</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="tenantFormControls.prop_id.hasError('required') && (tenantFormControls.prop_id.touched || tenantFormControls.prop_id.dirty)">
                        {{'Property name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                      </div>
                    </div>
                  </div>-->
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="tenant_name">{{'Name' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-user"></em></div>
                        <input type="text" [formControl]="tenantFormControls.tenant_name" name="tenant_name" class="form-control" id="tenant_name" placeholder="Enter name">
                        <span class="invalid" *ngIf="tenantFormControls.tenant_name.hasError('required') && (tenantFormControls.tenant_name.touched || tenantFormControls.tenant_name.dirty)">
                          {{'Tenant name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--<div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="country">{{'Country' | translate}}</label>
                      <div class="form-control-wrap">
                        <ng-select [formControl]="tenantFormControls.tenant_country" (change)="fetchState($event)" name="tenant_country" id="country" placeholder="Select Country">
                          <ng-option *ngFor="let country of countryList.data" [value]="country.country_id">{{ country.country_name }}</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="tenantFormControls.tenant_country.hasError('required') && (tenantFormControls.tenant_country.touched || tenantFormControls.tenant_country.dirty)">
                        {{'Country' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                      </div>
                    </div>
                  </div>-->
                  <!--<div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="state">{{'State' | translate}}</label>
                      <div class="form-control-wrap">
                        <ng-select [formControl]="tenantFormControls.tenant_state" (change)="fetchCity($event)" name="tenant_state" id="state" placeholder="Select State">
                          <ng-option *ngFor="let state of stateList.data" [value]="state.state_id">{{ state.state_name }}</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="tenantFormControls.tenant_state.hasError('required') && (tenantFormControls.tenant_state.touched || tenantFormControls.tenant_state.dirty)">
                        {{'State' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                      </div>
                    </div>
                  </div>-->
                  <!--<div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="city">{{'City' | translate}}</label>
                      <div class="form-control-wrap">
                        <ng-select [formControl]="tenantFormControls.tenant_city" name="tenant_city" id="city" placeholder="Select City">
                          <ng-option *ngFor="let city of cityList.data" [value]="city.city_id">{{city.city_name}}</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="tenantFormControls.tenant_city.hasError('required') && (tenantFormControls.tenant_city.touched || tenantFormControls.tenant_city.dirty)">
                        {{'City' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                      </div>
                    </div>
                  </div>-->
                  <!--<div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="pin-code">{{'Pin Code' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-home"></em></div>
                        <input type="text" [formControl]="tenantFormControls.tenant_pincode" name="tenant_pincode" class="form-control" id="pin-code" placeholder="Enter pin code">
                        <span class="invalid" *ngIf="(tenantFormControls.tenant_pincode.touched || tenantFormControls.tenant_pincode.dirty)">
                        <span *ngIf="tenantFormControls.tenant_pincode.hasError('required')">{{'Pin Code' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        <span *ngIf="tenantFormControls.tenant_pincode.hasError('minlength') || tenantFormControls.tenant_pincode.hasError('maxlength')">{{'Pin Code length should be 6' | translate}}</span>
                      </span>
                      </div>
                    </div>
                  </div>-->
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="contact-no">{{'Contact No' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-mobile"></em></div>
                        <input type="text" [formControl]="tenantFormControls.tenant_phone" name="tenant_phone" class="form-control" id="contact-no" placeholder="Enter contact no.">
                        <span class="invalid" *ngIf="(tenantFormControls.tenant_phone.touched || tenantFormControls.tenant_phone.dirty)">
                        <span *ngIf="tenantFormControls.tenant_phone.hasError('required')">{{'Contact No' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        <span *ngIf="tenantFormControls.tenant_phone.hasError('minlength')">{{'Contact No should have at-least 10 digit' | translate}}</span>
                      </span>
                      </div> 
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="contact-email">{{'Contact Email' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-emails"></em></div>
                        <input type="text" [formControl]="tenantFormControls.tenant_email" name="tenant_email" class="form-control" id="contact-email" placeholder="Enter contact email">
                      </div>
                    </div>
                  </div>
                  <!--<div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="contact-email">{{'Rent' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-sign-inr"></em></div>
                        <input type="text" [formControl]="tenantFormControls.tenant_rent" name="tenant_rent" class="form-control" id="tenant_rent" placeholder="Enter Rent">
                        <span class="invalid" *ngIf="(tenantFormControls.tenant_rent.dirty || tenantFormControls.tenant_rent.touched) && tenantFormControls.tenant_rent.hasError('pattern')">
                          {{'Rent should be integer' | translate}}
                        </span>
                      </div>
                    </div>
                  </div>-->
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label" for="address-1">{{'Address' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-location"></em></div>
                        <textarea [formControl]="tenantFormControls.tenant_address" name="tenant_address" class="form-control" id="address-1" placeholder="Enter full address" style="min-height:70px;"></textarea>
                        <span class="invalid" *ngIf="tenantFormControls.tenant_address.hasError('required') && (tenantFormControls.tenant_address.touched || tenantFormControls.tenant_address.dirty)">
                        {{'Address' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-md-10">
                    <div class="form-group">
                      <label class="form-label">{{'Add Members' | translate}}</label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <button type="button" class="btn btn-primary" (click)="addMembers()"><em class="icon ni ni-plus"></em></button>
                    </div>
                  </div>
                </div>
                <div class="row gy-4" *ngFor="let member of membersList; let i = index;">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="address-1">{{'Name' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-user"></em></div>
                        <input type="text" name="member_name_{{i}}" id="member_name_{{i}}" class="form-control" [value]="member.name" placeholder="Enter member name">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="form-label" for="address-1">{{'Relation' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-user"></em></div>
                        <input type="text" name="member_relation_{{i}}" id="member_relation_{{i}}" [value]="member.relation" class="form-control" placeholder="Enter relation">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group" style="margin-top: 30px;">
                      <label class="form-label"></label>
                      <button type="button" class="btn btn-danger" (click)="removeMembers(i)"><em class="icon ni ni-minus"></em></button>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label">{{'File Upload' | translate}} ({{'ID Proof - Aadhaar card, Pan card, Voter Id, License...' | translate}})</label>
                      <div class="form-control-wrap">
                        <div class="custom-file">
                          <input type="file" name="image" ng2FileSelect (change)="tenantAttachmentsUpload($event)" class="custom-file-input">
                          <label class="custom-file-label">{{'Choose file' | translate}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 row">
                    <div class="col-sm-1" *ngFor="let files of tenantFilesList" style="border: 1px solid #e5e9f2;margin-left: 15px;">
                      <img src="{{files.path}}"  alt="Preview" *ngIf="files.type=='image'">
                      <video *ngIf="files.type=='video'" width="100%" height="100%" poster="assets/images/play-button.png">
                        <source src="{{files.path}}" type="video/mkv">
                      </video>
                      <img *ngIf="files.type!='video' && files.type != 'image'" src="assets/file_icons/{{files.ext}}.png">
                      <span class="file-title">{{files.original_name}}</span>
                      <a class="remove-image" (click)="deleteTenantFiles(files.id)" style="display: inline;">&#215;</a>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <button type="submit" class="btn btn-primary" data-style="slide-down" [ladda]="isBtnLoading" [disabled]="!tenantAddForm.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
