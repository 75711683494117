<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Tenants' | translate}}</h3>
            <div class="nk-block-des text-soft">
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="admin/tenants" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-arrow-left"></em><span>{{'Back' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
          <div class="card-inner">
            <ul class="nav nav-tabs mt-n3">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabItem1"><em class="icon ni ni-user"></em><span>{{'Personal' | translate}}</span></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabItem2"><em class="icon ni ni-file"></em><span>{{'Attachments' | translate}}</span></a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tabItem1">
                <table class="nk-tb-list nk-tb-ulist table-bordered" *ngIf="tenantData">
                  <tr>
                    <th class="nk-tb-col">{{'Tenant' | translate}} {{'ID' | translate}}</th>
                    <td>{{tenantData.tenant_id}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Tenant' | translate}} {{'Name' | translate}}</th>
                    <td>{{tenantData.tenant_name}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Contact No' | translate}}</th>
                    <td>{{tenantData.tenant_phone}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Email' | translate}}</th>
                    <td>{{tenantData.tenant_email}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Rent' | translate}}</th>
                    <td>{{tenantData.tenant_rent}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Address' | translate}}</th>
                    <td>{{tenantData.tenant_address}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Property' | translate}}</th>
                    <td>{{tenantData.prop_name}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Start Date' | translate}}</th>
                    <td>{{tenantData.start_date | date: 'dd/MM/yyyy'}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'End Date' | translate}}</th>
                    <td>{{tenantData.end_date | date: 'dd/MM/yyyy'}}</td>
                  </tr>
                </table>
              </div>
              <div class="tab-pane" id="tabItem2">
                <div class="row gy-4">
                  <hr class="preview-hr">
                  <div class="col-md-12">
                    <section *ngIf="tenantFilesList.length > 0">
                      <ks-modal-gallery
                        [id]="3"
                        [modalImages]="tenantFilesList"
                        [plainGalleryConfig]="plainGalleryGrid"
                      >
                      </ks-modal-gallery>
                    </section>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-md-1" *ngFor="let otherFile of otherFiles" style="text-align: center;">
                    <a href="{{otherFile.path}}" target="_blank"><img src="assets/file_icons/{{otherFile.ext}}.png"></a>
                    <span class="preview-icon-name" style="font-size: 10px;">{{otherFile.original_name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- .card-preview -->
      </div>
    </div>
  </div>
</div>
