<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Test' | translate}}</h3>
            <div class="nk-block-des text-soft">
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
              </div>
            </div>
          </div>
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-stretch">
          <div class="card-inner-group">
            <div class="card-inner position-relative card-tools-toggle">
              <div class="card-title-group">
                <div class="card-tools">
                  <input type="text" [formControl]="datePicker" id="datePicker" class="form-control date-picker">
                  <button type="button" [ladda]="btnLoading" data-style="slide-down" (click)="checkFunction()" class="btn btn-dim btn-outline-primary">Check</button>
                </div><!-- .card-tools -->
              </div><!-- .card-title-group -->
            </div><!-- .card-inner -->
            <div class="card-inner">
              <table class="datatable-init nk-tb-list nk-tb-ulist" id="testTable" data-auto-responsive="false">
                <thead>
                <tr class="nk-tb-item nk-tb-head">
                  <th class="nk-tb-col"><span class="sub-text">User</span></th>
                  <th class="nk-tb-col tb-col-md"><span class="sub-text">Phone</span></th>
                  <th class="nk-tb-col tb-col-lg"><span class="sub-text">Address</span></th>
                  <th class="nk-tb-col tb-col-md"><span class="sub-text">Status</span></th>
                </tr>
                </thead>
                <tbody>
                  <tr class="nk-tb-item" *ngFor="let data of tableData">
                    <td class="nk-tb-col">
                      <div class="user-card">
                        <div class="user-avatar bg-dim-primary d-none d-sm-flex">
                          <span>AB</span>
                        </div>
                        <div class="user-info">
                          <span class="tb-lead">{{data.name}} <span class="dot dot-success d-md-none ml-1"></span></span>
                          <span>{{data.email}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="nk-tb-col tb-col-md">
                      <span>+91-{{data.phone}}</span>
                    </td>
                    <td class="nk-tb-col tb-col-lg">
                      <span>{{data.address}}</span>
                    </td>
                    <td class="nk-tb-col tb-col-md">
                      <span class="tb-status text-success">{{data.status}}</span>
                    </td>
                  </tr><!-- .nk-tb-item  -->
                </tbody>
              </table>
            </div><!-- .card-inner -->
          </div><!-- .card-inner-group -->
        </div><!-- .card -->
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
