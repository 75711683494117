<div class="container-fluid">
  <div class="nk-footer-wrap">
    <div class="nk-footer-copyright"> &copy; {{appConst.CURRENT_YEAR}} Cuztomise Softech Pvt. Ltd.
    </div>
    <div class="nk-footer-links">
      <ul class="nav nav-sm">
        <li class="nav-item"><a class="nav-link" href="javascript:void(0)">{{'Terms' | translate}}</a></li>
        <li class="nav-item"><a class="nav-link" href="#">{{'Privacy' | translate}}</a></li>
        <li class="nav-item"><a class="nav-link" href="#">{{'Help' | translate}}</a></li>
      </ul>
    </div>
  </div>
</div>
