<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Dashboard' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p>{{'Monthly statics' | translate}}.</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li>
                    <div class="drodown">
                      <a href="#" class="dropdown-toggle btn btn-white btn-dim btn-outline-light" data-toggle="dropdown"><em class="d-none d-sm-inline icon ni ni-calender-date"></em><span><span class="d-none d-md-inline">{{lastMonthFilter | translate}}</span> </span><em class="dd-indc icon ni ni-chevron-right"></em></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <ul class="link-list-opt no-bdr">
                          <li><a (click)="reloadDashboardGraphs(1, 'Last 30 Days')"><span>{{'Last 30 Days' | translate}}</span></a></li>
                          <li><a (click)="reloadDashboardGraphs(3, 'Last 3 Months')"><span>{{'Last 3 Months' | translate}}</span></a></li>
                          <li><a (click)="reloadDashboardGraphs(6, 'Last 6 Months')"><span>{{'Last 6 Months' | translate}}</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </li>
<!--                  <li class="nk-block-tools-opt"><a href="#" class="btn btn-primary"><em class="icon ni ni-reports"></em><span>Reports</span></a></li>-->
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="row g-gs">
          <div class="col-md-6">
              <div class="card card-bordered">
                <div class="card-inner">
                  <div class="card-title-group align-start mb-2">
                    <div class="card-title">
                      <h6 class="title">{{'Active Properties' | translate}}</h6>
                    </div>
                    <div class="card-tools">
                      <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left" title="Total active subscription"></em>
                    </div>
                  </div>
                  <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                    <div class="nk-sale-data">
                      <span class="amount">{{propertyActiveCount}}</span>
                    </div>
                    <div class="nk-sales-ck">
                      <canvas id="property_count_tab" width="200" height="100"></canvas>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-md-6">
              <div class="card card-bordered">
                  <div class="card-inner">
                    <div class="card-title-group align-start mb-2">
                      <div class="card-title">
                        <h6 class="title">{{'Active Tenants' | translate}}</h6>
                      </div>
                      <div class="card-tools">
                        <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left" title="Daily Avg. subscription"></em>
                      </div>
                    </div>
                    <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div class="nk-sale-data">
                        <span class="amount">{{tenantActiveCount}}</span>
                      </div>
                      <div class="nk-sales-ck">
                        <canvas id="tenant_count_tab"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div class="col-md-8">
            <div class="card card-bordered h-100">
              <div class="card-inner">
                <div class="card-title-group pb-3 g-2">
                  <div class="card-title card-title-sm">
                    <h6 class="title">{{'Rent Collection' | translate}} {{appConstants.CURRENT_YEAR}} (<em class="icon ni ni-sign-inr"></em>{{totalRentCollection}})</h6>
                  </div>
                </div>
                <div class="analytic-ov">
                  <canvas id="rent_collection_line_chart"></canvas>
                </div>
              </div>
            </div><!-- .card -->
          </div>
          <div class="col-md-4">
            <div class="card card-bordered h-100">
              <div class="card-inner">
                <div class="card-title-group">
                  <div class="card-title card-title-sm">
                    <h6 class="title">{{'Rent Collection' | translate}} ({{tenantActiveCount}})</h6>
                  </div>
                </div>
                <canvas id="rent_collection_pie"></canvas>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card card-bordered card-full">
              <div class="card-inner">
                <div class="card-title-group">
                  <div class="card-title">
                    <h6 class="title"><span class="mr-2">{{'Last Five Transaction' | translate}}</span></h6>
                  </div>
                </div>
              </div>
              <div class="card-inner p-0 border-top">
                <div class="nk-tb-list nk-tb-orders">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>{{'Property' | translate}}</span></div>
                    <div class="nk-tb-col tb-col-sm"><span>{{'Tenant' | translate}}</span></div>
                    <div class="nk-tb-col tb-col-md"><span>{{'Date' | translate}}</span></div>
                    <div class="nk-tb-col"><span>{{'Amount' | translate}}</span></div>
                    <div class="nk-tb-col"><span class="d-none d-sm-inline">{{'Status' | translate}}</span></div>
                    <div class="nk-tb-col"><span>&nbsp;</span></div>
                  </div>
                  <div class="nk-tb-item" *ngFor="let lastFiveRent of lastFiveTransaction.data">
                    <div class="nk-tb-col tb-col-sm">
                      <div class="user-card">
                        <div class="user-avatar user-avatar-sm {{commonHelper.getThumbnailBG(lastFiveRent.tenant_name)}}">
                          <span>{{commonHelper.getNameThumbnail(lastFiveRent.tenant_name)}}</span>
                        </div>
                        <div class="user-name">
                          <span class="tb-lead">{{lastFiveRent.tenant_name}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col tb-col-sm">
                      <div class="user-card">
                        <div class="user-avatar user-avatar-sm {{commonHelper.getNameThumbnail(lastFiveRent.prop_name)}}">
                          <span>{{commonHelper.getNameThumbnail(lastFiveRent.prop_name)}}</span>
                        </div>
                        <div class="user-name">
                          <span class="tb-lead">{{lastFiveRent.prop_name}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col tb-col-md">
                      <span class="tb-sub">{{lastFiveRent.payment_date | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub tb-amount"><em class="icon ni ni-sign-inr"></em>{{lastFiveRent.total_rent_paid}}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="badge badge-dot badge-dot-xs badge-success">{{'Paid' | translate}}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-action">
                      <div class="dropdown">
                        <a class="text-soft dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                          <ul class="link-list-opt no-bdr">
                            <li><a href="admin/viewRent/{{commonHelper.encodeURIParam(lastFiveRent.rent_collection_id)}}"><em class="icon ni ni-eye"></em><span>{{'View' | translate}}</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><!-- .card -->
          </div><!-- .col -->
          <div class="col-md-4">
            <div class="card card-bordered card-full">
              <div class="card-inner-group">
                <div class="card-inner">
                  <div class="card-title-group">
                    <div class="card-title">
                      <h6 class="title">{{'New Tenants' | translate}}</h6>
                    </div>
                    <div class="card-tools">
                      <a href="admin/tenants" class="link">{{'View All' | translate}}</a>
                    </div>
                  </div>
                </div>
                <div class="card-inner card-inner-md" *ngFor="let tenant of tenantsList.data">
                  <div class="user-card">
                    <div class="user-avatar {{commonHelper.getThumbnailBG(tenant.tenant_name)}}">
                      <span>{{commonHelper.getNameThumbnail(tenant.tenant_name)}}</span>
                    </div>
                    <div class="user-info">
                      <span class="lead-text">{{tenant.tenant_name}}</span>
                      <span class="sub-text">{{tenant.tenant_email}}</span>
                    </div>
                    <div class="user-action">
                      <div class="drodown">
                        <a href="#" class="dropdown-toggle btn btn-icon btn-trigger mr-n1" data-toggle="dropdown" aria-expanded="false"><em class="icon ni ni-more-h"></em></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li><a href="admin/viewTenant/{{commonHelper.encodeURIParam(tenant.tenant_id)}}"><em class="icon ni ni-eye"></em><span>View</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><!-- .card -->
          </div><!-- .col -->
        </div><!-- .row -->
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
