import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../../app-config/app-constants';
import { CommonHelper } from '../../app-config/common-helper';
import {CommonService} from '../../services/common.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {GridLayout, Image, PlainGalleryConfig, PlainGalleryStrategy} from '@ks89/angular-modal-gallery';
declare const $: any;

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.css']
})
export class TenantsComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private ngxSpinnerService: NgxSpinnerService
  ) { }
  tenantsList: any = this.appConst.API_RESPONSE;
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  ngOnInit(): void {
      this.ngxSpinnerService.show();
      this.translate.use(localStorage.getItem("lang"));
      this.fetchTenants();
      this.ngxSpinnerService.hide();
  }
  fetchTenants (type='view') {
      const paramData: any = {
        dbname: this.appConst.DATABASE,
        type: type
      }
      if (this.localUser.user_role == "OWNER")
        paramData.ownerId = this.localUser.userid;
      else if (this.localUser.user_role == "TENANT")
        paramData.tenantId = this.localUser.tenant_id;
      this.ngxSpinnerService.show()
      this.commonService.fetchTenantsService(paramData).subscribe(
          Response => {
              const apiResponse: any = Response;
              console.log("fetchTenants :", apiResponse);
              if (type == "excel") {
                if (apiResponse.opcode === 1) {
                  window.open(this.appConst.API_END + "common/download?file_path=" + apiResponse.data.filePath);
                }
                else {
                  Swal.fire("", this.translate.instant("No tenants found in the system."), "warning");
                }
              }
              else
                this.tenantsList = apiResponse;
              this.ngxSpinnerService.hide();
          },
          Error => {
              console.log("fetchTenants :", Error);
              this.ngxSpinnerService.hide();
          }
      );
  }
  deleteTenants(tenantId, tenantUserId) {
    this.commonService.deleteTenantService({dbname: this.appConst.DATABASE, tenantId: tenantId, userId: tenantUserId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant("Tenant successfully deleted"), "success");
          this.fetchTenants();
        }
        else {
          Swal.fire("", this.translate.instant("Connection error"), "error");
        }
      },
      Error => {
        console.log("deleteTenants Error:", Error);
      }
    );
  }
  changeTenantsStatus(status, tenantId, tenantUserId, tenantEmail) {
    const paramData = {
      dbname: this.appConst.DATABASE,
      status: status,
      tenantId: tenantId,
      userId: tenantUserId,
      tenantEmail: tenantEmail
    };
    let statusTxt = "Deactivated";
    if (status == 1)
      statusTxt = "Activated";
    this.commonService.changeTenantStatusService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant("Tenant successfully "+statusTxt), "success");
          this.fetchTenants();
        }
        else {
          Swal.fire("", this.translate.instant(apiResponse.message), "error");
        }
      },
      Error => {
        console.log("changeTenantsStatus Error:", Error);
      }
    );
  }
}
@Component({
  selector: 'app-add-tenants',
  templateUrl: './add-tenant.component.html',
  styleUrls: ['./tenants.component.css']
})
export class AddTenantComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private urlRoute: ActivatedRoute,
    public datePipe: DatePipe,
    private ngxSpinnerService: NgxSpinnerService
  ) { translate.setDefaultLang("en"); }
  tenantsList: any = this.appConst.API_RESPONSE;
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  tenantAddForm = new FormGroup({
    // prop_id: new FormControl(null, [Validators.required]),
    tenant_name: new FormControl('', [Validators.required]),
    tenant_address: new FormControl('', [Validators.required]),
    // tenant_country: new FormControl(null, [Validators.required]),
    // tenant_state: new FormControl(null, [Validators.required]),
    // tenant_city: new FormControl(null, [Validators.required]),
    // tenant_pincode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    tenant_phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
    tenant_email: new FormControl(),
    tenant_profile: new FormControl(),
    tenant_user_id: new FormControl(0),
    // tenant_rent: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.required])
  });
  isBtnLoading: boolean = false;
  manageFlag: string = 'add';
  propertyList: any = this.appConst.API_RESPONSE;
  countryList: any = this.appConst.API_RESPONSE;
  stateList: any = this.appConst.API_RESPONSE;
  cityList: any = this.appConst.API_RESPONSE;
  bgImageProfile: any = "assets/images/user_avatar.png";
  profileData: any;
  tenantFilesList: any = [];
  membersList: any = [];
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    const tenantId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    if (tenantId != '0') {
      this.editTenant(tenantId);
      this.manageFlag = "edit";
    }
    // this.fetchProperty();
    // this.fetchCountry();
  }
  get tenantFormControls() {
    return this.tenantAddForm.controls;
  }
  addTenantDetails(manageFlag) {
      console.log("tenantFilesList :", this.tenantFilesList);
      const fileId = this.commonHelper.convertToStringByKey("id", this.tenantFilesList);
      const formData = new FormData();
      const tenantData = this.tenantAddForm.value;
      if (manageFlag == "add")
        tenantData.tenant_created_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
      else
        tenantData.tenant_last_updated = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
      if (this.membersList.length > 0) {
        const memberArray = [];
        for (let i=0; i < this.membersList.length; i++) {
          const memberName = $("#member_name_"+i).val();
          const memberRelation = $("#member_relation_"+i).val();
          if (memberName != "" && memberName != null) {
            const memberObj = {
              name: memberName,
              relation: memberRelation
            };
            memberArray.push(memberObj);
          }
        }
        tenantData.tenant_members = JSON.stringify(memberArray);
      }
      formData.append("prop_file", this.profileData);
      formData.append("data", JSON.stringify(tenantData));
      formData.append("dbname", this.appConst.DATABASE);
      formData.append("tenantId", this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id")));
      formData.append("fileId", fileId);
      formData.append("ownerId", this.localUser.userid);
      formData.append("sessionUserId", this.localUser.userid);
      this.isBtnLoading = true;
      this.commonService.addTenantsService(formData, manageFlag).subscribe(
          Response => {
            const apiResponse: any = Response;
            if (apiResponse.opcode === 1) {
              Swal.fire("", this.translate.instant("Tenant") + " " + this.translate.instant("data successfully saved"), "success").then((r)=>{
                window.location.href = "admin/tenants";
              });
            }
            else {
              Swal.fire("", this.translate.instant(apiResponse.message), "error");
            }
            this.isBtnLoading = false;
          },
        Error => {
          console.log("addTenantDetails ", Error);
          Swal.fire("", this.translate.instant("Connection error") + this.translate.instant(".") + this.translate.instant("Please try again"), "error");
          this.isBtnLoading = false;
        }
      );
  }
  previewProfileImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.profileData = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.bgImageProfile = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  fetchProperty() {
    const paramData: any = {
      dbname: this.appConst.DATABASE
    }
    if (this.localUser.user_role == "OWNER")
      paramData.userId = this.localUser.userid;
    else if (this.localUser.user_role == "TENANT")
      paramData.propertyId = this.localUser.prop_id;
    else
      paramData.isDeleted = 0;
    this.commonService.fetchPropertyService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("fetchProperty :", apiResponse);
        this.propertyList = apiResponse;
      },
      Error => {
        console.log("fetchProperty :", Error);
      }
    );
  }
  fetchCountry() {
    this.commonService.fetchCountryData({dbname: this.appConst.DATABASE}).subscribe(
      Response => {
        const apiResponse: any = Response;
        this.countryList = apiResponse;
      },
      Error => {
        console.log("fetchCountry :", Error);
      }
    );
  }
  fetchState(event) {
    console.log("event :", event);
    this.commonService.fetchStateData({dbname: this.appConst.DATABASE, countryId: event}).subscribe(
      Response => {
        const apiResponse: any = Response;
        this.stateList = apiResponse;
        console.log("stateList :", this.stateList);
      },
      Error => {
        console.log("fetchState :", Error);
      }
    );
  }
  fetchCity(event) {
    this.commonService.fetchCityData({dbname: this.appConst.DATABASE, stateId: event}).subscribe(
      Response => {
        const apiResponse: any = Response;
        this.cityList = apiResponse;
        console.log("cityList :", this.cityList);
      },
      Error => {
        console.log("fetchCity :", Error);
      }
    );
  }
  editTenant(tenantId) {
    this.commonService.fetchTenantsService({dbname: this.appConst.DATABASE, tenantId: tenantId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("editTenant apiResponse :", apiResponse);
        if (apiResponse.opcode === 1) {
          const data = apiResponse.data[0];
          // this.tenantAddForm.controls.prop_id.setValue(data.prop_id);
          this.tenantAddForm.controls.tenant_user_id.setValue(data.tenant_user_id);
          this.tenantAddForm.controls.tenant_name.setValue(data.tenant_name);
          this.tenantAddForm.controls.tenant_address.setValue(data.tenant_address);
          // this.tenantAddForm.controls.tenant_country.setValue(parseInt(data.tenant_country));
          // this.fetchState(parseInt(data.tenant_country));
          // this.tenantAddForm.controls.tenant_state.setValue(parseInt(data.tenant_state));
          // this.fetchCity(parseInt(data.tenant_state));
          // this.tenantAddForm.controls.tenant_city.setValue(parseInt(data.tenant_city));
          // this.tenantAddForm.controls.tenant_pincode.setValue(data.tenant_pincode);
          this.tenantAddForm.controls.tenant_phone.setValue(data.tenant_phone);
          this.tenantAddForm.controls.tenant_email.setValue(data.tenant_email);
          // this.tenantAddForm.controls.tenant_rent.setValue(data.tenant_rent);
          if (data.tenant_profile !== null && data.tenant_profile !== "")
            this.bgImageProfile =  this.appConst.API_END + data.tenant_profile;
          if(data.tenant_members != "" && data.tenant_members != null) {
            this.membersList = JSON.parse(data.tenant_members);
          }
          this.commonService.fetchTenantFilesService({dbname: this.appConst.DATABASE, tenantId: tenantId}).subscribe(
            Response => {
              const apiFilesResponse: any = Response;
              if (apiFilesResponse.numRows > 0) {
                apiFilesResponse.data.forEach(fileValue => {
                  const fileType = fileValue.file_type.split("/");
                  const fileExt = this.commonHelper.getFileExtentionByType(fileValue.file_type);
                  const fileData = {
                    id: fileValue.tenant_file_id,
                    type: fileType[0],
                    path: this.appConst.API_END + this.appConst.TENANT_FILE + fileValue.file_name,
                    ext: fileExt,
                    original_name: fileValue.original_name
                  }
                  this.tenantFilesList.push(fileData);
                });
              }
              console.log("tenantFilesList :", this.tenantFilesList);
            }
          );
        }
      },
      Error => {
        console.log("editTenant Error :", Error);
      }
    );
  }
  tenantAttachmentsUpload(event) {
    const formData = new FormData();
    // @ts-ignore
    if (event.target.files.length > 0) {
      // @ts-ignore
      formData.append("prop_file", event.target.files[0]);
      formData.append("dbname", this.appConst.DATABASE);
      formData.append("sessionUserId", this.localUser.userid);
      this.ngxSpinnerService.show();
      this.commonService.uploadTenantsFilesService(formData).subscribe(
        Response => {
          console.log("Response :", Response);
          const apiResponse: any = Response;
          if (apiResponse.opcode === 1) {
            const fileType = apiResponse.data.file_type.split("/");
            const fileExt = this.commonHelper.getFileExtentionByType(apiResponse.data.file_type);
            const fileData = {
              id: apiResponse.data.tenant_file_id,
              type: fileType[0],
              path: this.appConst.API_END + this.appConst.TENANT_FILE + apiResponse.data.file_name,
              ext: fileExt,
              original_name: apiResponse.data.original_name
            }
            this.tenantFilesList.push(fileData);
          }
          else {
            Swal.fire("",this.translate.instant("file data not saved") + this.translate.instant(".") + this.translate.instant("Please try again"), "error").then();
          }
          this.ngxSpinnerService.hide();
        },
        Error => {
          console.log("Error :", Error);
          this.ngxSpinnerService.hide();
        }
      );
    }
  }
  deleteTenantFiles(id) {
    console.log("File Id :", id);
    this.ngxSpinnerService.show();
    this.commonService.deleteTenantFilesService({dbname: this.appConst.DATABASE, id: id}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1) {
          const removeItem = this.tenantFilesList.filter((item) => item.id !== id);
          this.tenantFilesList = removeItem;
        }
        else {
          Swal.fire("", this.translate.instant("Connection error"), "error").then();
        }
        this.ngxSpinnerService.hide();
      },
      Error => {
        console.log("Error :", Error);
        this.ngxSpinnerService.hide();
      }
    );
  }
  addMembers() {
    const memberObj = {
      name: '',
      relation: ''
    };
    this.membersList.push(memberObj);
  }
  removeMembers(index) {
    this.membersList.splice(index, 1);
  }
}
@Component({
  selector: 'app-view-tenant',
  templateUrl: './view-tenant.component.html',
  styleUrls: ['./tenants.component.css']
})
export class ViewTenantComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private urlRoute: ActivatedRoute,
    public datePipe: DatePipe,
  ) { translate.setDefaultLang("en"); }
  tenantData: any;
  tenantFilesList: any = [];
  otherFiles: any = [];
  plainGalleryGrid: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.GRID,
    layout: new GridLayout({ width: '100px', height: '100px' }, { length: 3, wrap: true }),
  };
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    const tenantId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    this.viewTenantDetails(tenantId);
    this.fetchTenantFiles(tenantId);
  }
  private viewTenantDetails(tenantId) {
    this.commonService.fetchTenantsService({dbname: this.appConst.DATABASE, tenantId: tenantId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("viewTenantDetails apiResponse :", apiResponse);
        this.tenantData = apiResponse.data[0];
      },
      Error => {
        console.log("viewTenantDetails Error :", Error);
      }
    );
  }
  fetchTenantFiles (tenantId) {
    this.commonService.fetchTenantFilesService({dbname: this.appConst.DATABASE, tenantId: tenantId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("fetchTenantFiles :", apiResponse);
        if (apiResponse.numRows > 0) {
          let fileCount: number = 0;
          apiResponse.data.forEach(fileValue => {
            const fileType = fileValue.file_type.split("/");
            const fileExt = this.commonHelper.getFileExtentionByType(fileValue.file_type);
            if (fileType[0].includes('image')) {
              this.tenantFilesList.push(new Image(fileCount, {
                img: this.appConst.API_END + this.appConst.TENANT_FILE + fileValue.file_name,
                description: 'Tenant File'
              }));
              fileCount++;
            }
            else {
              this.otherFiles.push(
                {
                  id: fileValue.prop_file_id,
                  type: fileType[0],
                  path: this.appConst.API_END + this.appConst.TENANT_FILE + fileValue.file_name,
                  ext: fileExt,
                  original_name:fileValue.original_name
                }
              );
            }
          });
          console.log("tenantFilesList :", this.tenantFilesList);
        }
      }
    );
  }
}
