<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Rent' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p>{{ 'Rent Collection details submitted by tenants/property staff' | translate}}</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="admin/rent/{{rentMonthParam}}/{{rentYearParam}}" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-arrow-left"></em><span>{{'Back' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
          <div class="card-inner">
            <ul class="nav nav-tabs mt-n3">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabItem1"><em class="icon ni ni-user"></em><span>{{'Description' | translate}}</span></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabItem2"><em class="icon ni ni-file"></em><span>{{'Attachments' | translate}}</span></a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tabItem1">
                <table class="nk-tb-list nk-tb-ulist table-bordered" *ngIf="rentDetails">
                  <tr>
                    <th class="nk-tb-col">{{'Property' | translate}} {{'Name' | translate}}</th>
                    <td>{{rentDetails.prop_name}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Tenant' | translate}} {{'Name' | translate}}</th>
                    <td>{{rentDetails.tenant_name}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Tax Rate' | translate}}</th>
                    <td>{{rentDetails.tax_rate}}%</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Payment By' | translate}}</th>
                    <td>{{rentDetails.payment_by | translate}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Month' | translate}}</th>
                    <td>{{commonHelper.getMonthName(rentDetails.rent_month)}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Year' | translate}}</th>
                    <td>{{rentDetails.rent_year}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Payment Date' | translate}}</th>
                    <td>{{rentDetails.payment_date | date: 'dd/MM/yyyy'}}</td>
                  </tr>
                  <tr>
                    <th class="nk-tb-col">{{'Payable Amount' | translate}}</th>
                    <td style="padding: 0px;">
                      <table style="width: 100%;">
                        <tr>
                          <th class="nk-tb-col">{{'Base Amount' | translate}}</th>
                          <td class="text-success">+{{rentDetails.base_amount}}</td>
                        </tr>
                        <tr>
                          <th class="nk-tb-col">{{'Tax Amount' | translate}}</th>
                          <td class="text-success">+{{rentDetails.tax_amount}}</td>
                        </tr>
                        <tr *ngFor="let rentHead of rentHeadList">
                          <th class="nk-tb-col">{{rentHead.head | translate}}</th>
                          <td class="text-success">+{{rentHead.amount}}</td>
                        </tr>
                        <tr>
                          <th class="nk-tb-col">{{'Discount' | translate}}</th>
                          <td class="text-danger">-{{rentDetails.discount}}</td>
                        </tr>
                        <tr>
                          <th class="nk-tb-col">{{"Total Amount" | translate}}</th>
                          <td><b>{{rentDetails.total_amount}}</b></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="tab-pane" id="tabItem2">
                <div class="row gy-4">
                  <div class="col-md-12">
                    <section *ngIf="rentFiles.length > 0">
                      <ks-modal-gallery [id]="3" [modalImages]="rentFiles"
                                        [plainGalleryConfig]="plainGalleryGrid"></ks-modal-gallery>
                    </section>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-1" *ngFor="let otherFile of otherFileList" style="text-align: center;">
                      <a href="{{otherFile.path}}" target="_blank"><img src="assets/file_icons/{{otherFile.ext}}.png"></a>
                      <span class="preview-icon-name" style="font-size: 10px;">{{otherFile.original_name}}</span>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tabItem3">
                <!--<agm-map [latitude]="lat" [longitude]="lng">
                  <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                </agm-map>-->
              </div>
            </div>
          </div>
        </div><!-- .card-preview -->
      </div>
    </div>
  </div>
</div>
