<div class="nk-app-root">
  <!-- main @s -->
  <div class="nk-main ">
    <!-- sidebar @s -->
    <div class="nk-sidebar nk-sidebar-fixed is-light" data-content="sidebarMenu">
      <app-sidebar></app-sidebar>
    </div>
    <!-- sidebar @e -->
    <!-- wrap @s -->
    <div class="nk-wrap ">
      <!-- main header @s -->
      <div class="nk-header nk-header-fixed is-light">
        <app-header></app-header>
      </div>
      <!-- main header @e -->
      <!-- content @S -->
      <div class="nk-content ">
        <router-outlet></router-outlet>
      </div>
      <!-- content @e -->
      <!-- footer @s -->
      <div class="nk-footer">
        <app-footer [pData]="data1" (sendData)="getDataFromChild($event)"></app-footer>
      </div>
      <!-- footer @e -->
    </div>
    <!-- wrap @e -->
  </div>
  <!-- main @e -->
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>
