<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Rent' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p *ngIf="manageFlag=='add'">{{'Fill new rent collection details' | translate}}</p>
              <p *ngIf="manageFlag=='edit'">{{'Edit rent collection details' | translate}}</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="admin/rent/{{rentMonthParam}}/{{rentYearParam}}" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-arrow-left"></em><span>{{'Back' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
          <div class="card-inner">
            <div class="preview-block">
              <span class="preview-title-lg overline-title"></span>
              <form [formGroup]="rentFormGroup" (submit)="addRentCollection(manageFlag)">
                <div class="row gy-4">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="rent_prop_id">{{'Property' | translate}}</label>
                      <div class="form-control-wrap">
                        <ng-select (change)="fetchTenantForRent($event)" [formControl]="rentFormControls.rent_prop_id" name="rent_prop_id" id="rent_prop_id" placeholder="Select Property">
                          <ng-option *ngFor="let property of rentPropertyList.data" [value]="property.prop_id">{{ property.prop_name}}</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="rentFormControls.rent_prop_id.hasError('required') && (rentFormControls.rent_prop_id.touched || rentFormControls.rent_prop_id.dirty)">
                        {{'Property name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="rent_tenant_id">{{'Tenant' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-user"></em></div>
                        <ng-select [formControl]="rentFormControls.rent_tenant_id" name="rent_tenant_id" id="rent_tenant_id" placeholder="Select Tenant">
                          <ng-option *ngFor="let tenant of rentTenantList.data" [value]="tenant.tenant_id">{{ tenant.tenant_name}}</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="rentFormControls.rent_tenant_id.hasError('required') && (rentFormControls.rent_tenant_id.touched || rentFormControls.rent_tenant_id.dirty)">
                        {{'Tenant Name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="base_amount">{{'Base Amount' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-sign-inr"></em></div>
                        <input type="text" [formControl]="rentFormControls.base_amount" name="base_amount" class="form-control" id="base_amount" placeholder="Enter Base Amount" (keyup)="addTotalPaidAmount()">
                        <span class="invalid" *ngIf="(rentFormControls.base_amount.touched || rentFormControls.base_amount.dirty)">
                          <span *ngIf="rentFormControls.base_amount.hasError('required')">{{'Base Amount' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                          <span *ngIf="rentFormControls.base_amount.hasError('pattern')">{{'Base amount should be number' | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="tax_rate">{{'Tax Rate' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-percent"></em></div>
                        <input type="text" [formControl]="rentFormControls.tax_rate" name="base_amount" class="form-control" id="tax_rate" placeholder="Enter Tax Rate">
                        <span class="invalid" *ngIf="(rentFormControls.tax_rate.touched || rentFormControls.tax_rate.dirty)">
                          <span *ngIf="rentFormControls.tax_rate.hasError('pattern')">{{'Tax rate should be number' | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="tax_rate">{{'Tax Amount' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-sign-inr"></em></div>
                        <input type="text" [formControl]="rentFormControls.tax_amount" name="tax_amount" class="form-control" id="tax_amount" placeholder="Enter Tax Amount" (keyup)="addTotalPaidAmount()">
                        <span class="invalid" *ngIf="(rentFormControls.tax_amount.touched || rentFormControls.tax_amount.dirty)">
                          <span *ngIf="rentFormControls.tax_amount.hasError('pattern')">{{'Tax amount should be number' | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="discount">{{'Discount On Rent' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-sign-inr"></em></div>
                        <input type="text" [formControl]="rentFormControls.discount" name="discount" class="form-control" id="discount" placeholder="Enter Discount">
                        <span class="invalid" *ngIf="(rentFormControls.discount.touched || rentFormControls.discount.dirty)">
                          <span *ngIf="rentFormControls.discount.hasError('pattern')">{{'Discount amount should be number' | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="payment_by">{{'Payment By' | translate}}</label>
                      <div class="form-control-wrap">
                        <ng-select [formControl]="rentFormControls.payment_by" name="payment_by" id="payment_by" placeholder="Select Payment By">
                          <ng-option value="Cash">Cash</ng-option>
                          <ng-option value="Cheque">Cheque</ng-option>
                          <ng-option value="Bank Transfer">Bank Transfer</ng-option>
                          <ng-option value="Other">Other</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="rentFormControls.payment_by.hasError('required') && (rentFormControls.payment_by.touched || rentFormControls.payment_by.dirty)">
                        {{'Payment By' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="rent_month">{{'Month' | translate}}</label>
                      <div class="form-control-wrap">
                        <ng-select [items]="monthList" bindValue="id" bindLabel="name" [formControl]="rentFormControls.rent_month" name="rent_month" id="rent_month" placeholder="Select Rent Month">
                        </ng-select>
                        <span class="invalid-select" *ngIf="rentFormControls.rent_month.hasError('required') && (rentFormControls.rent_month.touched || rentFormControls.rent_month.dirty)">
                        {{'Month' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="rent_year">{{'Year' | translate}}</label>
                      <div class="form-control-wrap">
                        <ng-select [items]="yearList" bindValue="id" bindLabel="name" [formControl]="rentFormControls.rent_year" name="rent_year" id="rent_year" placeholder="Select Rent Year">
                        </ng-select>
                        <span class="invalid-select" *ngIf="rentFormControls.rent_year.hasError('required') && (rentFormControls.rent_year.touched || rentFormControls.rent_year.dirty)">
                        {{'Year' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="payment_date">{{'Payment Date' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-calendar"></em>
                        </div>
                        <input  type="text" [formControl]="rentFormControls.payment_date" name="payment_date" id="payment_date" class="form-control date-picker" placeholder="Select Payment Date" data-date-format="yyyy-mm-dd">
                        <span class="invalid-select" *ngIf="rentFormControls.payment_date.hasError('required') && (rentFormControls.payment_date.touched || rentFormControls.payment_date.dirty)">
                        {{'Payment Date' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-md-10">
                    <div class="form-group">
                      <label class="form-label">{{'Add Extra Amount (Electricity, Maintenance, Water.....)' | translate}} </label>
                      <button type="button" (click)="rentTypeCtrl.setValue('')" class="btn btn-round btn-icon btn-xs btn-primary mg-left-10px" data-toggle="modal" data-target="#rent_type_modal"><em class="icon ni ni-plus"></em></button>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <button type="button" class="btn btn-primary" (click)="addExtraAmountOnRent()"><em class="icon ni ni-plus"></em></button>
                    </div>
                  </div>
                </div>
                <div class="row gy-4" *ngFor="let rentHead of rentHeadList; let i = index;">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="rent_head_type_{{i}}">{{'Amount Type' | translate}}</label>
                      <div class="form-control-wrap">
                        <select name="rent_head_type_{{i}}" class="form-select form-control" id="rent_head_type_{{i}}">
                          <option [value]="0">Select Rent Type</option>
                          <ng-container *ngFor="let rentType of rentTypeList.data">
                          <option [value]="rentType.rent_head_id" [selected]="rentType.rent_head_id==rentHead.head_id">{{rentType.rent_head_name}}</option>
                          </ng-container>
                        </select>
                        <!--<ng-select name="rent_head_type_{{i}}" id="rent_head_type_{{i}}" placeholder="Select Rent Type">
                          <ng-option *ngFor="let rentType of rentTypeList.data" [value]="rentType.rent_head_id" [selected]="rentType.rent_head_id==rentHead.head_id">{{rentType.rent_head_name | translate}}</ng-option>
                        </ng-select>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="form-label" for="rent_head_amount_{{i}}">{{'Amount' | translate}}</label>
                      <div class="form-control-wrap">
                        <input type="text" name="rent_head_amount_{{i}}" class="form-control" id="rent_head_amount_{{i}}" [value]="rentHead.amount" placeholder="Enter Amount" (keyup)="addTotalPaidAmount()">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group" style="margin-top: 30px;">
                      <label class="form-label"></label>
                      <button type="button" class="btn btn-danger" (click)="removeExtraRentAmount(i)"><em class="icon ni ni-minus"></em></button>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="total_amount">{{'Total Payable Amount' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-sign-inr"></em></div>
                        <input type="text" [formControl]="rentFormControls.total_amount" name="discount" class="form-control" id="total_amount" placeholder="Enter Payable Amount">
                        <span class="invalid" *ngIf="(rentFormControls.total_amount.touched || rentFormControls.total_amount.dirty)">
                          <span *ngIf="rentFormControls.total_amount.hasError('pattern')">{{'Payable amount should be number' | translate}}</span>
                          <span *ngIf="rentFormControls.total_amount.hasError('required')">{{'Total Payable Amount' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label">{{'File Upload' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="custom-file">
                          <input type="file" name="image" (change)="rentFilesUpload($event)" class="custom-file-input">
                          <label class="custom-file-label">{{'Choose file' | translate}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 row">
                    <div class="col-sm-1" *ngFor="let files of rentFileList" style="border: 1px solid #e5e9f2;margin-left: 15px;">
                      <img src="{{files.path}}"  alt="Preview" *ngIf="files.type=='image'">
                      <video *ngIf="files.type=='video'" width="100%" height="100%" poster="assets/images/play-button.png">
                        <source src="{{files.path}}" type="video/mkv">
                      </video>
                      <img *ngIf="files.type!='video' && files.type!='image'" src="assets/file_icons/{{files.ext}}.png">
                      <span class="file-title">{{files.original_name}}</span>
                      <a class="remove-image" (click)="deleteRentFile(files.id)" style="display: inline;">&#215;</a>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <button type="submit" class="btn btn-primary" data-style="slide-down" [ladda]="rentSaveBtnLoading" [disabled]="!rentFormGroup.valid">{{'Save' | translate}}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
<!-- Modal Content Code -->
<div class="modal fade zoom" id="rent_type_modal">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <a href="#" class="close" data-dismiss="modal" aria-label="Close">
        <em class="icon ni ni-cross"></em>
      </a>
      <div class="modal-header">
        <h5 class="modal-title">{{'New Rent Type' | translate}}</h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="form-label" for="expTypeCtrl">{{'Rent Type' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="rentTypeCtrl" name="expTypeCtrl" id="expTypeCtrl" class="form-control" placeholder="Enter here">
            <span class="invalid-select" *ngIf="rentTypeCtrl.hasError('required') && (rentTypeCtrl.touched || rentTypeCtrl.dirty)">
              {{'Rent Type' | translate}} {{appConst.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-primary" (click)="addNewRentType()" [ladda]="rentBtnLoading" [disabled]="!rentTypeCtrl.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
      </div>
    </div>
  </div>
</div>
