import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../app-config/app-constants';
import { DbTables } from '../../app-config/db-tables';
import { LoginService } from '../../services/login.service';
import { Md5 } from 'ts-md5';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {DatePipe} from '@angular/common';
import { CommonHelper } from '../../app-config/common-helper';
import 'rxjs/add/operator/filter';

interface langList {
  id: string,
  name: string
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public route: Router,
              private appConstant: AppConstants,
              public commonHelper: CommonHelper,
              private dbTable: DbTables,
              private loginService: LoginService,
              private md5: Md5,
              public translate: TranslateService,
              private socialAuthService: SocialAuthService,
              public datePipe: DatePipe,
              private urlRoute: ActivatedRoute
  ) {
      translate.setDefaultLang("en");
  }
  googleFlag: boolean = true;
  isLoggedIn: boolean;
  socialUser: SocialUser;
  langList: langList[] = [{id: "en", name: "English"}, {id: "hi", name: "Hindi"}];
  loginForm = new FormGroup({
    userName: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    language: new FormControl('en')
  })
  ngOnInit(): void {
      this.translate.use("en");

      this.urlRoute.queryParams
      .filter(params => params.isGoogleAuth)
      .subscribe(params => {
        this.googleFlag = params.isGoogleAuth;
        console.log(this.googleFlag);
      }
    );
    console.log("tafzil::: "+ this.googleFlag);
  }
  openSignupPage() {
    this.route.navigateByUrl('signup').then(r => console.log('Welcome to signup'));
  }
  userLogin() {
    console.log(this.loginForm.value);
    const username = this.loginForm.value.userName;
    const password = Md5.hashStr(this.loginForm.value.password);

    this.loginService.loginAuthentication(username, password).subscribe(
      Response => {
        const loginResponse: any = Response;
        console.log("loginResponse :", loginResponse);
        if (loginResponse.opcode === 1) {
          localStorage.setItem("token", loginResponse.data.token);
          localStorage.setItem("userData", JSON.stringify(loginResponse.data.user));
          localStorage.setItem("lang", this.loginForm.value.language);
          // this.route.navigateByUrl("admin/properties").then(r => {});
          window.location.href = "admin/dashboard";
        }
        else {
          Swal.fire("", loginResponse.message, "error");
        }
      },
      Error => {
        console.log("loginResponse Error:", Error);
        Swal.fire("", this.appConstant.API_ERROR, "error");
      }
    )
  }
  get loginFormControl() {
    return this.loginForm.controls;
  }
  storeLangVal(language) {
    console.log(language);
  }
  loginWithGoogle(loginType: string) {
    let providerId: any;
    if (loginType == "GOOGLE")
      providerId = GoogleLoginProvider.PROVIDER_ID;
    if (loginType == "FACEBOOK")
      providerId = FacebookLoginProvider.PROVIDER_ID;

    this.socialAuthService.signIn(providerId).then(response => {
      this.socialUser = response;
      console.log("socialUser :", this.socialUser);
      const paramData: any = {
        loginType: loginType,
        userName: this.socialUser.email,
        userData: {
          user_email: this.socialUser.email,
          first_name: this.socialUser.firstName,
          last_name: this.socialUser.lastName,
          is_active: 1,
          date_created: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
          profile_image: this.socialUser.photoUrl,
          user_role: "OWNER",
          login_type: "GOOGLE"
        }
      };
      console.log("socialUser Param:", paramData);
      this.loginService.socialLoginAuthentication(paramData).subscribe(
        Response => {
          const loginResponse: any = Response;
          console.log("loginResponse :", loginResponse);
          if (loginResponse.opcode === 1) {
            localStorage.setItem("token", loginResponse.data.token);
            localStorage.setItem("userData", JSON.stringify(loginResponse.data.user));
            localStorage.setItem("lang", this.loginForm.value.language);
            // this.route.navigateByUrl("admin/properties").then(r => {});
            window.location.href = "admin/dashboard";
          }
          else {
            Swal.fire("", loginResponse.message, "error");
          }
        },
        Error => {
          console.log("loginResponse Error:", Error);
          Swal.fire("", this.appConstant.API_ERROR, "error");
        }
      );
    });
  }
}
