import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../app-config/app-constants';
import { DbTables } from '../../app-config/db-tables';
import { Md5 } from 'ts-md5';
import { CommonHelper } from '../../app-config/common-helper';
import { LoginService } from '../../services/login.service';
import Swal from 'sweetalert2/dist/sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  constructor(public appConst: AppConstants,
              private tbConst: DbTables,
              public commonHelper: CommonHelper,
              private loginService: LoginService,
              public route: Router
  ) { }
  isLoading: boolean = false;
  ngOnInit(): void {
  }
  signupForm = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    user_email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    privacy: new FormControl(false, [Validators.requiredTrue]),
  });
  get signupFormControl () {
    return this.signupForm.controls;
  }
  registerUser() {
      const userData: any = this.signupForm.value;
      delete userData.privacy;
      userData.password = Md5.hashStr(userData.password);
      userData.activation_code = this.commonHelper.randomString(20);
      userData.user_role = "OWNER";
      userData.is_active = 1;
      const paramData: any = {
          dbname: this.appConst.DATABASE,
          tableName: this.tbConst.USER,
          tableData: userData
      };
      console.log("registerUser :", paramData);
      this.isLoading = true;
      this.loginService.signUpUser(paramData).subscribe(
        Response => {
            const apiResponse: any = Response;
            if (apiResponse.opcode === 1){
              const username =  userData.user_email;
              const password =  userData.password;

                this.loginService.loginAuthentication(username, password).subscribe(
                  Response => {
                    const loginResponse: any = Response;
                    console.log("loginResponse :", loginResponse);
                    if (loginResponse.opcode === 1) {
                      localStorage.setItem("token", loginResponse.data.token);
                      localStorage.setItem("userData", JSON.stringify(loginResponse.data.user));
                      localStorage.setItem("lang", "en");
                      // this.route.navigateByUrl("admin/properties").then(r => {});
                      window.location.href = "admin/dashboard";
                    }
                    else {
                      Swal.fire("", loginResponse.message, "error");
                    }
                  },
                  Error => {
                    console.log("loginResponse Error:", Error);
                    Swal.fire("", this.appConst.API_ERROR, "error");
                  }
                )
            }             
            else{
              Swal.fire("", apiResponse.message, "error");
            }
          this.isLoading = false;
          // this.route.navigateByUrl("/").then();
        },
        Error => {
            Swal.fire("", this.appConst.API_ERROR, "error");
            this.isLoading = false;
        }
      )
  }
}
