import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: [
    './start-page.component.css',
    '../../assets/assets1/css/animate.css',
    '../../assets/assets1/css/fontawesome-all.css',
    '../../assets/assets1/css/owl.carousel.css',
    '../../assets/assets1/css/style.css'
  ]
})
export class StartPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
