<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Rent' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p>{{ 'You have total' | translate}} <b>{{rentTotal}}<em class="icon ni ni-sign-inr"></em></b> {{'rent collection' | translate}}.</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle" *ngIf="localUser.user_role == 'OWNER' || localUser.user_role == 'ROOT'">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
<!--                  <li class="nk-block-tools-opt"><a href="admin/addRent/0" class="btn btn-primary"><em class="icon ni ni-plus"></em><span>{{'New Rent' | translate}}</span></a></li>-->
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-stretch">
          <div class="card-inner-group">
            <div class="card-inner position-relative">
              <div class="card-title-group">
<!--                <div class="card-tools">-->
                  <form [formGroup]="rentFilter" style="width: 100%;">
                    <div class="row gy-4">
                      <!--<div class="col-sm-2">
                        <div class="form-group">
                          <div class="form-control-wrap">
                            <ng-select (change)="fetchTenantForRent($event)" [formControl]="rentFilterControls.propertyId" name="propertyId" id="propertyId" placeholder="{{'Select Property' | translate}}">
                              <ng-option *ngFor="let property of rentPropertyList.data" [value]="property.prop_id">{{ property.prop_name}}</ng-option>
                            </ng-select>
                            <span class="invalid-select" *ngIf="rentFilterControls.propertyId.hasError('required') && (rentFilterControls.propertyId.touched || rentFilterControls.propertyId.dirty)">
                              {{'Property name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                            </span>
                          </div>
                        </div>
                      </div>-->
                      <!--<div class="col-sm-2">
                        <div class="form-group">
                          <div class="form-control-wrap">
                            <div class="form-icon form-icon-right"><em class="icon ni ni-user"></em></div>
                            <ng-select [formControl]="rentFilterControls.tenantId" name="tenantId" id="tenantId" placeholder="{{'Select Tenant' | translate}}">
                              <ng-option *ngFor="let tenant of rentTenantList.data" [value]="tenant.tenant_id">{{ tenant.tenant_name}}</ng-option>
                            </ng-select>
                            <span class="invalid-select" *ngIf="rentFilterControls.tenantId.hasError('required') && (rentFilterControls.tenantId.touched || rentFilterControls.tenantId.dirty)">
                              {{'Tenant Name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                            </span>
                          </div>
                        </div>
                      </div>-->
                      <div class="col-sm-3">
                        <div class="form-group">
                          <div class="form-control-wrap">
                            <ng-select [items]="monthList" bindValue="id" bindLabel="name" [formControl]="rentFilterControls.month" name="month" id="month" placeholder="{{'Select Month' | translate}}">
                            </ng-select>
                            <span class="invalid-select" *ngIf="rentFilterControls.month.hasError('required') && (rentFilterControls.month.touched || rentFilterControls.month.dirty)">
                              {{'Month' | translate}} {{appConst.EMPTY_ERROR | translate}}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <div class="form-control-wrap">
                            <ng-select [items]="yearList" bindValue="id" bindLabel="name" [formControl]="rentFilterControls.year" name="year" id="year" placeholder="{{'Select Year' | translate}}">
                            </ng-select>
                            <span class="invalid-select" *ngIf="rentFilterControls.year.hasError('required') && (rentFilterControls.year.touched || rentFilterControls.year.dirty)">
                              {{'Year' | translate}} {{appConst.EMPTY_ERROR | translate}}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <span class="d-md-block">
                          <button type="button" data-style="zoom-in" (click)="fetchRentCollectionData('view')" class="btn btn-primary" [ladda]="viewBtnLoader" [disabled]="!rentFilter.valid"><em class="icon ni ni-check"></em> <span>{{'Apply' | translate}}</span></button>
<!--                          <button type="button" data-style="zoom-in" (click)="fetchRentCollectionData('excel')" class="btn btn-outline-secondary mg-left-10px" [ladda]="excelBtnLoader" [disabled]="!rentFilter.valid"><em class="icon ni ni-download"></em> <span>{{'Export' | translate}}</span></button>-->
                        </span>
                      </div>
                    </div><!-- .form-inline -->
                  </form>
<!--                </div>-->
              </div><!-- .card-title-group -->
            </div><!-- .card-inner -->
            <div class="card-inner" *ngIf="rentCollectionData.numRows > 0" style="overflow-x: scroll;">
              <table class="datatable-init nk-tb-list nk-tb-ulist" id="rent_table" data-auto-responsive="ture">
                <thead>
                <tr class="nk-tb-item nk-tb-head">
                  <th class="nk-tb-col">#</th>
                  <th class="nk-tb-col">{{'Property Name' | translate}}</th>
                  <th class="nk-tb-col">{{'Tenant Name' | translate}}</th>
                  <th class="nk-tb-col">{{'Payable Rent' | translate}}</th>
                  <th class="nk-tb-col">{{'Paid Rent' | translate}}</th>
                  <th class="nk-tb-col">{{'Payment Method' | translate}}</th>
                  <th class="nk-tb-col">{{'Month' | translate}}</th>
                  <th class="nk-tb-col">{{'Year' | translate}}</th>
                  <th class="nk-tb-col">{{'Is Rent Paid' | translate}}</th>
                  <th class="nk-tb-col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let rent of rentCollectionData.data; let sno = index;" class="nk-tb-item" (dblclick)="showHideRentAmount(sno)">
                  <td class="nk-tb-col">{{sno+1}}</td>
                  <td class="nk-tb-col">{{rent.prop_name}}</td>
                  <td class="nk-tb-col">{{rent.tenant_name}}</td>
                  <td class="nk-tb-col">{{rent.rent_payable}}</td>
                  <td class="nk-tb-col" style="width: 100px;">
                    <input type="text" class="form-control" [value]="rent.total_amount" id="rent_amount_{{sno}}" style="display: none;">
                    <span id="rent_amount_txt_{{sno}}">{{rent.total_rent_paid}}</span>
                  </td>
                  <td class="nk-tb-col" style="width: 150px;">
                    <select class="form-select form-control form-control-lg" data-search="on" id="payment_method_{{sno}}" style="display: none;">
                      <option value="Cash" [selected]="rent.payment_by=='Cash'">Cash</option>
                      <option value="Cheque" [selected]="rent.payment_by=='Cheque'">Cheque</option>
                      <option value="Bank Transfer" [selected]="rent.payment_by=='Bank Transfer'">Bank Transfer</option>
                      <option value="Other" [selected]="rent.payment_by=='Other'">Other</option>
                    </select>
                    <span id="payment_method_txt_{{sno}}">{{rent.payment_by}}</span>
                  </td>
                  <td class="nk-tb-col">{{commonHelper.getSingleMonthName(rent.rent_month) | translate}}</td>
                  <td class="nk-tb-col">{{rent.rent_year}}</td>
                  <td class="nk-tb-col nk-tb-col-check">
                    <div class="custom-control custom-control-sm custom-checkbox notext">
                      <input type="checkbox" class="custom-control-input" id="is_rent_paid_{{sno}}" [checked]="rent.rent_is_paid==1" (change)="saveRentAmount(sno, rent.rent_collection_id, rent.tenant_id,rent.prop_id, rent.rent_month, rent.rent_year)">
                      <label class="custom-control-label" for="is_rent_paid_{{sno}}"></label>
                    </div>
                  </td>
                  <td class="nk-tb-col nk-tb-col-tools">
                    <ul class="nk-tb-actions gx-1">
                      <li>
                        <div class="drodown">
                          <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <ul class="link-list-opt no-bdr">
                              <li><a href="admin/viewRent/{{commonHelper.encodeURIParam(rent.rent_collection_id)}}"><em class="icon ni ni-eye"></em><span>{{'View Details' | translate}}</span></a></li>
                              <li><a href="admin/editRent/{{commonHelper.encodeURIParam(rent.rent_collection_id)}}"><em class="icon ni ni-edit-alt"></em><span>{{'Edit Details' | translate}}</span></a></li>
                              <li *ngIf="rent.is_link_sent==0 && rent.rent_is_paid==0" style="display: none;"><a (click)="openSendPaymentLinkPopup(rent.rent_collection_id, rent.rent_month, rent.rent_year)"><em class="icon ni ni-send-alt"></em><span>{{'Send Payment Link' | translate}}</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="nk-tb-col font-weight-bold">Total</td>
                  <td class="nk-tb-col font-weight-bold">{{rentCollectionData.extra}}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div><!-- .card-inner -->
          </div><!-- .card-inner-group -->
        </div><!-- .card -->
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
