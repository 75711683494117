import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../../app-config/app-constants';
import { ActivatedRoute, Router} from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonHelper } from '../../app-config/common-helper';
import { DbTables } from '../../app-config/db-tables';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Md5 } from 'ts-md5';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ResetPasswordComponent implements OnInit {

  constructor(public appConst: AppConstants,
              public route: Router,
              private loginService: LoginService,
              public commonHelper: CommonHelper,
              public dbTables: DbTables,
              private urlRoute: ActivatedRoute
  ) { }

  isLoading: boolean = false;
  keyWord: string;
  matchValue: string = "";
  newPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
  cnfPassword = new FormControl('', [Validators.required]);
  ngOnInit(): void {
    this.keyWord = this.urlRoute.snapshot.paramMap.get("key");
  }
  resetPassword(resetKeyword) {
    console.log("resetKeyword :", resetKeyword);
    if (this.newPassword.value != this.cnfPassword.value){
        Swal.fire("", "Both password not matched", "error");
        return false;
    }
    const paramData = {
      dbname: this.appConst.DATABASE,
      password: Md5.hashStr(this.newPassword.value),
      resetKeyword: resetKeyword
    }
    this.isLoading = true;
    this.loginService.resetPasswordService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1) {
          Swal.fire("", apiResponse.message, "success");
          this.route.navigateByUrl('/').then();
        }
        else {
          Swal.fire("", apiResponse.message, "error");
        }
        this.isLoading = false;
      },
      Error => {
          Swal.fire("", this.appConst.API_ERROR, "error");
          this.isLoading = false;
      }
    )
  }
}
