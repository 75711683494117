<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
<!--      <div class="components-preview wide-md mx-auto">-->
        <div class="nk-block">
          <div class="nk-block-head nk-block-head-sm">
            <div class="nk-block-between">
              <div class="nk-block-head-content">
                <h3 class="nk-block-title page-title">{{'Settings' | translate}}</h3>
                <div class="nk-block-des text-soft">
                </div>
              </div><!-- .nk-block-head-content -->
            </div><!-- .nk-block-between -->
          </div><!-- .nk-block-head -->
          <ul class="row g-gs preview-icon-svg">
            <li class="col-md-2 col-6 col-sm-4">
              <div class="preview-icon-box card card-bordered">
                <div class="preview-icon-wrap">
                  <svg viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
                    <path d="m40 8h12" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <rect fill="#fff" height="72" rx="2" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="50" x="23.5" y="3" />
                    <path d="m26.5 13h44a0 0 0 0 1 0 0v58a2 2 0 0 1 -2 2h-40a2 2 0 0 1 -2-2v-58a0 0 0 0 1 0 0z" fill="#e3e7fe" />
                    <rect fill="#fff" height="14" rx="2" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="48" x="34.5" y="65" />
                    <circle cx="23.5" cy="71" fill="#fff" r="16" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <circle cx="23.5" cy="71" fill="#e3e7fe" r="12" />
                    <path d="m18.44 70h10.12a1.45 1.45 0 0 1 1.44 1.44v5.09a1.45 1.45 0 0 1 -1.44 1.47h-10.12a1.45 1.45 0 0 1 -1.44-1.47v-5.09a1.45 1.45 0 0 1 1.44-1.44z" fill="#6576ff" />
                    <path d="m20 70v-2.53a3.5 3.5 0 0 1 7 0v2.53" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <circle cx="23.5" cy="73.23" fill="#fff" r="1.5" />
                    <path d="m22.5 74.23h2a0 0 0 0 1 0 0v1.24a.76.76 0 0 1 -.76.76h-.47a.76.76 0 0 1 -.76-.76v-1.24a0 0 0 0 1 -.01 0z" fill="#fff" />
                    <g fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                      <path d="m48.5 69.5v5" />
                      <path d="m50.5 70.79-4 2.52" />
                      <path d="m50.5 73.31-4-2.52" />
                      <path d="m57.17 69.5v5" />
                      <path d="m59.17 70.79-4 2.52" />
                      <path d="m59.17 73.31-4-2.52" />
                      <path d="m65.83 69.5v5" />
                      <path d="m67.83 70.79-4 2.52" />
                      <path d="m67.83 73.31-4-2.52" />
                      <path d="m74.5 69.5v5" />
                      <path d="m76.5 70.79-4 2.52" />
                      <path d="m76.5 73.31-4-2.52" />
                    </g>
                    <path d="m30.5 36h34v5h-34z" fill="#fff" />
                    <path d="m30.5 43.75h34v5h-34z" fill="#fff" />
                    <path d="m30.5 50.5h34v7h-34z" fill="#6576ff" />
                    <path d="m49.5 52.5-2.75 3-1.25-1.36" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="48.5" cy="23.5" fill="#c4cefe" r="7.5" />
                  </svg>
                </div>
                <a href="javascript:void(0);" (click)="openChangePasswordPopup()"><span class="preview-icon-name">{{'Change Password' | translate}}</span></a>
              </div><!-- .preview-icon-box -->
            </li>
            <li class="col-md-2 col-6 col-sm-4">
              <div class="preview-icon-box card card-bordered">
                <div class="preview-icon-wrap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <rect x="5" y="7" width="60" height="56" rx="7" ry="7" fill="#e3e7fe" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <rect x="25" y="27" width="60" height="56" rx="7" ry="7" fill="#e3e7fe" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <rect x="15" y="17" width="60" height="56" rx="7" ry="7" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line x1="15" y1="29" x2="75" y2="29" fill="none" stroke="#6576ff" stroke-miterlimit="10" stroke-width="2" />
                    <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                    <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                    <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                    <rect x="22" y="39" width="20" height="20" rx="2" ry="2" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <circle cx="32" cy="45.81" r="2" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path d="M29,54.31a3,3,0,0,1,6,0" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line x1="49" y1="40" x2="69" y2="40" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line x1="49" y1="51" x2="69" y2="51" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line x1="49" y1="57" x2="59" y2="57" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line x1="64" y1="57" x2="66" y2="57" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line x1="49" y1="46" x2="59" y2="46" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line x1="64" y1="46" x2="66" y2="46" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></svg>
                </div>
                <a href="admin/viewProfile/{{commonHelper.encodeURIParam(localUser.userid)}}"><span class="preview-icon-name">{{'Profile' | translate}}</span></a>
              </div><!-- .preview-icon-box -->
            </li>
            <!--<li class="col-md-2 col-6 col-sm-4">
              <div class="preview-icon-box card card-bordered">
                <div class="preview-icon-wrap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <path d="M40.74,5.16l38.67,9.23a6,6,0,0,1,4.43,7.22L70.08,80a6,6,0,0,1-7.17,4.46L24.23,75.22A6,6,0,0,1,19.81,68L33.56,9.62A6,6,0,0,1,40.74,5.16Z" fill="#eff1ff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path d="M50.59,6.5,11.18,11.73a6,6,0,0,0-5.13,6.73L13.85,78a6,6,0,0,0,6.69,5.16l39.4-5.23a6,6,0,0,0,5.14-6.73l-7.8-59.49A6,6,0,0,0,50.59,6.5Z" fill="#eff1ff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <rect x="15" y="15" width="54" height="70" rx="6" ry="6" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line x1="42" y1="77" x2="58" y2="77" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <circle cx="38" cy="77" r="0.5" fill="#c4cefe" stroke="#c4cefe" stroke-miterlimit="10" />
                    <line x1="42" y1="72" x2="58" y2="72" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <circle cx="38" cy="72" r="0.5" fill="#c4cefe" stroke="#c4cefe" stroke-miterlimit="10" />
                    <line x1="42" y1="66" x2="58" y2="66" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <circle cx="38" cy="66" r="0.5" fill="#c4cefe" stroke="#c4cefe" stroke-miterlimit="10" />
                    <path d="M46,40l-15-.3V40A15,15,0,1,0,46,25h-.36Z" fill="#e3e7fe" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path d="M42,22A14,14,0,0,0,28,36H42V22" fill="#e3e7fe" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <polyline points="33.47 30.07 28.87 23 23 23" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <polyline points="25 56 35 56 40.14 49" fill="none" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></svg>
                </div>
                <a href="admin/reports"><span class="preview-icon-name">{{'Reports' | translate}}</span></a>
              </div>&lt;!&ndash; .preview-icon-box &ndash;&gt;
            </li>-->
            <li class="col-md-2 col-6 col-sm-4">
              <div class="preview-icon-box card card-bordered">
                <div class="preview-icon-wrap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <rect x="9" y="21" width="55" height="39" rx="6" ry="6" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></rect>
                    <line x1="17" y1="44" x2="25" y2="44" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></line>
                    <line x1="30" y1="44" x2="38" y2="44" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></line>
                    <line x1="42" y1="44" x2="50" y2="44" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></line>
                    <line x1="17" y1="50" x2="36" y2="50" fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></line>
                    <rect x="16" y="31" width="15" height="8" rx="1" ry="1" fill="#c4cefe"></rect>
                    <path d="M76.79,72.87,32.22,86.73a6,6,0,0,1-7.47-4L17,57.71A6,6,0,0,1,21,50.2L65.52,36.34a6,6,0,0,1,7.48,4l7.73,25.06A6,6,0,0,1,76.79,72.87Z" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                    <polygon points="75.27 47.3 19.28 64.71 17.14 57.76 73.12 40.35 75.27 47.3" fill="#6576ff"></polygon>
                    <path d="M30,77.65l-1.9-6.79a1,1,0,0,1,.69-1.23l4.59-1.3a1,1,0,0,1,1.23.7l1.9,6.78A1,1,0,0,1,35.84,77l-4.59,1.3A1,1,0,0,1,30,77.65Z" fill="#c4cefe"></path>
                    <path d="M41.23,74.48l-1.9-6.78A1,1,0,0,1,40,66.47l4.58-1.3a1,1,0,0,1,1.23.69l1.9,6.78A1,1,0,0,1,47,73.88l-4.58,1.29A1,1,0,0,1,41.23,74.48Z" fill="#c4cefe"></path>
                    <path d="M52.43,71.32l-1.9-6.79a1,1,0,0,1,.69-1.23L55.81,62A1,1,0,0,1,57,62.7l1.9,6.78a1,1,0,0,1-.69,1.23L53.66,72A1,1,0,0,1,52.43,71.32Z" fill="#c4cefe"></path>
                    <ellipse cx="55.46" cy="19.1" rx="16.04" ry="16.1" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></ellipse>
                    <ellipse cx="55.46" cy="19.1" rx="12.11" ry="12.16" fill="#e3e7fe"></ellipse><text transform="translate(50.7 23.72) scale(0.99 1)" font-size="16.12" fill="#6576ff" font-family="Nunito-Black, Nunito Black">$</text></svg>
                </div>
                <a data-toggle="modal" data-target="#payment_link_modal" (click)="fetchPaymentData()"><span class="preview-icon-name">{{'Payment' | translate}}</span></a>
              </div><!-- .preview-icon-box -->
            </li>
          </ul>
        </div>
<!--      </div>-->
    </div>
  </div>
</div>
<!-- Modal Content Code -->
<div class="modal fade zoom" id="payment_link_modal">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <a href="#" class="close" data-dismiss="modal" aria-label="Close">
        <em class="icon ni ni-cross"></em>
      </a>
      <div class="modal-header">
        <h5 class="modal-title">{{'Add Your Payment Details' | translate}}</h5>
      </div>
      <form [formGroup]="paymentForm">
      <div class="modal-body">
        <!--<div class="form-group">
          <label class="form-label" for="payment_by">{{'Payment By' | translate}}</label>
          <div class="form-control-wrap">
            <ng-select [formControl]="getPaymentFormControls.payment_by" name="payment_by" id="payment_by" placeholder="Payment By">
              <ng-option value="Razorpay">Razorpay</ng-option>
              &lt;!&ndash;<ng-option value="Paytm">Paytm</ng-option>
              <ng-option value="GPay">GPay</ng-option>&ndash;&gt;
            </ng-select>
            <span class="invalid-select" *ngIf="getPaymentFormControls.payment_by.hasError('required') && (getPaymentFormControls.payment_by.touched || getPaymentFormControls.payment_by.dirty)">
            {{'Payment By' | translate}} {{appConstants.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="merchant_id">{{'Merchant Id' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getPaymentFormControls.merchant_id" name="merchant_id" id="merchant_id" class="form-control" placeholder="Enter here">
            <span class="invalid-select" *ngIf="getPaymentFormControls.merchant_id.hasError('required') && (getPaymentFormControls.merchant_id.touched || getPaymentFormControls.merchant_id.dirty)">
              {{'Merchant Id' | translate}} {{appConstants.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="merchant_key">{{'Merchant Key' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getPaymentFormControls.merchant_key" name="merchant_key" id="merchant_key" class="form-control" placeholder="Enter here">
            <span class="invalid-select" *ngIf="getPaymentFormControls.merchant_key.hasError('required') && (getPaymentFormControls.merchant_key.touched || getPaymentFormControls.merchant_key.dirty)">
              {{'Merchant key' | translate}} {{appConstants.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>-->
        <div class="form-group">
          <label class="form-label" for="bank_name">{{'Bank Name' | translate}}</label>
          <div class="form-control-wrap">
            <ng-select [formControl]="getPaymentFormControls.bank_name" name="bank_name" id="bank_name" placeholder="Bank Name" [items]="appConstants.BANK_LIST">
            </ng-select>
            <span class="invalid-select" *ngIf="getPaymentFormControls.bank_name.hasError('required') && (getPaymentFormControls.bank_name.touched || getPaymentFormControls.bank_name.dirty)">
            {{'Bank Name' | translate}} {{appConstants.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="bank_name">{{'Branch Name' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getPaymentFormControls.branch_name" name="bank_name" id="branch_name" class="form-control" placeholder="Enter here">
            <span class="invalid-select" *ngIf="getPaymentFormControls.branch_name.hasError('required') && (getPaymentFormControls.branch_name.touched || getPaymentFormControls.branch_name.dirty)">
              {{'Branch Name' | translate}} {{appConstants.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="account_name">{{'Account Name' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getPaymentFormControls.account_name" name="account_name" id="account_name" class="form-control" placeholder="Enter here">
            <span class="invalid-select" *ngIf="getPaymentFormControls.account_name.hasError('required') && (getPaymentFormControls.account_name.touched || getPaymentFormControls.account_name.dirty)">
              {{'Account Name' | translate}} {{appConstants.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="account_no">{{'Account No.' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getPaymentFormControls.account_no" name="account_no" id="account_no" class="form-control" placeholder="Enter here">
            <span class="invalid-select" *ngIf="getPaymentFormControls.account_no.hasError('required') && (getPaymentFormControls.account_no.touched || getPaymentFormControls.account_no.dirty)">
              {{'Account No.' | translate}} {{appConstants.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="ifsc_code">{{'IFSC Code' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="getPaymentFormControls.ifsc_code" name="ifsc_code" id="ifsc_code" class="form-control" placeholder="Enter here">
            <span class="invalid-select" *ngIf="getPaymentFormControls.ifsc_code.hasError('required') && (getPaymentFormControls.ifsc_code.touched || getPaymentFormControls.ifsc_code.dirty)">
              {{'Account No.' | translate}} {{appConstants.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-primary" (click)="savePaymentLink()" [ladda]="paymentBtnLoading" [disabled]="!paymentForm.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
      </div>
      </form>
    </div>
  </div>
</div>
