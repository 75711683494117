<div class="card-inner card-inner-lg">
  <div class="nk-block-head">
    <div class="nk-block-head-content">
      <h4 class="nk-block-title">Register</h4>
      <div class="nk-block-des">
        <p>Create New RENTALFLO Account</p>
      </div>
    </div>
  </div>
  <form [formGroup]="signupForm">
    <div class="form-group">
      <label class="form-label" for="fname">First Name<span class="text-danger">*</span></label>
      <input type="text" [formControl]="signupFormControl.first_name" class="form-control form-control-lg" id="fname" placeholder="Enter your first name">
      <span id="fv-fname-error" class="invalid" *ngIf="signupFormControl.first_name.hasError('required') && (signupFormControl.first_name.touched)">First name {{ appConst.EMPTY_ERROR }}</span>
    </div>
    <div class="form-group">
      <label class="form-label" for="lname">Last Name<span class="text-danger">*</span></label>
      <input type="text" [formControl]="signupFormControl.last_name" class="form-control form-control-lg" id="lname" placeholder="Enter your last name">
      <span id="fv-lname-error" class="invalid" *ngIf="signupFormControl.last_name.hasError('required') && (signupFormControl.last_name.touched)">Last name {{appConst.EMPTY_ERROR}}</span>
    </div>
    <div class="form-group">
      <label class="form-label" for="email">Email<span class="text-danger">*</span></label>
      <input type="text" [formControl]="signupFormControl.user_email" class="form-control form-control-lg" id="email" placeholder="Enter your email address or username">
      <span id="fv-email-error" class="invalid" *ngIf="signupFormControl.user_email.touched">
        <span *ngIf="signupFormControl.user_email.hasError('required')">Email {{appConst.EMPTY_ERROR}}</span>
        <span *ngIf="signupFormControl.user_email.hasError('email')">{{appConst.EMAIL_ERROR}}</span>
      </span>
    </div>
    <div class="form-group">
      <label class="form-label" for="password">Password<span class="text-danger">*</span></label>
      <div class="form-control-wrap">
        <a class="form-icon form-icon-right passcode-switch" data-target="password">
          <em class="passcode-icon icon-show icon ni ni-eye"></em>
          <em class="passcode-icon icon-hide icon ni ni-eye-off"></em>
        </a>
        <input type="password" [formControl]="signupFormControl.password" class="form-control form-control-lg" id="password" placeholder="Enter your passcode">
        <span id="fv-password-error" class="invalid" *ngIf="signupFormControl.password.touched ||
        signupFormControl.password.dirty">
          <span *ngIf="signupFormControl.password.hasError('required')">Password {{appConst.EMPTY_ERROR}}</span>
          <span *ngIf="signupFormControl.password.hasError('minlength')">Minimum length should be 6</span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <div class="custom-control custom-control-xs custom-checkbox">
        <input type="checkbox" [formControl]="signupFormControl.privacy" class="custom-control-input" id="checkbox">
        <label class="custom-control-label" for="checkbox">I agree to RENTALFLO <a href="#">Privacy Policy</a> &amp; <a href="#"> Terms.</a><span class="text-danger">*</span></label>
        <span id="fv-checkbox-error" class="invalid" style="display: none"></span>
      </div>
    </div>
    <div class="form-group">
      <button type="button" [ladda]="isLoading" data-style="slide-down" class="btn btn-lg btn-primary btn-block" (click)="registerUser()" [disabled]="!signupForm.valid">Register</button>
    </div>
  </form>
  <div class="form-note-s2 text-center pt-4"> Already have an account? <a (click)="route.navigateByUrl('/')"><strong>Sign in instead</strong></a>
  </div>
</div>
