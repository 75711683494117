import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppConstants} from '../../app-config/app-constants';
import {CommonService} from '../../services/common.service';
import Swal from 'sweetalert2';
import {CommonHelper} from '../../app-config/common-helper';
import {DatePipe} from '@angular/common';
declare function $(str: string): any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public matDialog: MatDialog,
    public commonHelper: CommonHelper,
    public appConstants: AppConstants,
    public commonService: CommonService,
    public datePipe: DatePipe
  ) { }
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  paymentBtnLoading: boolean = false;
  paymentForm: any = new FormGroup({
    // payment_by: new FormControl(null, [Validators.required]),
    // merchant_id: new FormControl(null, [Validators.required]),
    // merchant_key: new FormControl(null, [Validators.required]),
    bank_name: new FormControl(null, [Validators.required]),
    branch_name: new FormControl(null, [Validators.required]),
    account_name: new FormControl(null, [Validators.required]),
    account_no: new FormControl(null, [Validators.required]),
    ifsc_code: new FormControl(null, [Validators.required])
  });
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
  }
  openChangePasswordPopup() {
    this.matDialog.open(ChangePasswordPopup);
  }
  get getPaymentFormControls () {
    return this.paymentForm.controls;
  }
  savePaymentLink() {
    const formData: any = this.paymentForm.value;
    formData.added_datetime = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
    formData.owner_id = this.localUser.userid;
    const paramData: any = {
      dbname: this.appConstants.DATABASE,
      data: formData
    }
    console.log("PaymentLink: ", paramData);
    this.commonService.savePaymentLinkDetails(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("savePaymentLinkDetails :", apiResponse);
        Swal.fire("", "Payment details saved successfully.", "success").then(r=>{
            $("#payment_link_modal").modal("hide");
        });
      },
      Error => {
        console.log("savePaymentLinkDetails Error:", Error);
      }
    );
  }
  fetchPaymentData() {
    const paramData: any = {
      dbname: this.appConstants.DATABASE,
      ownerId: this.localUser.userid,
    };
    console.log("Payment paramData:", paramData);
    this.commonService.fetchPaymentLinkDetails(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("fetchPaymentLinkDetails Link:", apiResponse);
        if (apiResponse.numRows > 0) {
          const paymentData: any = apiResponse.data[0];
          this.paymentForm.controls.bank_name.setValue(paymentData.bank_name);
          this.paymentForm.controls.branch_name.setValue(paymentData.branch_name);
          this.paymentForm.controls.account_name.setValue(paymentData.account_name);
          this.paymentForm.controls.account_no.setValue(paymentData.account_no);
          this.paymentForm.controls.ifsc_code.setValue(paymentData.ifsc_code);
        }
      },
      Error => {
        console.log("fetchPaymentLinkDetails Error:", Error);
      }
    );
  }
}
@Component({
  selector: 'change-password-popup',
  templateUrl: 'change-password-popup.component.html',
})
export class ChangePasswordPopup {

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<ChangePasswordPopup>,
    public appConst:  AppConstants,
    public commonService: CommonService
  ) {}
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  passwordForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    cnfPassword: new FormControl('', [Validators.required])
  });
  saveBtnLoading: boolean = false;
  passwordMatch: boolean = false;
  oldPassError: string = "";
  onNoClick(): void {
  }
  get passwordChangeControl() {
    return this.passwordForm.controls;
  }
  checkPassword (newPassword, cnfPassword) {
    this.passwordMatch = newPassword === cnfPassword;
  }
  saveChangePassword(userId) {
    this.saveBtnLoading = true;
    const paramData = {
      dbname: this.appConst.DATABASE,
      userId: userId,
      newPassword: this.passwordForm.value.newPassword,
      oldPassword: this.passwordForm.value.oldPassword
    };
    this.commonService.saveChangePasswordService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("saveChangePassword apiResponse :", apiResponse);
        if (apiResponse.opcode === 1) {
          this.oldPassError = "";
          Swal.fire("", this.translate.instant("Password successfully changed."), "success");
          this.dialogRef.close();
        }
        else{
          this.oldPassError = apiResponse.message;
        }
        this.saveBtnLoading = false;
      },
      Error => {
        console.log("saveChangePassword Error :", Error.error);
        this.saveBtnLoading = false;
      }
    );
  }
}
