<h2 mat-dialog-title class="modal-header">{{'Expiry By Date' | translate}}</h2>
<mat-dialog-content>
  <form style="margin-bottom: 20px;">
    <div class="form-group">
      <label class="form-label" for="expiry_by">{{'Expiry By' | translate}}</label>
      <div class="form-control-wrap">
        <div class="form-icon form-icon-right"><em class="icon ni ni-lock"></em></div>
        <input type="text" [formControl]="expiryByCtrl" class="form-control" id="expiry_by" placeholder="Select Expiry By Date">
        <span class="invalid" *ngIf="(expiryByCtrl.dirty || expiryByCtrl.touched)">
            <span *ngIf="expiryByCtrl.hasError('required')">{{'Expiry By' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
        </span>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="modal-footer">
  <button class="btn btn-outline-secondary" mat-dialog-close><em class="icon ni ni-cross"></em> <span>{{'Cancel' | translate}}</span></button>
  <button class="btn btn-primary" data-style="zoom-in" (click)="sendPaymentLink()" [ladda]="saveBtnLoading" [disabled]="!expiryByCtrl.valid || !expiryByCtrl" style="margin-left: 10px;"><em class="icon ni ni-check"></em> <span>{{'Send' | translate}}</span></button>
</mat-dialog-actions>
