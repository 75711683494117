<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-content ">
        <div class="nk-block nk-block-middle wide-xs mx-auto">
          <div class="nk-block-content nk-error-ld text-center">
            <div class="card card-bordered card-preview">
              <div class="card-inner">
                <h1 class="nk-error-head">404</h1>
                <h3 class="nk-error-title">Oops! Why you’re here?</h3>
                <p class="nk-error-text">We are very sorry for inconvenience. It looks like you’re try to access a page that either has been deleted or under development.</p>
              </div>
            </div>
          </div>
        </div><!-- .nk-block -->
      </div>
    </div>
  </div>
</div>
