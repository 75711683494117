

<body class="saas-classic">
<!-- Start of Banner section
    ============================================= -->
<section id="saas_two_banner" class="saas_two_banner_section relative-position">

<div class="nk-app-root">
  <div class="nk-main ">
    <div class="nk-wrap nk-wrap-nosidebar">
      <div class="nk-content" id="maincontent">
        <div class="nk-block nk-block-middle nk-auth-body  wide-xs">
          <div class="card card-bordered" id="outercard" style="z-index: 1;">
            <div class="brand-logo pb-4 text-center" style="padding-top: 20px;">
              <a href="" class="logo-link">
                <img class="logo-light logo-img logo-img-lg" style="width: 250px;" src="assets/images/logo2x.png" srcset="assets/images/logo2x.png 2x" alt="logo">
                <img class="logo-dark logo-img logo-img-lg" style="width: 250px;" src="assets/images/logo2x.png" srcset="assets/images/logo2x.png 2x" alt="logo-dark">
              </a>
            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="s2-banner_shape1 position-absolute" data-parallax='{"y" : 100}'><img src="assets/img/saas-c/banner/b-shape4.png" alt=""></div>
  <div class="s2-banner_shape2 position-absolute"><img src="assets/img/saas-c/banner/b-shape3.png" alt=""></div>
  <div class="s2-banner_shape3 position-absolute"><img src="assets/img/saas-c/banner/b-shape2.png" alt=""></div>
</section>
<!-- End of Banner section --->
</body>
