import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { StartPageComponent } from './start-page/start-page.component';
import { LoginComponent } from './start-page/login/login.component';
import { SignUpComponent } from './start-page/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './start-page/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './start-page/forgot-password/reset-password.component';
import { VerifyUserComponent} from './start-page/sign-up/verify-user.component';
import {AddPropertyComponent, PropertiesComponent, ViewPropertyComponent} from './admin/properties/properties.component';
import {Error404Component, ErrorUnderDevelopmentComponent} from './admin/error/error.component';
import {AddTenantComponent, TenantsComponent, ViewTenantComponent} from './admin/tenants/tenants.component';
import {UserProfileComponent} from './admin/user-profile/user-profile.component';
import {AddExpenseComponent, ExpenseComponent, ViewExpenseComponent} from './admin/expense/expense.component';
import {TestAppComponent} from './admin/test-app/test-app.component';
import {AddRentComponent, RentComponent, ViewRentComponent} from './admin/rent/rent.component';
import {SettingsComponent} from './admin/settings/settings.component';
import {DashboardComponent} from './admin/dashboard/dashboard.component';
import { UtilityComponent,AddUtilityComponent } from './admin/utility/utility.component';

export const routes: Routes = [
  {
    path: '',
    component: StartPageComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      },
      {
        path: 'signup',
        component: SignUpComponent
      },
      {
        path: 'forgot',
        component: ForgotPasswordComponent
      },
      {
        path: 'resetPassword/:key',
        component: ResetPasswordComponent
      },
      {
        path: 'verify/:code',
        component: VerifyUserComponent
      }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'properties',
        component: PropertiesComponent
      },
      {
        path: 'addProperty/:id',
        component: AddPropertyComponent
      },
      {
        path: 'viewProperty/:id',
        component: ViewPropertyComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'rent/:month/:year',
        component: RentComponent
      },
      {
        path: 'addRent/:id',
        component: AddRentComponent
      },
      {
        path: 'viewRent/:id',
        component: ViewRentComponent
      },
      {
        path: 'editRent/:id',
        component: AddRentComponent
      },
      {
        path: 'expense',
        component: ExpenseComponent
      },
      {
        path: 'tenants',
        component: TenantsComponent
      },
      {
        path: 'addTenant/:id',
        component: AddTenantComponent
      },
      {
        path: 'editTenant/:id',
        component: AddTenantComponent
      },
      {
        path: 'viewTenant/:id',
        component: ViewTenantComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'viewProfile/:id',
        component: UserProfileComponent
      },
      {
        path: 'testApp',
        component: TestAppComponent
      },
      {
        path: 'addExpense/:id',
        component: AddExpenseComponent
      },
      {
        path: 'editExpense/:id',
        component: AddExpenseComponent
      },
      {
        path: 'viewExpense/:id',
        component: ViewExpenseComponent
      },
      {
        path: 'reports',
        component: ErrorUnderDevelopmentComponent
      },
      {
        path: 'utility',
        component: UtilityComponent
      },
      {
        path: 'addUtility/:id',
        component: AddUtilityComponent
      }
    ]
  },
];
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
