import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../app-config/app-constants';
import { CommonService } from '../../services/common.service';
import { DbTables } from '../../app-config/db-tables';
import Swal from 'sweetalert2';
import { CommonHelper } from '../../app-config/common-helper';
import { GridLayout, Image, PlainGalleryConfig, PlainGalleryStrategy } from '@ks89/angular-modal-gallery';
import {NgxLoadingService} from 'ngx-loading';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
declare const $: any;

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {
  constructor(public translate: TranslateService,
              public route: Router,
              private commonService: CommonService,
              public appConst: AppConstants,
              public commonHelper: CommonHelper,
              public ngxSpinnerService: NgxSpinnerService,
              public matDialog: MatDialog
  ) {
      translate.setDefaultLang("en");
  }
  propertiesList: any = this.appConst.API_RESPONSE;
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  excelBtnLoading: boolean = false;
  ngOnInit(): void {
      this.ngxSpinnerService.show();
      this.translate.use(localStorage.getItem("lang"));
      this.fetchProperties('view');
      this.ngxSpinnerService.hide();
  }
  fetchProperties (type='view') {
      const paramData: any = {
        dbname: this.appConst.DATABASE,
        type: type
      }
      if (this.localUser.user_role == "OWNER")
        paramData.userId = this.localUser.userid;
      else if (this.localUser.user_role == "TENANT")
        paramData.propertyId = this.localUser.prop_id;
      else
        paramData.isDeleted = 0;
      this.excelBtnLoading = true;
      this.ngxSpinnerService.show();
      this.commonService.fetchPropertyService(paramData).subscribe(
          Response => {
              const apiResponse: any = Response;
              console.log("fetchProperties :", apiResponse);
              if (type == "excel") {
                if (apiResponse.opcode === 1)
                  window.open(this.appConst.API_END + "common/download?file_path=" + apiResponse.data.filePath);
                else
                  Swal.fire("", this.translate.instant("No properties found in the system."),"warning");
              }
              else
                this.propertiesList = apiResponse;
              this.ngxSpinnerService.hide();
          },
          Error => {
              console.log("fetchProperties Error:", Error);
              this.ngxSpinnerService.hide();
          }
      );
  }
  deleteProperty (propertyId) {
    this.commonService.deletePropertyService({dbname: this.appConst.DATABASE, propertyId: propertyId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        if (apiResponse.opcode === 1) {
            Swal.fire("", this.translate.instant("Property successfully deleted"), "success").then();
            this.fetchProperties('view');
        }
        else {
            Swal.fire("", this.translate.instant("Connection error"), "error").then();
        }
      },
      Error => {
        console.log("fetchProperties Error:", Error);
      }
    );
  }
  changePropertyStatus (status, propertyId) {
    const paramData = {
      dbname: this.appConst.DATABASE,
      status: status,
      propertyId: propertyId
    };
    let statusTxt = "Deactivated";
    if (status == 1)
      statusTxt = "Activated";
    this.commonService.changePropertyStatusService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant("Property successfully "+statusTxt), "success").then();
          this.fetchProperties('view');
        }
        else {
          Swal.fire("", this.translate.instant("Connection error"), "error").then();
        }
      },
      Error => {
        console.log("fetchProperties Error:", Error);
      }
    );
  }
  openAddCareTakerPopup(propertyId) {
    this.matDialog.open(AddCareTakerComponent, {
      data: {
        propertyId: propertyId
      },
      width: '600px'
    });
  }
  openAssignTenantPopup(propertyId, actionType, propertyData) {
    this.matDialog.open(AssignTenantComponent, {
      data: {
        propertyId: propertyId,
        actionType: actionType,
        propertyData: propertyData
      },
      width: '600px'
    });
  }
}
@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./properties.component.css']
})
export class AddPropertyComponent implements OnInit {

  constructor(public translate: TranslateService,
              public route: Router,
              public appConst: AppConstants,
              private commonService: CommonService,
              private dbTables: DbTables,
              public commonHelper: CommonHelper,
              private urlRoute: ActivatedRoute
  ) {
    translate.setDefaultLang("en");
  }
  propAddForm = new FormGroup({
    // prop_user_id: new FormControl(null, [Validators.required]),
    prop_name: new FormControl('', [Validators.required]),
    prop_address_1: new FormControl('', [Validators.required]),
    prop_address_2: new FormControl(''),
    prop_country: new FormControl(null, [Validators.required]),
    prop_state: new FormControl(null, [Validators.required]),
    prop_city: new FormControl(null, [Validators.required]),
    prop_pincode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    // prop_contact_no: new FormControl('', [Validators.required, Validators.minLength(10)]),
    // prop_contact_email: new FormControl('', [Validators.required, Validators.email]),
    prop_rent: new FormControl('', [Validators.pattern("^[0-9]*$")]),
    prop_description: new FormControl('', [Validators.required])
  });
  countryList: any;
  stateList: any;
  cityList: any;
  userList: any;
  isBtnLoading: boolean = false;
  propertyFilesList: any = [];
  propFileLoader: boolean = false;
  manageFlag: string = "add";
  propXcoor: number = 0;
  propYcoor: number = 0;
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  addressCountry: string = '';
  addressState: string = '';
  addressCity: string = '';
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    const propId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    if (propId != '0') {
        this.editViewProperty(propId);
        this.manageFlag = "edit";
    }
    this.fetchCountry()
    // this.fetchUsers();
  }
  addPropertyDetails(flag: string) {
      const fileId = this.commonHelper.convertToStringByKey("id", this.propertyFilesList);
      const propFormData: any = this.propAddForm.value;
      propFormData.prop_user_id = this.localUser.userid;
      propFormData.prop_xcoor = this.propXcoor;
      propFormData.prop_ycoor = this.propYcoor;
      const data = {
          dbname: this.appConst.DATABASE,
          data: propFormData,
          fileId: fileId,
          propertyId: this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"))
      };
      delete data.data.prop_file;
      this.isBtnLoading = true;
      console.log("propAddForm data:", data);
      this.commonService.addProperty(data, flag).subscribe(
        Response => {
              const apiResponse: any = Response;
          if (apiResponse.opcode === 1997) {
            Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
              localStorage.clear();
              window.location.href = "/";
            });
          }
              if (apiResponse.opcode === 1) {
                  Swal.fire("", this.translate.instant("Property") + " " + this.translate.instant("data successfully saved"), "success").then();
                  this.route.navigateByUrl("admin/properties").then();
              }
              else {
                  Swal.fire("", this.translate.instant("Property") + " " + this.translate.instant("data not saved") + this.translate.instant(".") + this.translate.instant("Please try again"), "error").then();
              }
              this.isBtnLoading = false;
        },
        Error => {
              console.log("addPropertyDetails ", Error);
              Swal.fire("", this.translate.instant("Connection error") + this.translate.instant(".") + this.translate.instant("Please try again"), "error").then();
              this.isBtnLoading = false;
        }
      );
  }
  get propFormControls() {
      return this.propAddForm.controls;
  }
  fetchCountry() {
      this.commonService.fetchCountryData({dbname: this.appConst.DATABASE}).subscribe(
          Response => {
              const apiResponse: any = Response;
            if (apiResponse.opcode === 1997) {
              Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
                localStorage.clear();
                window.location.href = "/";
              });
            }
              this.countryList = apiResponse.data;
          },
          Error => {
              console.log("fetchCountry :", Error);
          }
      );
  }
  fetchState(event) {
      console.log("event :", event);
      this.commonService.fetchStateData({dbname: this.appConst.DATABASE, countryId: event}).subscribe(
          Response => {
              const apiResponse: any = Response;
            if (apiResponse.opcode === 1997) {
              Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
                localStorage.clear();
                window.location.href = "/";
              });
            }
              this.stateList = apiResponse.data;
              console.log("stateList :", this.stateList);
          },
          Error => {
              console.log("fetchState :", Error);
          }
      );
  }
  fetchCity(event) {
      this.commonService.fetchCityData({dbname: this.appConst.DATABASE, stateId: event}).subscribe(
          Response => {
              const apiResponse: any = Response;
            if (apiResponse.opcode === 1997) {
              Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
                localStorage.clear();
                window.location.href = "/";
              });
            }
              this.cityList = apiResponse.data;
              console.log("cityList :", this.cityList);
          },
          Error => {
              console.log("fetchCity :", Error);
          }
      );
  }
  fetchUsers() {
      const paramData = {dbname: this.appConst.DATABASE, userId: this.localUser.userid, userRole: "OWNER"};
      if (this.localUser.user_role == "ROOT")
        delete paramData.userId;

      this.commonService.fetchUsers(paramData).subscribe(
          Response => {
              const apiResponse: any = Response;
            if (apiResponse.opcode === 1997) {
              Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
                localStorage.clear();
                window.location.href = "/";
              });
            }
              this.userList = apiResponse.data;
              console.log("usersList :", this.userList);
          },
          Error => {
              console.log("fetchUsers :", Error);
          }
      );
  }
  propertyFilesUpload (event) {
      const formData = new FormData();
      // @ts-ignore
      if (event.target.files.length > 0) {
          // @ts-ignore
          formData.append("prop_file", event.target.files[0]);
          formData.append("dbname", this.appConst.DATABASE);
          formData.append("sessionUserId", this.localUser.userid);
          this.propFileLoader = true;
          this.commonService.uploadPropertyFiles(formData).subscribe(
              Response => {
                  console.log("Response :", Response);
                  const apiResponse: any = Response;
                if (apiResponse.opcode === 1997) {
                  Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
                    localStorage.clear();
                    window.location.href = "/";
                  });
                }
                  if (apiResponse.opcode === 1) {
                      const fileType = apiResponse.data.file_type.split("/");
                      const fileExt = this.commonHelper.getFileExtentionByType(apiResponse.data.file_type);
                      const fileData = {
                          id: apiResponse.data.prop_file_id,
                          type: fileType[0],
                          path: this.appConst.API_END + this.appConst.PROPERTY_FILE + apiResponse.data.file_name,
                          ext: fileExt,
                          original_name: apiResponse.data.original_name
                      }
                      this.propertyFilesList.push(fileData);
                  }
                  else {
                      Swal.fire("",this.translate.instant("file data not saved") + this.translate.instant(".") + this.translate.instant("Please try again"), "error").then();
                  }
                  this.propFileLoader = false;
              },
              Error => {
                  console.log("Error :", Error);
                  this.propFileLoader = false;
              }
          );
      }
  }
  deletePropertyFile (id) {
      console.log("File Id :", id);
      this.propFileLoader = true;
      this.commonService.deletePropertyFiles({dbname: this.appConst.DATABASE, id: id}).subscribe(
          Response => {
              const apiResponse: any = Response;
            if (apiResponse.opcode === 1997) {
              Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
                localStorage.clear();
                window.location.href = "/";
              });
            }
              if (apiResponse.opcode === 1) {
                  const removeItem = this.propertyFilesList.filter((item) => item.id !== id);
                  this.propertyFilesList = removeItem;
              }
              else {
                  Swal.fire("", this.translate.instant("Connection error"), "error").then();
              }
              this.propFileLoader = false;
          },
          Error => {
              console.log("Error :", Error);
              this.propFileLoader = false;
          }
      );
  }
  editViewProperty (id) {
      this.commonService.fetchPropertyService({dbname: this.appConst.DATABASE, propertyId: id}).subscribe(
        Response => {
          const apiResponse: any = Response;
          if (apiResponse.opcode === 1997) {
            Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
              localStorage.clear();
              window.location.href = "/";
            });
          }
          console.log("editViewProperty apiResponse :", apiResponse);
          if (apiResponse.opcode === 1) {
              const data = apiResponse.data[0];
              // this.propAddForm.controls.prop_user_id.setValue(data.prop_user_id);
              this.propAddForm.controls.prop_name.setValue(data.prop_name);
              this.propAddForm.controls.prop_address_1.setValue(data.prop_address_1);
              this.propAddForm.controls.prop_address_2.setValue(data.prop_address_2);
              this.propAddForm.controls.prop_country.setValue(parseInt(data.prop_country));
              this.fetchState(parseInt(data.prop_country));
              this.propAddForm.controls.prop_state.setValue(parseInt(data.prop_state));
              this.fetchCity(parseInt(data.prop_state));
              this.propAddForm.controls.prop_city.setValue(parseInt(data.prop_city));
              this.propAddForm.controls.prop_pincode.setValue(data.prop_pincode);
              // this.propAddForm.controls.prop_contact_no.setValue(data.prop_contact_no);
              // this.propAddForm.controls.prop_contact_email.setValue(data.prop_contact_email);
              this.propAddForm.controls.prop_rent.setValue(data.prop_rent);
              this.propAddForm.controls.prop_description.setValue(data.prop_description);
              this.propXcoor = data.prop_xcoor;
              this.propYcoor = data.prop_ycoor;
              this.addressCountry = data.country_name;
              this.addressState = data.state_name;
              this.addressCity = data.city_name;
              this.commonService.fetchPropertyFilesService({dbname: this.appConst.DATABASE, propertyId: id}).subscribe(
                Response => {
                    const apiFilesResponse: any = Response;
                    if (apiFilesResponse.numRows > 0) {
                        apiFilesResponse.data.forEach(fileValue => {
                          const fileType = fileValue.file_type.split("/");
                          const fileExt = this.commonHelper.getFileExtentionByType(fileValue.file_type);
                          const fileData = {
                            id: fileValue.prop_file_id,
                            type: fileType[0],
                            path: this.appConst.API_END + this.appConst.PROPERTY_FILE + fileValue.file_name,
                            ext: fileExt,
                            original_name: fileValue.original_name
                          }
                          this.propertyFilesList.push(fileData);
                        });
                    }
                    console.log("propertyFilesList :", this.propertyFilesList);
                }
              );
          }
        },
        Error => {
          console.log("Error :", Error);
        }
      );
  }
  setPropertyLocation() {
    console.log("propAddForm Location", this.propAddForm.controls);
    if (this.propAddForm.value.prop_pincode != "" && this.propAddForm.value.prop_city != null && this.propAddForm.value.prop_state != null && this.propAddForm.value.prop_country != "") {
      const address = this.propAddForm.value.prop_address_1 +','+this.propAddForm.value.prop_address_2 +','+this.propAddForm.value.addressCity +','+ this.addressState +','+ this.addressCountry +','+ this.propAddForm.value.prop_pincode;
      console.log("address Location", address);
      this.commonService.getLatLngByAddressService({address: address}).subscribe(
        Response => {
          const googleResponse: any = Response;
          console.log("Google Response:", googleResponse);
          this.propXcoor = googleResponse.lat;
          this.propYcoor = googleResponse.lng;
        },
        Error => {
          console.log("Google Response Error:", Error);
        }
      );
    }
  }

  getLatlngFromMap(event: google.maps.MouseEvent) {
    console.log("Marker event :", event.latLng);
    this.propXcoor = event.latLng.lat();
    this.propYcoor = event.latLng.lng();
  }

  setAddressCity(event: Event) {
    console.log("City :", this.propAddForm.controls.prop_city);
  }
}

@Component({
  selector: 'app-view-property',
  templateUrl: './view-property.component.html',
  styleUrls: ['./properties.component.css']
})

export class ViewPropertyComponent implements OnInit {
  constructor(public translate: TranslateService,
              public route: Router,
              public appConst: AppConstants,
              private commonService: CommonService,
              public commonHelper: CommonHelper,
              private urlRoute: ActivatedRoute
  ) {
    translate.setDefaultLang("en");
  }
  propertyDetails: any;
  plainGalleryGrid: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.GRID,
    layout: new GridLayout({ width: '100px', height: '100px' }, { length: 3, wrap: true }),
  };
  propertyFiles: Image[] = [];
  otherFiles: any = [];
  propXcoor: number = 0;
  propYcoor: number = 0;
  mapHeight = window.innerHeight-400;
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    const propId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    this.viewPropertyDetails(propId);
    this.fetchPropertyFiles(propId);
  }
  viewPropertyDetails(propertyId) {
    this.commonService.viewPropertyService({dbname: this.appConst.DATABASE, propertyId: propertyId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("viewPropertyDetails apiResponse :", apiResponse);
        this.propertyDetails = apiResponse.data[0];
        this.propXcoor = apiResponse.data[0].prop_xcoor;
        this.propYcoor = apiResponse.data[0].prop_ycoor;
      },
      Error => {
        console.log("Error :", Error);
      }
    );
  }
  fetchPropertyFiles (propertyId) {
    this.commonService.fetchPropertyFilesService({dbname: this.appConst.DATABASE, propertyId: propertyId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        if (apiResponse.numRows > 0) {
            let fileCount: number = 0;
            apiResponse.data.forEach(fileValue => {
              const fileType = fileValue.file_type.split("/");
              const fileExt = this.commonHelper.getFileExtentionByType(fileValue.file_type);
              if (fileType[0].includes('image')) {
                this.propertyFiles.push(new Image(fileCount, {
                  img: this.appConst.API_END + this.appConst.PROPERTY_FILE + fileValue.file_name,
                  description: 'Property File'
                }));
                fileCount++;
              }
              else {
                this.otherFiles.push(
                  {
                    id: fileValue.prop_file_id,
                    type: fileType[0],
                    path: this.appConst.API_END + this.appConst.PROPERTY_FILE + fileValue.file_name,
                    ext: fileExt,
                    original_name: fileValue.original_name
                  }
                );
              }
            });
            console.log("propertyFiles :", this.propertyFiles);
        }
      }
    );
  }
}

import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-add-caretaker',
  templateUrl: './add-caretaker.component.html',
  styleUrls: ['./properties.component.css']
})
export class AddCareTakerComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<AddCareTakerComponent>,
    public appConst:  AppConstants,
    public commonService: CommonService,
    public datePipe: DatePipe,
    public commonHelper: CommonHelper,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {translate.setDefaultLang("en");}
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    console.log("Dialog Data:", this.data);
    this.fetchCareTaker();
    this.fetchCareTakerForProperty(this.data.propertyId);
  }
  propertyCareTaker: any = this.appConst.API_RESPONSE;
  saveBtnLoading: boolean = false;
  showCareTakerList: boolean = true;
  careTakerList: any = this.appConst.API_RESPONSE;
  assignCareTaker = new FormControl('1',[Validators.required]);
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  careTakerForm = new FormGroup({
    care_taker_first_name: new FormControl('', [Validators.required]),
    care_taker_last_name: new FormControl(),
    care_taker_email: new FormControl('', [Validators.required, Validators.email]),
    care_taker_phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
  });
  get getCareTakerFormControls() {
    return this.careTakerForm.controls;
  }
  saveCareTakerDetails(propertyId) {
    this.saveBtnLoading = true;
    console.log("careTakerForm :", this.careTakerForm.value);
    const data: any = this.careTakerForm.value;
    data.care_taker_added_datetime = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    data.owner_user_id = this.localUser.userid;
    data.property_id = propertyId;
    this.commonService.saveCareTakerService({dbname: this.appConst.DATABASE, data: data}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("saveCareTakerDetails :", apiResponse);
        if (apiResponse.opcode === 1) {
          Swal.fire("", apiResponse.message, "success").then((r)=>{
            this.dialogRef.close();
          });
        }
        else {
          Swal.fire("", apiResponse.message, "error");
        }
        this.saveBtnLoading = false;
      },
      Error => {
        console.log("saveCareTakerDetails Error:", Error.error);
        this.saveBtnLoading = false;
      }
    );
  }
  fetchCareTaker() {
    this.commonService.fetchCareTakerService({dbname: this.appConst.DATABASE, ownerUserId: this.localUser.userid}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("fetchCareTaker :", apiResponse);
        if (apiResponse.numRows > 0) {
          this.careTakerList = apiResponse;
        }
        else {
          this.showCareTakerList = false;
        }
      },
      Error => {
        console.log("fetchCareTaker Error:", Error.error);
      }
    );
  }
  assignCareTakerToProperty(propertyId: any) {
    this.saveBtnLoading = true;
    console.log("assignCareTaker :", this.assignCareTaker.value);
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      careTakerId: this.assignCareTaker.value,
      propertyId: propertyId
    };
    this.commonService.assignCareTakerToPropertyService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("assignCareTakerToProperty :", apiResponse);
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant(apiResponse.message), "success").then((r)=>{this.dialogRef.close();});
        }
        else {
          Swal.fire("", this.translate.instant(apiResponse.message), "error");
        }
        this.saveBtnLoading = false;
      },
      Error => {
        console.log("assignCareTakerToProperty Error:", Error.error);
        this.saveBtnLoading = false;
      }
    );
  }
  fetchCareTakerForProperty(propertyId) {
    this.commonService.fetchCareTakerService({dbname: this.appConst.DATABASE, ownerUserId: localStorage.userid, propertyId: propertyId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("fetchCareTakerForProperty :", apiResponse);
        if (apiResponse.numRows > 0) {
          this.propertyCareTaker = apiResponse;
          this.assignCareTaker.setValue(apiResponse.data[0].care_taker_id);
        }
      },
      Error => {
        console.log("fetchCareTakerForProperty Error:", Error.error);
      }
    );
  }
}
@Component({
  selector: 'app-assign-tenant',
  templateUrl: './assign-tenant.component.html',
  styleUrls: ['./properties.component.css']
})
export class AssignTenantComponent implements OnInit, AfterViewInit {
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<AddCareTakerComponent>,
    public appConst:  AppConstants,
    public commonService: CommonService,
    public datePipe: DatePipe,
    public commonHelper: CommonHelper,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {translate.setDefaultLang("en");}

  ngAfterViewInit(): void {
    this.loadJqueryCode();
  }
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  tenantsList: any = this.appConst.API_RESPONSE;
  saveBtnLoading: boolean = false;
  assignTenantForm = new FormGroup({
    tenant_id: new FormControl(null, [Validators.required]),
    rent_payable: new FormControl('', [Validators.required]),
    start_date: new FormControl(''),
    end_date: new FormControl('')
  });
  unAssignCheckBox = new FormControl(1);
  unAssignEndDate = new FormControl();
  showUnAssignBtn: boolean = false;
  oldPropertyTenantEndDate: any = "";
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    this.assignTenantForm.controls.rent_payable.setValue(this.data.propertyData.prop_rent);
    console.log("propertyData :", this.data.propertyData.prop_rent);
    this.fetchTenantWithProperty(this.data.propertyId);
    if (this.data.actionType == 'assign')
      this.fetchOldPropertyTenant(this.data.propertyId);
  }
  get assignTenantFormControls() {
    return this.assignTenantForm.controls;
  }
  loadJqueryCode() {
    $(".date-picker").datepicker({
      autoclose: true
    });
    console.log("Jquery Loaded....");
  }
  fetchTenantWithProperty (propertyId) {
    const paramData: any = {
      dbname: this.appConst.DATABASE
    }
    if (this.localUser.user_role == "OWNER")
      paramData.ownerId = this.localUser.userid;
    else if (this.localUser.user_role == "TENANT")
      paramData.tenantId = this.localUser.tenant_id;

    if (this.data.actionType === 'unassign') {
      paramData.propertyId = ""+propertyId;
    }

    this.commonService.fetchTenantWithPropertyService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("fetchTenants unassign:", apiResponse);
        if (apiResponse.opcode === 1) {
          this.unAssignEndDate.setValue(apiResponse.data[0].end_date);
          this.tenantsList = apiResponse;
          // this.loadJqueryCode();
        }
        // this.loadJqueryCode();
      },
      Error => {
        console.log("fetchTenants Error:", Error);
      }
    );
  }
  assignTenantToProperty(propertyId) {
    const data: any = this.assignTenantForm.value;
    const startDate = $("#start_date").val();
    const endDate = $("#end_date").val();
    if (startDate.trim() == "") {
      Swal.fire("", this.translate.instant("Please select start date"),"warning");
      return false;
    }
    data.start_date = startDate;
    if (endDate != null && endDate != "")
      data.end_date = endDate;
    data.property_id = propertyId;
    data.assigned_by = this.localUser.userid;
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      data: data,
      propertyId: propertyId,
      dateTime: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
    };
    console.log("tenant numRow :", paramData);
    this.saveBtnLoading = true;
    this.commonService.assignTenantToPropertyService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("assignTenantToProperty :", apiResponse);
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant(apiResponse.message), "success").then((r)=>{
            window.location.href = "admin/properties";
            this.dialogRef.close();
          });
        }
        else {
          Swal.fire("", this.translate.instant(apiResponse.message), "error");
        }
        this.saveBtnLoading = false;
      },
      Error => {
        console.log("assignTenantToProperty Error:", Error.error);
        this.saveBtnLoading = false;
      }
    );
  }
  unAssignTenantFromProperty(propertyId, tenantId) {
    let assignTenant = this.unAssignCheckBox.value;
    if (assignTenant === false || assignTenant === 0) {
      assignTenant = 0;
    }
    const endDate = $("#ua_end_date").val();
    if (endDate == "" || endDate == null || endDate == undefined) {
      Swal.fire("", this.translate.instant("Please select End Date"), "warning");
      this.unAssignCheckBox.setValue(1);
      return false;
    }
    console.log("unAssignEndDate :", endDate);
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      assignTenant: assignTenant,
      propertyId: propertyId,
      tenantId: tenantId,
      endDate: endDate
    };
    console.log("unAssignCheckBox :", paramData);
    this.saveBtnLoading = true;
    Swal.fire({
      title: this.translate.instant('Do you want to un-assign the tenant?'),
      showDenyButton: true,
      confirmButtonText: this.translate.instant(`Yes`),
      denyButtonText: this.translate.instant(`No`),
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonService.unAssignTenantFromPropertyService(paramData).subscribe(
          Response => {
            const apiResponse: any = Response;
            console.log("unAssignTenantFromProperty :", apiResponse);
            if (apiResponse.opcode === 1) {
              Swal.fire("", this.translate.instant(apiResponse.message), "success").then((r)=>{
                window.location.href = "admin/properties";
                this.dialogRef.close();
              });
            }
            else {
              Swal.fire("", this.translate.instant(apiResponse.message), "error");
            }
            this.saveBtnLoading = false;
          },
          Error => {
            console.log("unAssignTenantFromProperty Error:", Error.error);
            this.saveBtnLoading = false;
          }
        );
      } else if (result.isDenied) {
        Swal.fire('Cancelled', '', 'info');
        this.unAssignCheckBox.setValue(1);
      }
    })
  }
  fetchOldPropertyTenant(propertyId) {
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      propertyId: ""+propertyId
    }
    if (this.localUser.user_role == "OWNER")
      paramData.ownerId = this.localUser.userid;
    else if (this.localUser.user_role == "TENANT")
      paramData.tenantId = this.localUser.tenant_id;

    this.commonService.fetchOldPropertyTenant(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1) {
          this.assignTenantForm.controls.start_date.setValue(apiResponse.data[0].end_date);
          this.assignTenantForm.controls.start_date.disable();
        }
        console.log("oldPropertyTenant :", apiResponse);
      },
      Error => {
        console.log("fetchTenants Error:", Error);
      }
    );
  }
}
