<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Tenants' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p *ngIf="tenantsList">{{ 'You have total' | translate}} {{tenantsList.numRows}} {{'have tenants' | translate}}.</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle" *ngIf="localUser.user_role == 'OWNER' || localUser.user_role == 'ROOT'">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="javascript:void(0)" (click)="fetchTenants('excel')" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-download"></em><span>{{'Export' | translate}}</span></a></li>
                  <li class="nk-block-tools-opt"><a href="admin/addTenant/{{commonHelper.encodeURIParam(0)}}" class="btn btn-primary"><em class="icon ni ni-plus"></em><span>{{'New' | translate}} {{'Tenant' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
          <div class="card-inner">
            <table class="nk-tb-list nk-tb-ulist">
              <thead>
              <tr class="nk-tb-item nk-tb-head">
                <th class="nk-tb-col"><span class="sub-text">{{'Name' | translate}}</span></th>
                <th class="nk-tb-col tb-col-md"><span class="sub-text">{{'Property' | translate}}</span></th>
                <th class="nk-tb-col tb-col-md"><span class="sub-text">{{'Contact No' | translate}}</span></th>
                <th class="nk-tb-col tb-col-lg"><span class="sub-text">{{'Payable Rent' | translate}} (<em class="icon ni ni-sign-inr"></em>)</span></th>
                <th class="nk-tb-col tb-col-md"><span class="sub-text">{{'Status' | translate}}</span></th>
                <th class="nk-tb-col nk-tb-col-tools text-right">
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="nk-tb-item" *ngFor="let tenant of tenantsList.data">
                <td class="nk-tb-col">
                  <div class="user-card">
                    <div *ngIf="tenant.tenant_profile == null else tenantProfileElse;" class="user-avatar {{commonHelper.getThumbnailBG(tenant.tenant_name)}} d-none d-sm-flex">
                      <span>{{commonHelper.getNameThumbnail(tenant.tenant_name)}}</span>
                    </div>
                    <ng-template #tenantProfileElse>
                      <div class="user-avatar d-none d-sm-flex">
                        <img src="{{appConst.API_END+tenant.tenant_profile}}" alt="" width="100%" height="100%">
                      </div>
                    </ng-template>
                    <div class="user-info">
                      <span class="tb-lead">{{tenant.tenant_name}}
                        <span class="dot dot-success d-md-none ml-1"  *ngIf="tenant.tenant_is_active == 1"></span>
                        <span class="dot dot-danger d-md-none ml-1"  *ngIf="tenant.tenant_is_active == 0"></span>
                      </span>
                      <span>{{tenant.tenant_email}}</span>
                    </div>
                  </div>
                </td>
                <td class="nk-tb-col tb-col-mb">
                  <span>{{tenant.prop_name}}</span>
                </td>
                <td class="nk-tb-col tb-col-mb">
                  <span>{{tenant.tenant_phone}}</span>
                </td>
                <td class="nk-tb-col tb-col-md">
                  <span>{{tenant.tenant_rent}}</span>
                </td>
                <td class="nk-tb-col tb-col-md">
                  <span class="tb-status text-success" *ngIf="tenant.tenant_is_active == 1">Active</span>
                  <span class="tb-status text-danger" *ngIf="tenant.tenant_is_active == 0">Inactive</span>
                </td>
                <td class="nk-tb-col nk-tb-col-tools">
                  <ul class="nk-tb-actions gx-1">
                    <div class="drodown">
                      <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <ul class="link-list-opt no-bdr">
                          <li><a href="admin/viewTenant/{{commonHelper.encodeURIParam(tenant.tenant_id)}}"><em class="icon ni ni-eye"></em><span>{{'View Details' | translate}}</span></a></li>
                          <li *ngIf="localUser.user_role == 'OWNER' || localUser.user_role == 'ROOT'"><a href="admin/addTenant/{{commonHelper.encodeURIParam(tenant.tenant_id)}}"><em class="icon ni ni-edit"></em><span>{{'Edit Details' | translate}}</span></a></li>
                          <li class="divider" *ngIf="localUser.user_role == 'OWNER' || localUser.user_role == 'ROOT'"></li>
<!--                          <li *ngIf="localUser.user_role == 'OWNER' || localUser.user_role == 'ROOT'"><a href="javascript:void(0)" (click)="deleteTenants(tenant.tenant_id, tenant.tenant_user_id)"><em class="icon ni ni-delete"></em><span>{{'Delete' | translate}}</span></a></li>-->
                          <li *ngIf="localUser.user_role == 'OWNER' || localUser.user_role == 'ROOT'"><a href="javascript:void(0)" *ngIf="tenant.tenant_is_active == 1" (click)="changeTenantsStatus(0, tenant.tenant_id, tenant.tenant_user_id, tenant.tenant_email)"><em class="icon ni ni-property-remove"></em><span>{{'Deactivate' | translate}}</span></a></li>
                          <li *ngIf="localUser.user_role == 'OWNER' || localUser.user_role == 'ROOT'"><a href="javascript:void(0)" *ngIf="tenant.tenant_is_active == 0" (click)="changeTenantsStatus(1, tenant.tenant_id, tenant.tenant_user_id, tenant.tenant_email)"><em class="icon ni ni-property-add"></em><span>{{'Activate' | translate}}</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                </td>
              </tr><!-- .nk-tb-item  -->
              </tbody>
            </table>
          </div><!-- .card-inner -->
        </div><!-- .card -->
      </div><!-- .nk-block -->
    </div>
  </div>
</div>


