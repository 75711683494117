<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Expense' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p>{{'Fill new expense details' | translate}}</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="admin/expense" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-arrow-left"></em><span>{{'Back' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
          <div class="card-inner">
            <div class="preview-block">
              <span class="preview-title-lg overline-title"></span>
              <form [formGroup]="expenseAddForm" (submit)="addExpenseData(manageFlag)">
                <div class="row gy-4">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="exp_prop_id">{{'Property' | translate}}<span class="text-danger">*</span></label>
                      <div class="form-control-wrap">
                        <ng-select [formControl]="expenseAddFormControls.exp_prop_id" (change)="fetchTenant($event)" name="exp_prop_id" id="exp_prop_id" placeholder="Select Property">
                          <ng-option *ngFor="let property of propertyList.data" [value]="property.prop_id">{{ property.prop_name}}</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="expenseAddFormControls.exp_prop_id.hasError('required') && (expenseAddFormControls.exp_prop_id.touched || expenseAddFormControls.exp_prop_id.dirty)">
                        {{'Property name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--<div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="exp_tenant_id">{{'Tenant' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-user"></em></div>
                        <ng-select [formControl]="expenseAddFormControls.exp_tenant_id" name="exp_tenant_id" id="exp_tenant_id" placeholder="Select Tenant">
                          <ng-option *ngFor="let tenant of tenantList.data" [value]="tenant.tenant_id">{{ tenant.tenant_name}}</ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>-->
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="exp_head">{{'Expense Type' | translate}}<span class="text-danger">*</span>
                        <button type="button" (click)="expTypeCtrl.setValue('')" class="btn btn-round btn-icon btn-xs btn-primary mg-left-10px" data-toggle="modal" data-target="#expense_type_modal"><em class="icon ni ni-plus"></em></button>
                      </label>
                      <div class="form-control-wrap">
                        <ng-select [formControl]="expenseAddFormControls.exp_head" name="exp_head" id="exp_head" placeholder="Select Expense Type">
                          <ng-option *ngFor="let expenseHead of expenseHeadList.data" [value]="expenseHead.exp_head_id">{{ expenseHead.exp_head_name}}</ng-option>
                        </ng-select>
                        <span class="invalid-select" *ngIf="expenseAddFormControls.exp_head.hasError('required') && (expenseAddFormControls.exp_head.touched || expenseAddFormControls.exp_head.dirty)">
                          {{'Expense Type' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="exp_date">{{'Date' | translate}}<span class="text-danger">*</span></label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-calendar"></em>
                        </div>
                        <input type="text" [formControl]="expenseAddFormControls.exp_date" name="exp_date" id="exp_date" class="form-control date-picker" placeholder="Date" data-date-format="yyyy-mm-dd">
                        <span class="invalid-select" *ngIf="expenseAddFormControls.exp_date.hasError('required') && (expenseAddFormControls.exp_date.touched || expenseAddFormControls.exp_date.dirty)">
                          {{'Date' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="exp_amount">{{'Amount' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-sign-inr"></em>
                        </div>
                        <input type="text" [formControl]="expenseAddFormControls.exp_amount" name="exp_amount" id="exp_amount" class="form-control" placeholder="Enter Amount">
                        <span class="invalid-select" *ngIf="expenseAddFormControls.exp_amount.hasError('required') && (expenseAddFormControls.exp_amount.touched || expenseAddFormControls.exp_amount.dirty)">
                          {{'Amount' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--<div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label">{{'Is Done On Tenant Behalf' | translate}}</label>
                      <ul class="custom-control-group g-3 align-center flex-wrap">
                      <li>
                        <div class="custom-control custom-radio">
                          <input type="radio" [formControl]="expenseAddFormControls.is_done_on_tenant_behalf" value="1" class="custom-control-input" name="is_done_on_tenant_behalf" id="is_done_on_tenant_behalf_yes">
                          <label class="custom-control-label" for="is_done_on_tenant_behalf_yes">{{'Yes' | translate}}</label>
                        </div>
                      </li>
                      <li>
                        <div class="custom-control custom-radio">
                          <input type="radio" [formControl]="expenseAddFormControls.is_done_on_tenant_behalf" value="0" class="custom-control-input" name="is_done_on_tenant_behalf" id="is_done_on_tenant_behalf_no" checked>
                          <label class="custom-control-label" for="is_done_on_tenant_behalf_no">{{'No' | translate}}</label>
                        </div>
                      </li>
                    </ul>
                    </div>
                  </div>-->
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label" for="exp_description">{{'Expense Description' | translate}}</label>
                      <div class="form-control-wrap">
                        <textarea [formControl]="expenseAddFormControls.exp_description" name="exp_description" id="exp_description" class="form-control" placeholder="Enter Expense Description" style="min-height: 70px;">
                        </textarea>
                        <span class="invalid-select" *ngIf="expenseAddFormControls.exp_description.hasError('required') && (expenseAddFormControls.exp_description.touched || expenseAddFormControls.exp_description.dirty)">
                          {{'Expense Description' | translate}} {{appConst.EMPTY_ERROR | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label">{{'File Upload' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="custom-file">
                          <input type="file" name="image" (change)="expenseFilesUpload($event)" class="custom-file-input">
                          <label class="custom-file-label">{{'Choose file' | translate}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 row">
                    <div class="col-sm-1" *ngFor="let files of expenseFilesList" style="border: 1px solid #e5e9f2;margin-left: 15px;">
                      <img src="{{files.path}}"  alt="Preview" *ngIf="files.type=='image'">
                      <video *ngIf="files.type=='video'" width="100%" height="100%" poster="assets/images/play-button.png">
                        <source src="{{files.path}}" type="video/mkv">
                      </video>
                      <img *ngIf="files.type!='video' && files.type != 'image'" src="assets/file_icons/{{files.ext}}.png">
                      <span class="file-title">{{files.original_name}}</span>
                      <a class="remove-image" (click)="deleteExpenseFile(files.id)" style="display: inline;">&#215;</a>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <button type="submit" class="btn btn-primary" data-style="slide-down" [ladda]="isBtnLoading" [disabled]="!expenseAddForm.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
<!-- Modal Content Code -->
<div class="modal fade zoom" id="expense_type_modal">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <a href="#" class="close" data-dismiss="modal" aria-label="Close">
        <em class="icon ni ni-cross"></em>
      </a>
      <div class="modal-header">
        <h5 class="modal-title">{{'New Expense Type' | translate}}</h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="form-label" for="expTypeCtrl">{{'Expense Type' | translate}}</label>
          <div class="form-control-wrap">
            <input type="text" [formControl]="expTypeCtrl" name="expTypeCtrl" id="expTypeCtrl" class="form-control" placeholder="Enter here">
            <span class="invalid-select" *ngIf="expTypeCtrl.hasError('required') && (expTypeCtrl.touched || expTypeCtrl.dirty)">
              {{'Expense Type' | translate}} {{appConst.EMPTY_ERROR | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-primary" (click)="addNewExpenseType()" [ladda]="expBtnLoading" [disabled]="!expTypeCtrl.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
      </div>
    </div>
  </div>
</div>
