import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CommonHelper} from '../../app-config/common-helper';
import {AppConstants} from '../../app-config/app-constants';
import {MatDialog} from '@angular/material/dialog';
import {SocialAuthService} from 'angularx-social-login';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private route: Router,
              public translate: TranslateService,
              public commonHelper: CommonHelper,
              public appConst: AppConstants,
              public dialog: MatDialog,
              private socialAuthService: SocialAuthService
  ) { translate.setDefaultLang('en'); }
  localData: any = JSON.parse(localStorage.getItem("userData"));
  ngOnInit(): void {
      this.translate.use(localStorage.getItem("lang"));
  }
  logoutUser () {
    this.socialAuthService.signOut();
    localStorage.clear();
  }
  openProfilePicDialog() {
    this.dialog.open(DialogProfilePictureChange);
  }
}
@Component({
  selector: 'dialog-profile-picture-change',
  templateUrl: './dialog-profile-picture-change.html',
})
export class DialogProfilePictureChange implements OnInit{
  constructor(private route: Router,
              public translate: TranslateService,
              public commonHelper: CommonHelper,
              public appConst: AppConstants,
              public dialog: MatDialog
  ) { translate.setDefaultLang('en'); }
  profileData: any;
  bgImageProfile: any = "assets/images/user_avatar.png";
  ngOnInit(): void {
  }
  previewProfileImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.profileData = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.bgImageProfile = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}
