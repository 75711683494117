import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../../app-config/app-constants';
import { ActivatedRoute, Router} from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonHelper } from '../../app-config/common-helper';
import { DbTables } from '../../app-config/db-tables';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./sign-up.component.css']
})

export class VerifyUserComponent implements OnInit {

  constructor(public appConst: AppConstants,
              public route: Router,
              private loginService: LoginService,
              public commonHelper: CommonHelper,
              public dbTables: DbTables,
              private urlRoute: ActivatedRoute
  ) { }
  isSuccess: boolean = false;
  ngOnInit(): void {
    const activationCode = this.urlRoute.snapshot.paramMap.get("code");
    console.log("activationCode :", activationCode);
    this.verifyUser(activationCode);
  }
  verifyUser(activationCode) {
      const paramData = {
          dbname: this.appConst.DATABASE,
          activationCode: activationCode
      };
      this.loginService.verifyUser(paramData).subscribe(
          Response => {
              const apiResponse: any = Response;
              console.log(apiResponse);
              if (apiResponse.opcode === 1)
                this.isSuccess = true;
          },
          Error => {
                console.log(Error.error);
                this.isSuccess = false;
          }
      )
  }
}
