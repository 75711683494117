<div class="container-fluid">
  <div class="nk-header-wrap">
    <div class="nk-menu-trigger d-xl-none ml-n1">
      <a  class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a>
    </div>
    <div class="nk-header-brand d-xl-none">
      <a href="admin/properties" class="logo-link">
        <img class="logo-light logo-img" style="width:150px" src="assets/images/logo.png" srcset="assets/images/logo2x.png 2x" alt="logo">
        <img class="logo-dark logo-img" style="width:150px" src="assets/images/logo.png" srcset="assets/images/logo2x.png 2x" alt="logo-dark">
<!--          <span class="nio-version">General</span>-->
      </a>
    </div><!-- .nk-header-brand -->
    <!--<div class="nk-header-news d-none d-xl-block">
      <div class="nk-news-list">
        <a class="nk-news-item" >
          <div class="nk-news-icon">
            <em class="icon ni ni-card-view"></em>
          </div>
          <div class="nk-news-text">
              <p>Do you know the latest update of 2019? <span> A overview of our is now available on YouTube</span></p>
              <em class="icon ni ni-external"></em>
          </div>
        </a>
      </div>
    </div>--><!-- .nk-header-news -->
    <div class="nk-header-tools">
      <ul class="nk-quick-nav">
        <li class="dropdown user-dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown">
            <div class="user-toggle">
              <div class="user-avatar sm">
                  <span *ngIf="localData.profile_image == null || localData.profile_image == '' else profileElse">
                    {{commonHelper.getNameThumbnail(localData.first_name+' '+localData.last_name)}}
                  </span>
                  <ng-template #profileElse>
                    <img *ngIf="localData.login_type=='PORTAL' else loginTypeElse" src="{{appConst.API_END+localData.profile_image}}" alt="P" width="100%" height="100%">
                    <ng-template #loginTypeElse>
                      <img src="{{localData.profile_image}}" alt="P" width="100%" height="100%">
                    </ng-template>
                  </ng-template>
              </div>
              <div class="user-info d-none d-md-block">
                <div class="user-status">{{localData.user_role | translate}}</div>
                <div class="user-name dropdown-indicator">{{localData.first_name}}</div>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
            <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
              <div class="user-card">
                <div class="user-avatar">
                  <span *ngIf="localData.profile_image == null || localData.profile_image == '' else profileElse">
                    {{commonHelper.getNameThumbnail(localData.first_name+' '+localData.last_name)}}
                  </span>
                  <ng-template #profileElse>
                    <img src="{{appConst.API_END+localData.profile_image}}" alt="" width="100%" height="100%">
                  </ng-template>
                </div>
                <div class="user-info">
                  <span class="lead-text">{{localData.first_name+" "+localData.last_name}}</span>
                  <span class="sub-text">{{localData.user_email}}</span>
                </div>
              </div>
            </div>
            <div class="dropdown-inner">
              <ul class="link-list">
                <li>
                  <a href="admin/viewProfile/{{commonHelper.encodeURIParam(localData.userid)}}"><em class="icon ni ni-user-alt"></em><span>{{'View Profile' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a href="admin/viewProfile/{{commonHelper.encodeURIParam(localData.userid)}}"><em class="icon ni ni-camera"></em><span>
                  {{'Change Profile Picture' | translate}}</span></a>
                </li>
              </ul>
            </div>
            <div class="dropdown-inner">
              <ul class="link-list">
                <li><a href="/" (click)="logoutUser()"><em class="icon ni ni-signout"></em><span>{{'Sign out' | translate}}</span></a></li>
              </ul>
            </div>
          </div>
        </li><!-- .dropdown -->
        <li class="dropdown notification-dropdown mr-n1">
          <a  class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
            <div class="icon-status icon-status-info"><em class="icon ni ni-bell"></em></div>
          </a>
          <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
            <div class="dropdown-head">
              <span class="sub-title nk-dropdown-title">{{'Notifications' | translate}}</span>
              <a>{{'Mark All as Read' | translate}}</a>
            </div>
            <div class="dropdown-body">
              <!--<div class="nk-notification">
                <div class="nk-notification-item dropdown-inner">
                  <div class="nk-notification-icon">
                    <em class="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                  </div>
                  <div class="nk-notification-content">
                    <div class="nk-notification-text">You have requested to <span>Widthdrawl</span></div>
                    <div class="nk-notification-time">2 hrs ago</div>
                  </div>
                </div>
                <div class="nk-notification-item dropdown-inner">
                  <div class="nk-notification-icon">
                    <em class="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
                  </div>
                  <div class="nk-notification-content">
                    <div class="nk-notification-text">Your <span>Deposit Order</span> is placed</div>
                    <div class="nk-notification-time">2 hrs ago</div>
                  </div>
                </div>
                <div class="nk-notification-item dropdown-inner">
                  <div class="nk-notification-icon">
                    <em class="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                  </div>
                  <div class="nk-notification-content">
                    <div class="nk-notification-text">You have requested to <span>Widthdrawl</span></div>
                    <div class="nk-notification-time">2 hrs ago</div>
                  </div>
                </div>
                <div class="nk-notification-item dropdown-inner">
                  <div class="nk-notification-icon">
                    <em class="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
                  </div>
                  <div class="nk-notification-content">
                    <div class="nk-notification-text">Your <span>Deposit Order</span> is placed</div>
                    <div class="nk-notification-time">2 hrs ago</div>
                  </div>
                </div>
                <div class="nk-notification-item dropdown-inner">
                  <div class="nk-notification-icon">
                    <em class="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                  </div>
                  <div class="nk-notification-content">
                    <div class="nk-notification-text">You have requested to <span>Widthdrawl</span></div>
                    <div class="nk-notification-time">2 hrs ago</div>
                  </div>
                </div>
                <div class="nk-notification-item dropdown-inner">
                  <div class="nk-notification-icon">
                    <em class="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
                  </div>
                  <div class="nk-notification-content">
                    <div class="nk-notification-text">Your <span>Deposit Order</span> is placed</div>
                    <div class="nk-notification-time">2 hrs ago</div>
                  </div>
                </div>
              </div>-->
            </div><!-- .nk-dropdown-body -->
            <div class="dropdown-foot center">
              <a>{{'View All' | translate}}</a>
            </div>
          </div>
        </li><!-- .dropdown -->
      </ul><!-- .nk-quick-nav -->
    </div><!-- .nk-header-tools -->
  </div><!-- .nk-header-wrap -->
</div><!-- .container-fliud -->

