import { Component, Inject, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppConstants} from '../../app-config/app-constants';
import {CommonHelper} from '../../app-config/common-helper';
import {CommonService} from '../../services/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {GridLayout, Image, PlainGalleryConfig, PlainGalleryStrategy} from '@ks89/angular-modal-gallery';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ChangePasswordPopup} from '../settings/settings.component';
declare function $(s: string);

@Component({
  selector: 'app-rent',
  templateUrl: './rent.component.html',
  styleUrls: ['./rent.component.css']
})
export class RentComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private urlRoute: ActivatedRoute,
    public datePipe: DatePipe,
    public ngxSpinner: NgxSpinnerService,
    public matDialog: MatDialog,
  ) { translate.setDefaultLang("en"); }
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  rentPropertyList: any = this.appConst.API_RESPONSE;
  rentTenantList: any = this.appConst.API_RESPONSE;
  monthList: any = this.commonHelper.getMonths();
  yearList: any = this.commonHelper.getYears().reverse();
  viewBtnLoader: boolean = false;
  excelBtnLoader: boolean = false;
  rentCollectionData: any = this.appConst.API_RESPONSE;
  rentTotal: number = 0;
  rentMonthParam: string = ""+this.appConst.CURRENT_MONTH;
  rentYearParam: number = this.appConst.CURRENT_YEAR;
  rentFilter = new FormGroup({
    month: new FormControl(this.rentMonthParam, [Validators.required]),
    year: new FormControl(this.rentYearParam, [Validators.required]),
  });
  ngOnInit(): void {
    this.ngxSpinner.show();
    this.translate.use(localStorage.getItem("lang"));
    this.rentMonthParam = this.urlRoute.snapshot.paramMap.get("month");
    this.rentYearParam = parseInt(this.urlRoute.snapshot.paramMap.get("year"));
    if (this.rentMonthParam != "0" && this.rentYearParam != 0) {
      this.rentFilter.controls.month.setValue(this.rentMonthParam);
      this.rentFilter.controls.year.setValue(this.rentYearParam);
      this.fetchRentCollectionData('view');
    }
    this.fetchRentTotal();
    this.ngxSpinner.hide();
  }
  get rentFilterControls() {
    return this.rentFilter.controls;
  }
  fetchRentCollectionData(type: string) {
    // console.log("rentFilter :",this.rentFilter);
    const data: any = this.rentFilter.value;
    if (this.rentMonthParam != '0' && this.rentYearParam != 0) {
      data.month = this.rentMonthParam;
      data.year = this.rentYearParam;
    }
    // data.monthName = this.commonHelper.getMonthName(data.month);
    data.ownerId = this.localUser.userid;
    if (data.tenantId == null) {
      if (this.localUser.user_role == "TENANT")
        data.tenantId = this.localUser.tenant_id;
      else
        delete data.tenantId;
    }
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      data: data,
      type: type
    };
    this.viewBtnLoader = true;
    this.excelBtnLoader = true;
    // console.log("fetchRentCollectionData paramData:", paramData);
    this.ngxSpinner.show();
    this.commonService.manageRentCollectionService({dbname: this.appConst.DATABASE, ownerId: this.localUser.userid, rent_month: this.rentFilter.value.month, rent_year: this.rentFilter.value.year}, "add").subscribe(
      Response => {
        this.commonService.fetchRentCollectionService(paramData).subscribe(
          Response => {
            const apiResponse: any = Response;
            console.log("fetchRentCollectionData apiResponse:", apiResponse);
            if (apiResponse.opcode === 1) {
              if (type == "view") {
                this.rentCollectionData = apiResponse;
                this.rentCollectionData.extra = this.rentCollectionData.data.map(t => t.total_rent_paid).reduce((a, value) => a + value, 0);
              } else {
                window.open(this.appConst.API_END + "common/download?file_path=" + apiResponse.data.filePath);
              }
            }
            else {
              Swal.fire("", this.translate.instant("No records found"), "error");
            }
            this.viewBtnLoader = false;
            this.excelBtnLoader = false;
            this.ngxSpinner.hide();
          },
          Error => {
            // console.log("fetchRentCollectionData Error :", Error.error);
            this.viewBtnLoader = false;
            this.excelBtnLoader = false;
          }
        );
      },
      Error => {
        console.log("fetchRentCollectionData Error :", Error.error);
        this.viewBtnLoader = false;
        this.excelBtnLoader = false;
      }
    );
  }
  fetchPropertyForRent() {
    const paramData: any = { dbname: this.appConst.DATABASE };
    if (this.localUser.user_role == "OWNER")
      paramData.userId = this.localUser.userid;
    else if (this.localUser.user_role == "TENANT")
      paramData.propertyId = this.localUser.prop_id;
    else
      paramData.isDeleted = 0;
    this.commonService.fetchPropertyService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        // console.log("fetchPropertyForRent :", apiResponse);
        this.rentPropertyList = apiResponse;
      },
      Error => {
        console.log("fetchPropertyForRent :", Error);
      }
    );
  }
  fetchTenantForRent(propertyId) {
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      propertyId: propertyId
    };
    if (this.localUser.user_role == "TENANT")
      paramData.tenantId = this.localUser.tenant_id;
    this.ngxSpinner.show();
    this.commonService.fetchTenantsService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        // console.log("fetchTenantForRent apiResponse:", apiResponse);
        this.rentTenantList = apiResponse;
        this.ngxSpinner.hide();
      },
      Error => {
        console.log("fetchTenantForRent Error:", Error);
        this.ngxSpinner.hide();
      }
    );
  }
  fetchRentTotal() {
    const paramData: any = {
      dbname: this.appConst.DATABASE
    };
    if (this.localUser.user_role == "OWNER")
      paramData.rentOwnerId = this.localUser.userid;
    else if (this.localUser.user_role == "TENANT")
      paramData.tenantId = this.localUser.tenant_id;
    else
      paramData.isDeleted = 0;
    this.commonService.fetchRentTotalService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        // console.log("fetchRentTotal :", apiResponse);
        if (apiResponse.opcode === 1)
          this.rentTotal = apiResponse.data[0].rent_total;
      },
      Error => {
        console.log("fetchRentTotal Error:", Error.error);
      }
    );
  }
  showHideRentAmount(index) {
    $("#rent_amount_txt_"+index).hide();
    $("#rent_amount_"+index).show();
    $("#payment_method_txt_"+index).hide();
    $("#payment_method_"+index).show();
    $("#payment_method_"+index).select2();
  }
  saveRentAmount(index, id, tenantId, propertyId, month, year) {
    const rentAmount = $("#rent_amount_"+index).val();
    const paymentMethod = $("#payment_method_"+index).val();
    let rent_is_paid = 0;
    if ($("#is_rent_paid_"+index).prop("checked") === true) {
      rent_is_paid = 1;
    }
    const data: any = {
      total_rent_paid: rentAmount,
      payment_by: paymentMethod,
      rent_month: month,
      rent_year: year,
      rent_tenant_id: tenantId,
      rent_owner_id: this.localUser.userid,
      rent_prop_id: propertyId,
      rent_is_paid: rent_is_paid,
      rent_paid_datetime: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
    };
    if (rent_is_paid == 1) {
      data.payment_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
    let flag = "edit";
    console.log("id :", id);
    if (id == null || id == "") {
      flag = "add";
      id = 0;
    }
    const paramData = {
      dbname: this.appConst.DATABASE,
      data: data,
      fileId: 0,
      rentCollectionId: id
    };
    console.log("Rent amount :", paramData);
    this.commonService.manageRentCollectionService(paramData, flag).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("manageRentCollectionService :", apiResponse);
        if (apiResponse.opcode === 1) {
          //Swal.fire("", this.translate.instant("Rent") + " " + this.translate.instant("data successfully saved"), "success").then((r)=> {
            // $("#rent_amount_txt_"+index).show();
            // $("#rent_amount_txt_"+index).html(rentAmount);
            // $("#rent_amount_"+index).hide();
            // $("#payment_method_txt_"+index).show();
            // $("#payment_method_txt_"+index).html(paymentMethod);
            // $("#payment_method_"+index).hide();

          //});
          this.fetchRentCollectionData('view');
        }
        else {
          Swal.fire("", this.translate.instant(apiResponse.message) , "error");
        }
      },
      Error => {
        console.log("addRentCollection ", Error);
        Swal.fire("", this.translate.instant("Connection error") + this.translate.instant(".") + this.translate.instant("Please try again"), "error").then();
      }
    );
  }
  openSendPaymentLinkPopup(rentCollectionId, rentMonth, rentYear) {
    // this.matDialog.open(SendPaymentLinkPopup);
    const param: any = {
      dbname: this.appConst.DATABASE,
      rentCollectionId: rentCollectionId,
      month: rentMonth,
      year: rentYear,
      expire_by: 0
    }
    this.commonService.sendPaymentLink(param).subscribe(
      Response => {
          const apiResponse: any = Response;
          console.log("sendPaymentLink :", apiResponse);
          if (apiResponse.opcode == 1) {
            Swal.fire("", this.translate.instant("Payment link sent successfully."), "success").then(r=>{
              this.fetchRentCollectionData('view');
            });
          }
          else if (apiResponse.opcode == 3){
            Swal.fire("", this.translate.instant(apiResponse.message), "error");
          }
          else {
            Swal.fire("", this.translate.instant("Payment link not sent.Please try again."), "error");
          }
      },
      Error => {
          console.log("sendPaymentLink Error:", Error);
      }
    );
  }
}
@Component({
  selector: 'send-payment-link-popup',
  templateUrl: 'send-payment-link-popup.component.html',
})
export class SendPaymentLinkPopup {
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SendPaymentLinkPopup>,
    public appConst:  AppConstants,
    public commonService: CommonService
  ) {}
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  expiryByCtrl: any = new FormControl(null, [Validators.required]);
  saveBtnLoading: boolean = false;
  onNoClick(): void {
  }
  sendPaymentLink() {

  }
}
@Component({
  selector: 'app-add-rent',
  templateUrl: './add-rent.component.html',
  styleUrls: ['./rent.component.css']
})
export class AddRentComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private urlRoute: ActivatedRoute,
    public datePipe: DatePipe,
    public ngxSpinner: NgxSpinnerService
  ) { translate.setDefaultLang("en"); }
  manageFlag: string = 'add';
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  rentPropertyList: any = this.appConst.API_RESPONSE;
  rentTenantList: any = this.appConst.API_RESPONSE;
  rentSaveBtnLoading: boolean = false;
  monthList: any = this.commonHelper.getMonths();
  yearList: any = this.commonHelper.getYears();
  rentFileList: any = [];
  rentHeadList: any = [];
  rentMonthParam: string = this.appConst.CURRENT_MONTH;
  rentYearParam: number = this.appConst.CURRENT_YEAR;
  rentTypeCtrl: any = new FormControl(null, [Validators.required]);
  rentBtnLoading: boolean = false;
  rentTypeList: any = [];

  rentFormGroup = new FormGroup({
    rent_prop_id: new FormControl({value: null, disabled: true}, [Validators.required]),
    rent_tenant_id: new FormControl({value: null, disabled: true}, [Validators.required]),
    base_amount: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.required]),
    tax_rate: new FormControl('', [Validators.pattern("^[0-9]*$")]),
    tax_amount: new FormControl('', [Validators.pattern("^[0-9]*$")]),
    discount: new FormControl('', [Validators.pattern("^[0-9]*$")]),
    total_amount: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.required]),
    payment_by: new FormControl(null, [Validators.required]),
    rent_month: new FormControl({value: null, disabled: true}, [Validators.required]),
    rent_year: new FormControl({value: null, disabled: true}, [Validators.required]),
    payment_date: new FormControl('')
  });
  ngOnInit(): void {
    this.ngxSpinner.show();
    this.translate.use(localStorage.getItem("lang"));
    const rentId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    if (rentId != '0') {
      this.editRentCollection(rentId);
      this.manageFlag = "edit";
    }
    this.fetchPropertyForRent();
    this.fetchRentHead();
    this.ngxSpinner.hide();
  }
  get rentFormControls() {
    return this.rentFormGroup.controls;
  }
  addRentCollection(flag) {
    const paymentData = $("#payment_date").val();
    if (paymentData == null || paymentData == "") {
        Swal.fire("", this.translate.instant("Please select payment date"), "warning");
        return false;
    }
    this.rentFormGroup.controls.payment_date.setValue(paymentData);
    // console.log("rentFormGroup :", this.rentFormGroup.value);
    const rentData: any = this.rentFormGroup.value;
    const fileId = this.commonHelper.convertToStringByKey("id", this.rentFileList);
    rentData.rent_paid_datetime = this.datePipe.transform(Date.now(), "yyyy-MM-dd HH:mm:ss");
    const rentHeadArray = [];
    if (this.rentHeadList.length > 0) {
      for (let i = 0; i < this.rentHeadList.length; i++) {
        const rentAmount = $("#rent_head_amount_" + i).val();
        const rentHeadId = $("#rent_head_type_" + i).val();
        const rentHeadName = $("#rent_head_type_" + i + " option:selected").text();
        if (rentHeadId != "" && rentHeadId != null) {
          const rentHeadObj = {
            amount: rentAmount,
            head_id: rentHeadId,
            head_name: rentHeadName
          };
          rentHeadArray.push(rentHeadObj);
        }
      }
    }
    rentData.rent_extra_amount = JSON.stringify(rentHeadArray);
    const data = {
      dbname: this.appConst.DATABASE,
      data: rentData,
      fileId: fileId,
      rentCollectionId: this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"))
    };
    // console.log("addRentCollection :", data);
    this.rentSaveBtnLoading = true;
    this.commonService.manageRentCollectionService(data, flag).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant("Rent") + " " + this.translate.instant("data successfully saved"), "success");
          if (flag === 'add')
            window.location.href = 'admin/rent';
        }
        else {
          Swal.fire("", this.translate.instant("Rent") + " " + this.translate.instant("data not saved") + this.translate.instant(".") + this.translate.instant("Please try again"), "error");
        }
        this.rentSaveBtnLoading = false;
      },
      Error => {
        console.log("addRentCollection ", Error);
        Swal.fire("", this.translate.instant("Connection error") + this.translate.instant(".") + this.translate.instant("Please try again"), "error").then();
        this.rentSaveBtnLoading = false;
      }
    );
  }
  fetchPropertyForRent(propertyId="0") {
    const paramData: any = { dbname: this.appConst.DATABASE };
    if (this.localUser.user_role == "OWNER")
      paramData.userId = this.localUser.userid;
    else if (this.localUser.user_role == "TENANT")
      paramData.propertyId = this.localUser.prop_id;
    else
      paramData.isDeleted = 0;
    if (propertyId != "0")
      paramData.propertyId = ""+propertyId;
    this.commonService.fetchPropertyService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("fetchPropertyForRent :", apiResponse);
        this.rentPropertyList = apiResponse;
      },
      Error => {
        console.log("fetchPropertyForRent :", Error);
      }
    );
  }
  fetchTenantForRent(propertyId, tenantId="0") {
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      propertyId: ""+propertyId
    };
    if (tenantId != "0")
      paramData.tenantId = ""+tenantId;
    if (this.localUser.user_role == "TENANT")
      paramData.tenantId = this.localUser.tenant_id;
    this.ngxSpinner.show();
    this.commonService.fetchTenantsService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("fetchTenantForRent apiResponse:", apiResponse);
        this.rentTenantList = apiResponse;
        this.ngxSpinner.hide();
      },
      Error => {
        console.log("fetchTenantForRent Error:", Error);
        this.ngxSpinner.hide();
      }
    );
  }
  rentFilesUpload (event) {
    const formData = new FormData();
    if (event.target.files.length > 0) {
      const fileData = event.target.files[0];
      // console.log("fileData Size:", fileData.size);
      if (fileData.size > 25000000) {
        Swal.fire("", this.translate.instant("File memory size should be less than 25MB"), "warning");
        return false;
      }
      formData.append("prop_file", event.target.files[0]);
      formData.append("dbname", this.appConst.DATABASE);
      formData.append("sessionUserId", this.localUser.userid);
      this.ngxSpinner.show();
      this.commonService.uploadRentFilesService(formData).subscribe(
        Response => {
          const apiResponse: any = Response;
          console.log("rentFilesUpload :", apiResponse);
          if (apiResponse.opcode === 1) {
            const fileType = apiResponse.data.file_type.split("/");
            const fileExt = this.commonHelper.getFileExtentionByType(apiResponse.data.file_type);
            const fileData = {
              id: apiResponse.data.rent_file_id,
              type: fileType[0],
              path: this.appConst.API_END + apiResponse.extra.folderPath + apiResponse.data.file_name,
              ext: fileExt,
              original_name: apiResponse.data.original_name
            }
            this.rentFileList.push(fileData);
          }
          else {
            Swal.fire("",this.translate.instant("file data not saved") + this.translate.instant(".") + this.translate.instant("Please try again"), "error");
          }
          this.ngxSpinner.hide();
        },
        Error => {
          console.log("rentFilesUpload Error :", Error);
          this.ngxSpinner.hide();
        }
      );
    }
  }
  deleteRentFile(id) {
    console.log("File Id :", id);
    this.ngxSpinner.show();
    this.commonService.deleteRentFileService({dbname: this.appConst.DATABASE, id: id}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1) {
          const removeItem = this.rentFileList.filter((item) => item.id !== id);
          this.rentFileList = removeItem;
        }
        else {
          Swal.fire("", this.translate.instant("Connection error"), "error").then();
        }
        this.ngxSpinner.hide();
      },
      Error => {
        console.log("deleteRentFile Error :", Error);
        this.ngxSpinner.hide();
      }
    );
  }
  private editRentCollection(rentId) {
    this.commonService.fetchRentCollectionService({dbname: this.appConst.DATABASE, data: {rentCollectionId: rentId, ownerId: this.localUser.userid}}).subscribe(
      Response => {
        const apiResponse: any = Response;
        // console.log("editRentCollection :", apiResponse);
        if (apiResponse.opcode === 1) {
          const data: any = apiResponse.data[0];
          this.fetchPropertyForRent(data.rent_prop_id);
          this.rentFormGroup.controls.rent_prop_id.setValue(data.rent_prop_id);
          this.fetchTenantForRent(data.rent_prop_id, data.rent_tenant_id);
          this.rentFormGroup.controls.rent_tenant_id.setValue(data.rent_tenant_id);
          this.rentFormGroup.controls.base_amount.setValue(data.base_amount);
          this.rentFormGroup.controls.tax_rate.setValue(data.tax_rate);
          this.rentFormGroup.controls.tax_amount.setValue(data.tax_amount);
          this.rentFormGroup.controls.discount.setValue(data.discount);
          this.rentFormGroup.controls.total_amount.setValue(data.total_amount);
          this.rentFormGroup.controls.payment_by.setValue(data.payment_by);
          this.rentFormGroup.controls.rent_month.setValue(data.rent_month);
          this.rentFormGroup.controls.rent_year.setValue(data.rent_year);
          this.rentMonthParam = data.rent_month;
          this.rentYearParam = data.rent_year;
          this.rentFormGroup.controls.payment_date.setValue(data.payment_date);
          if(data.rent_extra_amount != "" && data.rent_extra_amount != null) {
            this.rentHeadList = JSON.parse(data.rent_extra_amount);
          }
          this.commonService.fetchRentCollectionFilesService({dbname: this.appConst.DATABASE, rentCollectionId: rentId}).subscribe(
            Response => {
              const apiFilesResponse: any = Response;
              if (apiFilesResponse.numRows > 0) {
                apiFilesResponse.data.forEach(fileValue => {
                  const fileType = fileValue.file_type.split("/");
                  const fileExt = this.commonHelper.getFileExtentionByType(fileValue.file_type);
                  const fileData = {
                    id: fileValue.rent_file_id,
                    type: fileType[0],
                    path: this.appConst.API_END + apiFilesResponse.extra.folderPath + fileValue.file_name,
                    ext: fileExt,
                    original_name: fileValue.original_name
                  }
                  this.rentFileList.push(fileData);
                });
                console.log("rentFileList :", this.rentFileList);
              }
            }
          );
        }
      }
    );
  }
  addExtraAmountOnRent() {
    const rentHeadObj = {
      amount: '',
      head_id: 0,
      head_name: ''
    };
    this.rentHeadList.push(rentHeadObj);
  }
  removeExtraRentAmount(index: number) {
    const amount = $("#rent_head_amount_"+index).val();
    const totalAmount = this.rentFormGroup.value.total_amount;
    this.rentFormGroup.controls.total_amount.setValue(parseFloat(totalAmount)-parseFloat(amount));
    this.rentHeadList.splice(index, 1);
  }
  addTotalPaidAmount() {
    const baseAmount = parseFloat(this.rentFormGroup.value.base_amount);
    const taxAmount = parseFloat(this.rentFormGroup.value.tax_amount);
    const discountAmount = parseFloat(this.rentFormGroup.value.discount);
    let totalAmount: number = baseAmount + taxAmount;
    // console.log("rentHeadList :", this.rentHeadList);
    if (this.rentHeadList.length > 0) {
      for (let i = 0; i < this.rentHeadList.length; i++) {
        const rentAmount = $("#rent_head_amount_" + i).val();
        if (rentAmount != "" && rentAmount != null) {
          totalAmount = totalAmount + parseFloat(rentAmount);
        }
      }
    }
    totalAmount = totalAmount - discountAmount;
    this.rentFormGroup.controls.total_amount.setValue(totalAmount);
  }
  addNewRentType() {
    const rentType = this.rentTypeCtrl.value;
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      data: {
        rent_head_name: rentType,
        added_datetime: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
      }
    };
    console.log("addNewRentType paramData :", paramData);
    this.rentBtnLoading = true;
    this.commonService.addRentHeadService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant(apiResponse.message), "success").then((r) => {
            this.fetchRentHead();
            $("#rent_type_modal").modal('hide');
          });
        }
        else {
          Swal.fire("", this.translate.instant(apiResponse.message), "error");
        }
        this.rentBtnLoading = false;
      },
      Error => {
        console.log("addNewRentType ", Error);
        Swal.fire("", this.translate.instant("Connection error") + this.translate.instant(".") + this.translate.instant("Please try again"), "error");
        this.rentBtnLoading = false;
      }
    );
  }
  fetchRentHead() {
    const paramData: any = {
      dbname: this.appConst.DATABASE,
    };
    this.commonService.fetchRentHeadService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("fetchRentHead apiResponse:", apiResponse);
        this.rentTypeList = apiResponse;
      },
      Error => {
        console.log("fetchRentHead Error:", Error);
      }
    );
  }
}
@Component({
  selector: 'app-view-rent',
  templateUrl: './view-rent.component.html',
  styleUrls: ['./rent.component.css']
})
export class ViewRentComponent implements OnInit {

  constructor(public translate: TranslateService,
              public route: Router,
              public appConst: AppConstants,
              private commonService: CommonService,
              public commonHelper: CommonHelper,
              private urlRoute: ActivatedRoute,
              public ngxSpinner: NgxSpinnerService
  ) {
    translate.setDefaultLang("en");
  }
  rentDetails: any;
  plainGalleryGrid: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.GRID,
    layout: new GridLayout({ width: '80px', height: '80px' }, { length: 3, wrap: true })
  };
  rentFiles: Image[] = [];
  lat = 51.678418;
  lng = 7.809007;
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  rentHeadList: any = [];
  otherFileList: any = [];
  rentMonthParam: string = this.appConst.CURRENT_MONTH;
  rentYearParam: number = this.appConst.CURRENT_YEAR;

  ngOnInit(): void {
    this.ngxSpinner.show();
    this.translate.use(localStorage.getItem("lang"));
    const rentId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    this.viewRentCollectionDetails(rentId);
    this.fetchRentCollectionFiles(rentId);
    this.ngxSpinner.hide();
  }
  viewRentCollectionDetails(rentId) {
    this.commonService.fetchRentCollectionService({dbname: this.appConst.DATABASE, data: {rentCollectionId: rentId, ownerId: this.localUser.userid}}).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("viewRentCollectionDetails apiResponse :", apiResponse);
        this.rentDetails = apiResponse.data[0];
        if (apiResponse.opcode == 1) {
          this.rentMonthParam = apiResponse.data[0].rent_month;
          this.rentYearParam = apiResponse.data[0].rent_year;
          this.rentHeadList = JSON.parse(apiResponse.data[0].rent_extra_amount);
        }
      },
      Error => {
        console.log("viewRentCollectionDetails Error :", Error);
      }
    );
  }
  fetchRentCollectionFiles (rentId) {
    this.commonService.fetchRentCollectionFilesService({dbname: this.appConst.DATABASE, rentCollectionId: rentId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("fetchRentCollectionFiles apiResponse :", apiResponse);
        if (apiResponse.numRows > 0) {
          let fileCount: number = 0;
          apiResponse.data.forEach(fileValue => {
            const fileType = fileValue.file_type.split("/");
            const fileExt = this.commonHelper.getFileExtentionByType(fileValue.file_type);
            if (fileType[0].includes('image')) {
              this.rentFiles.push(new Image(fileCount, {
                img: this.appConst.API_END + apiResponse.extra.folderPath + fileValue.file_name,
                description: 'Rent File'
              }));
            }
            else {
              this.otherFileList.push(
                {
                  id: fileValue.rent_file_id,
                  type: fileType[0],
                  path: this.appConst.API_END + apiResponse.extra.folderPath + fileValue.file_name,
                  ext: fileExt,
                  original_name: fileValue.original_name
                }
              );
            }
            fileCount++;
          });
          console.log("rentFiles :", this.rentFiles);
        }
      },
      Error => {
        console.log("rentFiles Error:", Error.error);
      }
    );
  }
}
