import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppConstants} from '../../app-config/app-constants';
import {CommonHelper} from '../../app-config/common-helper';
import {CommonService} from '../../services/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2';
import { Utility } from 'src/app/modals/utility';
import { HttpClient } from '@angular/common/http';
// import { Config } from 'protractor/built/config';
declare const $: any;

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.css']
})
export class UtilityComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private urlRoute: ActivatedRoute,
  ) { translate.setDefaultLang("en"); }

  

  localUser: any = JSON.parse(localStorage.getItem("userData"));
  propertyList: any = this.appConst.API_RESPONSE;
  utilityList: any = "";

  ngOnInit(): void {
    this.fetchPropertyUtility();
  }

  fetchPropertyUtility() {
    const paramData: any = {
        dbname: this.appConst.DATABASE
    };
    if (this.localUser.user_role == "TENANT")
      paramData.propertyId = this.localUser.prop_id;
    else if (this.localUser.user_role == "OWNER")
      paramData.userId = this.localUser.userid;
    else
      paramData.isDeleted = 0;

    this.commonService.fetchPropertyUtitlityService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        this.utilityList = apiResponse.data;
        console.log(apiResponse);
      },
      Error => {
        console.log("fetchProperty :", Error);
      }
    );
  }

  gotoUtilityPayment(){
    const paramData: any = {
      "billerId": "MAHI00000NATIC",
      "redirectTo": "transactions",
      "categoryName": "Electricity",
      "mobileNumber": "8181773053",
      "defaultAmount": "1000",
      "customerParams": [
        {
          "name": "Loan Number",
          "value": "10001"
        }
      ],
      "agentMobileNumber": "8181773053"
    };

      this.commonService.utilityPaymentService(paramData).subscribe(
        Response => {
          const apiResponse: any = Response;
          // if (apiResponse.opcode === 1997) {
          //   Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
          //     localStorage.clear();
          //     window.location.href = "/";
          //   });
          // }
          console.log("utilityPaymentService :", apiResponse);
          // window.location.href = ;
          window.open(apiResponse.link, "_blank"); 
        },
        Error => {
          console.log("utilityPaymentService :", Error);
        }
      );
  }
    getDataForUtilityAmount(ut_id: any){
      
      const paramData: any = { ut_id: ut_id };
      this.commonService.fetchUtilityBillStatus(paramData).subscribe(
        Response => {
          const apiResponse: any = Response;
          let apiRes: any = apiResponse.data[0];
            const utilityArr: any = {
              "billerId": apiRes.biller_id,
              "redirectTo": "transactions",
              "categoryName": apiRes.provider,
              "mobileNumber": apiRes.mobile_no,
              "defaultAmount": "0",
              "agentMobileNumber": "9425478321",
              "customerParams": [
                {
                  "name": apiRes.consumer,
                  "value": apiRes.unique_id,
                }
              ],
            };
            this.commonService.utilityPaymentService(utilityArr).subscribe(
              Response => {
                const dataResponse: any = Response;
                const urlLink = dataResponse.link;
                window.open(urlLink);
                // console.log("utilityPaymentService :::", dataResponse.link);               
              },
              Error => {
                console.log("utilityPaymentService :", Error);
              }
            );
        },
        Error => {
          console.log("loginResponse Error:", Error);
        }
      );
    }
}


@Component({
  selector: 'add-utility',
  templateUrl: './add-utility.component.html',
  styleUrls: ['./utility.component.css']
})
export class AddUtilityComponent implements OnInit {

  boardName: any;
  propertyData: any;
  consumer: any;
  biller_id: any;
  ivrs_no: any;
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  constructor(public translate: TranslateService,
              public route: Router,
              public appConst: AppConstants,
              private commonService: CommonService,
              public commonHelper: CommonHelper,
              private urlRoute: ActivatedRoute,
              private httpClient: HttpClient
  ) {
    translate.setDefaultLang("en");
  }

  ngOnInit(): void { 
    this.fetchPropertyForUtilityPayment();
  }

  propUtilityForm = new FormGroup({   
    utility: new FormControl('', [Validators.required]),
    board_name: new FormControl('', [Validators.required]),
    biller_id: new FormControl('', [Validators.required]),
    consumer: new FormControl('', [Validators.required]),
    ivrs: new FormControl('', [Validators.required]),
    mobile_no: new FormControl('', [Validators.required]),
    property_id: new FormControl('', [Validators.required]),
  });

  addUtilityDetails(){
    const utilityFormData: any = this.propUtilityForm.value;
    // console.warn(utilityFormData);
    const utilityArr: any = {
      "billerId": this.propUtilityForm.value.biller_id,
      "user_id" : this.localUser.userid,
      "redirectTo": "transactions",
      "categoryName": this.propUtilityForm.value.board_name,
      "mobileNumber": this.propUtilityForm.value.mobile_no,
      "defaultAmount": "0",
      "agentMobileNumber": "8871500630",
      "customerParams": [
        {
          "name": this.propUtilityForm.value.consumer,
          "value": this.propUtilityForm.value.ivrs,
        }
      ],
    };

    const utilityArrToSave: any = {
      "biller_id": this.propUtilityForm.value.biller_id,
      "user_id" : this.localUser.userid,
      "utility_type": this.propUtilityForm.value.utility,
      "provider": this.propUtilityForm.value.board_name,
      "mobile_no": this.propUtilityForm.value.mobile_no,
      "property_id": this.propUtilityForm.value.property_id,
      "bill_amount": "0",
      "is_verified": "0",
      "unique_id": this.propUtilityForm.value.ivrs,
      "consumer": this.propUtilityForm.value.consumer,
    };
    // console.log(this.propUtilityForm);
    this.getLinkForUtilityPayment(utilityArr,utilityArrToSave);
  }

  get utilityFormControls() {
    return this.propUtilityForm.controls;
  }

  getUtilityParams(){
      let utility_type = this.propUtilityForm.value.utility;
      // console.log(utility_type);
      const paramData: any = {utility_type:utility_type};
      this.commonService.fetchBoardByUtilityType(paramData).subscribe(
        Response => {
          const loginResponse: any = Response;
          this.boardName = loginResponse.data;
          console.log(this.boardName); 
          // console.log(loginResponse);    
        },
        Error => {
          console.log("loginResponse Error:", Error);
        }
      )
      // console.log(utility_type);
  }

  fetchInfoByBoardName(){
      let boardName = this.propUtilityForm.value.board_name;
      // console.log(board_name);
      const paramData = { boardName : boardName};
      this.commonService.fetchInfoByBoardName(paramData).subscribe(
        Response => {
          const loginResponse: any = Response;
          // this.biller_id = loginResponse.data[0].biller_id;
          this.propUtilityForm.controls.biller_id.setValue(loginResponse.data[0].biller_id);
          this.propUtilityForm.controls.consumer.setValue(loginResponse.data[0].consumer);
          // this.consumer = loginResponse.data[0].consumer;
          // console.log(this.consumer);    
        },
        Error => {
          console.log("loginResponse Error:", Error);
        }
      )
  }

  getLinkForUtilityPayment(paramData: any,utilityArrToSave:any){

      this.commonService.insertUtilityData(utilityArrToSave).subscribe(
        Response => {
          console.log("InsertResponse ::", Response);   
          this.commonService.utilityPaymentService(paramData).subscribe(
            Response => {
              const apiResponse: any = Response;
              // if (apiResponse.opcode === 1997) {
              //   Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
              //     localStorage.clear();
              //     window.location.href = "/";
              //   });
              // }
              console.log("utilityPaymentService :", apiResponse);
              var wnd = window.open(apiResponse.link);
              setTimeout(function() {
                wnd.close();
              }, 500);
              // this.httpClient.request(apiResponse.link);
              // this.httpClient.get(apiResponse.link);
              // window.location.href = ;
              // window.open(apiResponse.link, "_blank");
    
            },
            Error => {
              console.log("utilityPaymentService :", Error);
            }
          ); 
        },
        Error => {
          console.log("loginResponse Error:", Error);
      });      
  }

  fetchPropertyForUtilityPayment(){
    const paramData: any = {
      dbname: this.appConst.DATABASE,
      type: 'view',
      userId: this.localUser.userid
    }
    this.commonService.fetchPropertyService(paramData).subscribe(
      Response => {
          const apiResponse: any = Response;
          this.propertyData = apiResponse.data;
          console.log("fetchProperties :", apiResponse);
      },
      Error => {
          console.log("fetchProperties Error:", Error);
      }
  );
  }

  


}