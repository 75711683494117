import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AppConstants} from '../../app-config/app-constants';
import {CommonHelper} from '../../app-config/common-helper';
import {CommonService} from '../../services/common.service';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public appConst: AppConstants,
    public commonHelper: CommonHelper,
    private commonService: CommonService,
    private urlRoute: ActivatedRoute,
    public datePipe: DatePipe
  ) { translate.setDefaultLang("en"); }
  userManageForm = new FormGroup({
    user_email: new FormControl('', [Validators.required, Validators.email]),
    last_name: new FormControl('', [Validators.required]),
    first_name: new FormControl('', [Validators.required]),
    profile_image: new FormControl()
  });
  bgImageProfile: any = "assets/images/user_avatar.png";
  isBtnLoading: boolean = false;
  profileData: any;
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    const userId = this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id"));
    this.fetchUserData(userId);
  }
  get userFormControls() {
    return this.userManageForm.controls;
  }
  manageUserProfile() {
    const formData = new FormData();
    const userProfileData = this.userManageForm.value;
    console.log("userProfileData :", userProfileData);
    formData.append("prop_file", this.profileData);
    formData.append("data", JSON.stringify(userProfileData));
    formData.append("dbname", this.appConst.DATABASE);
    formData.append("userId", this.commonHelper.decodeURIParam(this.urlRoute.snapshot.paramMap.get("id")));
    formData.append("sessionUserId", this.localUser.userid);
    this.isBtnLoading = true;
    this.commonService.manageUserService(formData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("manageUserProfile apiResponse :", apiResponse);
        if (apiResponse.opcode === 1) {
          Swal.fire("", this.translate.instant("Profile") + " " + this.translate.instant("data successfully saved"), "success").then();
        }
        else {
          Swal.fire("", this.translate.instant(apiResponse.message), "error").then();
        }
        this.isBtnLoading = false;
      },
      Error => {
        console.log("manageUserProfile Error :", Error);
        this.isBtnLoading = false;
      }
    );
  }
  previewProfileImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.profileData = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.bgImageProfile = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  fetchUserData(userId) {
    this.commonService.fetchUsers({dbname: this.appConst.DATABASE, userId: userId}).subscribe(
      Response => {
        const apiResponse: any = Response;
        if (apiResponse.opcode === 1) {
          const data = apiResponse.data[0];
          this.userManageForm.controls.last_name.setValue(data.last_name);
          this.userManageForm.controls.first_name.setValue(data.first_name);
          this.userManageForm.controls.user_email.setValue(data.user_email);
          if (data.profile_image != null && data.profile_image != "") {
            this.bgImageProfile = this.appConst.API_END + data.profile_image;
          }
        }
      },
      Error => {
        console.log("fetchUserData Error :", Error);
      }
    );
  }
}
