import { Injectable } from '@angular/core';
import { AppConstants } from '../app-config/app-constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { DbTables } from '../app-config/db-tables';
import {Observable} from 'rxjs';
import { Utility } from '../modals/utility';
import { catchError } from 'rxjs/operators';
declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private appConstant: AppConstants,
              private http: HttpClient,
              private dbTables: DbTables
  ) { }
  getError: any = "";
  token: string = localStorage.getItem("token");
  localUserData: any = JSON.parse(localStorage.getItem("userData"));
  headerObj: any = new HttpHeaders().set("Authorization", "Bearer "+this.token);

  getLatLngByAddressService (paramData: any) {
    const request = {
      address: paramData.address
    };
    const geocoder = new google.maps.Geocoder();
    return new Observable(
      observer => {
        geocoder.geocode(request, (results, status) => {
          const latLngObj: any = {
            lat: 0,
            lng: 0
          };
          if (status == google.maps.GeocoderStatus.OK) {
            latLngObj.lat = results[0].geometry.location.lat(),
            latLngObj.lng = results[0].geometry.location.lng()
          }
          observer.next(latLngObj);
          observer.complete();
        })
      }
    );
  }
  fetchCountryData (paramData: any) {
      paramData.sessionUserId = this.localUserData.userid;
      return this.http.post(this.appConstant.API_END+"common/fetchCountry", paramData, {headers: this.headerObj});
  }
  fetchStateData (paramData: any) {
      paramData.sessionUserId = this.localUserData.userid;
      return this.http.post(this.appConstant.API_END+"common/fetchState", paramData, {headers: this.headerObj});
  }
  fetchCityData (paramData: any) {
      paramData.sessionUserId = this.localUserData.userid;
      return this.http.post(this.appConstant.API_END+"common/fetchCity", paramData, {headers: this.headerObj});
  }
  addProperty (paramData: any, flag) {
    paramData.sessionUserId = this.localUserData.userid;
    if (flag == "add")
      return this.http.post(this.appConstant.API_END+"property/addProperty", paramData, {headers: this.headerObj});
    else
      return this.http.post(this.appConstant.API_END+"property/updateProperty", paramData, {headers: this.headerObj});
  }
  fetchUsers (paramData: any) {
      paramData.sessionUserId = this.localUserData.userid;
      return this.http.post(this.appConstant.API_END+"common/users", paramData, {headers: this.headerObj});
  }
  uploadPropertyFiles (paramData: any) {
    // paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/propertyFile", paramData, {headers: this.headerObj});
  }
  deletePropertyFiles (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/deletePropertyFile", paramData, {headers: this.headerObj});
  }
  fetchPropertyService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/propertyWithJoin", paramData, {headers: this.headerObj});
  }
  fetchPropertyFilesService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/getPropertyFiles", paramData, {headers: this.headerObj});
  }
  deletePropertyService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/deleteProperty", paramData, {headers: this.headerObj});
  }
  changePropertyStatusService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/propertyStatus", paramData, {headers: this.headerObj});
  }
  viewPropertyService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/propertyWithJoin", paramData, {headers: this.headerObj});
  }
  fetchTenantsService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"tenant/tenants", paramData, {headers: this.headerObj});
  }
  addTenantsService (paramData: any, flag) {
    paramData.sessionUserId = this.localUserData.userid;
    console.log("addTenantsService : ", paramData);
    if (flag == 'add')
      return this.http.post(this.appConstant.API_END+"tenant/addTenant", paramData, {headers: this.headerObj});
    else
      return this.http.post(this.appConstant.API_END+"tenant/editTenant", paramData, {headers: this.headerObj});
  }
  deleteTenantService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"tenant/deleteTenant", paramData, {headers: this.headerObj});
  }
  changeTenantStatusService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"tenant/tenantStatus", paramData, {headers: this.headerObj});
  }
  manageUserService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"user/updateUser", paramData, {headers: this.headerObj});
  }
  uploadExpenseFilesService (paramData: any) {
    // paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"expense/expenseFile", paramData, {headers: this.headerObj});
  }
  manageExpenseService (paramData: any, flag) {
    paramData.sessionUserId = this.localUserData.userid;
    if (flag == "add")
      return this.http.post(this.appConstant.API_END+"expense/addExpense", paramData, {headers: this.headerObj});
    else
      return this.http.post(this.appConstant.API_END+"expense/editExpense", paramData, {headers: this.headerObj});
  }
  deleteExpenseFileService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"expense/deleteExpenseFile", paramData, {headers: this.headerObj});
  }
  fetchExpenseHeadService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"expense/expenseHead", paramData, {headers: this.headerObj});
  }
  fetchExpenseDataService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"expense/expenseData", paramData, {headers: this.headerObj});
  }
  fetchExpenseFilesService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"expense/getExpenseFiles", paramData, {headers: this.headerObj});
  }
  manageRentCollectionService (paramData: any, flag) {
    paramData.sessionUserId = this.localUserData.userid;
    if (flag == "add")
      return this.http.post(this.appConstant.API_END+"rent/addRent", paramData, {headers: this.headerObj});
    else
      return this.http.post(this.appConstant.API_END+"rent/editRent", paramData, {headers: this.headerObj});
  }
  uploadRentFilesService (paramData: any) {
    // paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"rent/addRentFile", paramData, {headers: this.headerObj});
  }
  deleteRentFileService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"rent/deleteRentFile", paramData, {headers: this.headerObj});
  }
  fetchRentCollectionService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"rent/rentData", paramData, {headers: this.headerObj});
  }
  fetchRentCollectionFilesService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"rent/rentFiles", paramData, {headers: this.headerObj});
  }
  fetchRentTotalService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"rent/rentTotal", paramData, {headers: this.headerObj});
  }
  fetchExpenseTotalService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"expense/expenseTotal", paramData, {headers: this.headerObj});
  }
  fetchUsersTest (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"common/users", paramData, {headers: this.headerObj});
  }
  saveChangePasswordService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"common/changePassword", paramData, {headers: this.headerObj});
  }
  saveCareTakerService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/addCareTaker", paramData, {headers: this.headerObj});
  }
  fetchCareTakerService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/getCareTakers", paramData, {headers: this.headerObj});
  }
  assignCareTakerToPropertyService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/assignCareTaker", paramData, {headers: this.headerObj});
  }
  fetchTenantWithPropertyService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/getTenantWithProperty", paramData, {headers: this.headerObj});
  }
  assignTenantToPropertyService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/assignTenant", paramData, {headers: this.headerObj});
  }
  unAssignTenantFromPropertyService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/unAssignTenant", paramData, {headers: this.headerObj});
  }
  uploadTenantsFilesService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"tenant/tenantFile", paramData, {headers: this.headerObj});
  }
  deleteTenantFilesService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"tenant/deleteTenantFile", paramData, {headers: this.headerObj});
  }
  fetchTenantFilesService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"tenant/getTenantFiles", paramData, {headers: this.headerObj});
  }
  addExpenseHeadService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"expense/addExpenseHead", paramData, {headers: this.headerObj});
  }
  checkUserIsAuthenticatedService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"auth/checkUserAuth", paramData, {headers: this.headerObj});
  }
  fetchOldPropertyTenant (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"tenant/getOldPropertyTenant", paramData, {headers: this.headerObj});
  }
  applyRentEntryForMonth (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"rent/applyRentEntryForMonth", paramData, {headers: this.headerObj});
  }
  fetchTenantPropertyGraphData (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"dashboard/graphDataTenantAndProperty", paramData, {headers: this.headerObj});
  }
  fetchRentCollectionGraphData (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"dashboard/graphDataRent", paramData, {headers: this.headerObj});
  }
  fetchRentCollectionPieGraphData (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"dashboard/graphDataRentPie", paramData, {headers: this.headerObj});
  }
  fetchLastFiveTransaction (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"dashboard/lastFiveTransaction", paramData, {headers: this.headerObj});
  }
  fetchRentHeadService(paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"rent/rentHead", paramData, {headers: this.headerObj});
  }
  addRentHeadService(paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"rent/addRentHead", paramData, {headers: this.headerObj});
  }
  fetchPaymentLinkDetails(paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"payment/paymentLinks", paramData, {headers: this.headerObj});
  }
  savePaymentLinkDetails(paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"payment/savePaymentLinks", paramData, {headers: this.headerObj});
  }
  sendPaymentLink(paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"payment/sendPaymentLink", paramData, {headers: this.headerObj});
  }
  fetchPropertyUtitlityService (paramData: any) {
    paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"property/fetchPropertyUtitlity", paramData, {headers: this.headerObj});
  }
  fetchBoardByUtilityType (paramData: Utility) {
    const dbname =  {dbname : this.appConstant.DATABASE};
    const sessionUserId = {sessionUserId : this.localUserData.userid};
    return this.http.post(this.appConstant.API_END+"common/fetchBoardByUtilityType", {...paramData, ...sessionUserId, ...dbname}, {headers: this.headerObj});
  }
  fetchInfoByBoardName (paramData) {
    const dbname =  {dbname : this.appConstant.DATABASE};
    const sessionUserId = {sessionUserId : this.localUserData.userid};
    return this.http.post(this.appConstant.API_END+"common/fetchInfoByBoardName", {...paramData, ...sessionUserId, ...dbname}, {headers: this.headerObj});
  }

  utilityPaymentService(paramData: any) {

    paramData.sessionUserId = this.localUserData.userid;
    const headers: any = new HttpHeaders({
      'x-api-user':'sloppy-dancing-collie',
      'x-api-password':'R9XRD5PQs-r5z8XGqfLzMqwXc4Q',
      'Content-Type':'application/json'
    }); 
    const urlLink:any = this.http.post("https://bills.setu.co/direct-link", paramData, {headers: headers});
    return urlLink;    
  }

  insertUtilityData (paramData: any) {
    const utilityArrToSave = { 
      dbname : this.appConstant.DATABASE,
      tableName : "utility",
      sessionUserId : this.localUserData.userid,
      tableData : paramData};
    // paramData.sessionUserId = this.localUserData.userid;
    return this.http.post(this.appConstant.API_END+"common/insert", utilityArrToSave, {headers: this.headerObj});
  }

  fetchUtilityBillStatus(paramData: any){

    const paramaters: any = { 
      dbname : this.appConstant.DATABASE,
      tableName : "utility",
      selectColumns : "*",
      whereClause : paramData,
      sessionUserId : this.localUserData.userid,
    };
    return this.http.post(this.appConstant.API_END+"common/fetch", paramaters, {headers: this.headerObj});
  }

  hitUrlByGet(link: any){

    const paramaters: any = { 
      key: "JVP8xGk4hsX2cZd0L3NQwYbI0mf4exPiSoAhVYnz",
      link : link,
    };
    return this.http.post("https://www.cuztomiseapp.com/safmed_api/cron/cUrlRequestForSetu", paramaters);
  }
  
}
