<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-content ">
        <div class="nk-block nk-block-middle wide-xs mx-auto">
          <div class="nk-block-content nk-error-ld text-center">
            <div class="card card-bordered card-preview">
              <div class="card-inner">
                <img src="assets/images/Error/udev.png" alt="404">
              </div>
            </div>
          </div>
        </div><!-- .nk-block -->
      </div>
    </div>
  </div>
</div>
