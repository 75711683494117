import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppConstants } from '../../app-config/app-constants';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonHelper } from '../../app-config/common-helper';
import { DbTables } from '../../app-config/db-tables';
import Swal from 'sweetalert2/dist/sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(public appConst: AppConstants,
              public route: Router,
              private loginService: LoginService,
              public commonHelper: CommonHelper,
              public dbTables: DbTables
  ) { }

  ngOnInit(): void {
  }
  isLoading: boolean = false;
  email = new FormControl('', [Validators.required, Validators.email]);

  sendForgotPassword() {
    console.log("email :", this.email.value);
    this.isLoading = true;
    const paramData = {
      dbname: this.appConst.DATABASE,
      email: this.email.value,
      resetKeyword: this.commonHelper.randomString(20),
      tableName: this.dbTables.USER
    };
    this.loginService.sendForgotPasswordService(paramData).subscribe(
      Response => {
          const apiResponse: any = Response;
          if (apiResponse.opcode === 1)
            Swal.fire("Thank you for submitting form.", "Please check your inbox. You must have received a mail with reset instructions.", "success");
          else
            Swal.fire("", apiResponse.message, "error");
          this.isLoading = false;
          this.route.navigateByUrl("/").then();
      },
      Error => {
          Swal.fire("", this.appConst.API_ERROR, "error");
          this.isLoading = false;
      }
    )
  }
}
