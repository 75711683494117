<div class="card-inner card-inner-lg">
  <div class="nk-block-head">
    <div class="nk-block-head-content">
      <h5 class="nk-block-title">Reset password</h5>
      <div class="nk-block-des">
        <p>Please type in the new password below.</p>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="form-label-group">
      <label class="form-label" for="newpassword">New Password</label>
    </div>
    <input type="password" [formControl]="newPassword" class="form-control form-control-lg" id="newpassword" placeholder="Enter new password">
    <span id="fv-newpassword-error" class="invalid" *ngIf="newPassword.touched || newPassword.dirty">
      <span *ngIf="newPassword.hasError('required')">New password {{appConst.EMPTY_ERROR}}</span>
      <span *ngIf="newPassword.hasError('minlength')">Password minimum length should be 6</span>
    </span>
  </div>
  <div class="form-group">
    <div class="form-label-group">
      <label class="form-label" for="newpassword1">Retype Password</label>
    </div>
    <input type="password" [formControl]="cnfPassword" class="form-control form-control-lg" id="newpassword1" placeholder="Enter new password">
    <span id="fv-newpassword1-error" class="invalid" *ngIf="cnfPassword.touched || cnfPassword.dirty">
      <span class="text-success" *ngIf="newPassword.value == cnfPassword.value else elseBlock">Password matched</span>
      <ng-template #elseBlock><span class="text-danger">Password not matched</span></ng-template>
    </span>
  </div>
  <div class="form-group">
    <button type="button" class="btn btn-lg btn-primary btn-block" data-style="slide-down" (click)="resetPassword(keyWord)" [ladda]="isLoading" [disabled]="(!newPassword.valid && !cnfPassword.valid) || newPassword.value != cnfPassword.value">Reset Password</button>
  </div>
  <div class="form-note-s2 text-center pt-4">
    <a (click)="route.navigateByUrl('/')"><strong>Return to login</strong></a>
  </div>
</div>
