<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Properties' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p>{{ 'Fill new property details' | translate}}.</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="admin/properties" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-arrow-left"></em><span>{{'Back' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
          <ngx-loading [show]="propFileLoader" [config]="{backdropBorderRadius: '3px'}"></ngx-loading>
          <div class="card-inner">
            <div class="preview-block">
              <form [formGroup]="propAddForm" (submit)="addPropertyDetails(manageFlag)">
              <div class="row gy-4">
                <!--<div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="country">{{'Property owner name' | translate}}</label>
                    <div class="form-control-wrap">
                      <ng-select [formControl]="propFormControls.prop_user_id" name="prop_user_id" id="prop_user_id" placeholder="Select Owner Of Property">
                        <ng-option *ngFor="let user of userList" [value]="user.userid">{{ user.first_name+" "+user.last_name}}</ng-option>
                      </ng-select>
                      <span class="invalid-select" *ngIf="propFormControls.prop_user_id.hasError('required') && (propFormControls.prop_user_id.touched || propFormControls.prop_user_id.dirty)">
                        {{'Property owner name' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                    </div>
                  </div>
                </div>-->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="prop-name">{{'Title' | translate}}</label>
                    <div class="form-control-wrap">
                      <input type="text" [formControl]="propFormControls.prop_name" name="prop_name" class="form-control" id="prop-name" placeholder="Enter property title/name">
                      <span class="invalid" *ngIf="propFormControls.prop_name.hasError('required') && (propFormControls.prop_name.touched || propFormControls.prop_name.dirty)">
                        {{'Property title' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="address-1">{{'Address' | translate}} 1</label>
                    <div class="form-control-wrap">
                      <input type="text" [formControl]="propFormControls.prop_address_1" name="prop_address_1" class="form-control" id="address-1" placeholder="Enter address line-1">
                      <span class="invalid" *ngIf="propFormControls.prop_address_1.hasError('required') && (propFormControls.prop_address_1.touched || propFormControls.prop_address_1.dirty)">
                        {{'Address' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="address-2">{{'Address' | translate}} 2</label>
                    <div class="form-control-wrap">
                      <input type="text" [formControl]="propFormControls.prop_address_2" name="prop_address_2" class="form-control" id="address-2" placeholder="Enter address line-2">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="country">{{'Country' | translate}}</label>
                    <div class="form-control-wrap">
                      <ng-select [formControl]="propFormControls.prop_country" (change)="fetchState($event)" bindLabel="country_name" name="prop_country" id="country" placeholder="Select Country">
                          <ng-option *ngFor="let country of countryList" [value]="country.country_id">{{ country.country_name }}</ng-option>
                      </ng-select>
                      <span class="invalid-select" *ngIf="propFormControls.prop_country.hasError('required') && (propFormControls.prop_country.touched || propFormControls.prop_country.dirty)">
                        {{'Country' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="state">{{'State' | translate}}</label>
                    <div class="form-control-wrap">
                      <ng-select [formControl]="propFormControls.prop_state" (change)="fetchCity($event)" name="prop_state" id="state" placeholder="Select State">
                        <ng-option *ngFor="let state of stateList" [value]="state.state_id">{{ state.state_name }}</ng-option>
                      </ng-select>
                      <span class="invalid-select" *ngIf="propFormControls.prop_state.hasError('required') && (propFormControls.prop_state.touched || propFormControls.prop_state.dirty)">
                        {{'State' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="city">{{'City' | translate}}</label>
                    <div class="form-control-wrap">
                      <ng-select [formControl]="propFormControls.prop_city" (change)="setAddressCity($event)" bindLabel="city_name" name="prop_city" id="city" placeholder="Select City">
                        <ng-option *ngFor="let city of cityList" [value]="city.city_id">{{city.city_name}}</ng-option>
                      </ng-select>
                      <span class="invalid-select" *ngIf="propFormControls.prop_city.hasError('required') && (propFormControls.prop_city.touched || propFormControls.prop_city.dirty)">
                        {{'City' | translate}} {{appConst.EMPTY_ERROR | translate}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="pin-code">{{'Pin Code' | translate}}</label>
                    <div class="form-control-wrap">
                      <input type="text" [formControl]="propFormControls.prop_pincode" name="prop_pincode" class="form-control" id="pin-code" placeholder="Enter pin code" (blur)="setPropertyLocation()">
                      <span class="invalid" *ngIf="(propFormControls.prop_pincode.touched || propFormControls.prop_pincode.dirty)">
                        <span *ngIf="propFormControls.prop_pincode.hasError('required')">{{'Pin Code' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        <span *ngIf="propFormControls.prop_pincode.hasError('minlength') || propFormControls.prop_pincode.hasError('maxlength')">{{'Pin Code length should be 6' | translate}}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <!--<div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="contact-no">{{'Contact No' | translate}}</label>
                    <div class="form-control-wrap">
                      <input type="text" [formControl]="propFormControls.prop_contact_no" name="prop_contact_no" class="form-control" id="contact-no" placeholder="Enter contact no.">
                      <span class="invalid" *ngIf="(propFormControls.prop_contact_no.touched || propFormControls.prop_contact_no.dirty)">
                        <span *ngIf="propFormControls.prop_contact_no.hasError('required')">{{'Contact No' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        <span *ngIf="propFormControls.prop_contact_no.hasError('minlength')">{{'Contact No should have at-least 10 digit' | translate}}</span>
                      </span>
                    </div>
                  </div>
                </div>-->
                <!--<div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="contact-email">{{'Contact Email' | translate}}</label>
                    <div class="form-control-wrap">
                      <input type="text" [formControl]="propFormControls.prop_contact_email" name="prop_contact_email" class="form-control" id="contact-email" placeholder="Enter contact email">
                      <span class="invalid" *ngIf="(propFormControls.prop_contact_email.dirty || propFormControls.prop_contact_email.touched)">
                        <span *ngIf="propFormControls.prop_contact_email.hasError('required')">{{'Contact Email' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        <span *ngIf="propFormControls.prop_contact_email.hasError('email')">{{'Contact email should be valid' | translate}}</span>
                      </span>
                    </div>
                  </div>
                </div>-->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="prop-rent">{{'Rent' | translate}}</label>
                    <div class="form-control-wrap">
                      <input type="text" [formControl]="propFormControls.prop_rent" name="prop_rent" class="form-control" id="prop-rent" placeholder="Enter rent">
                      <span class="invalid" *ngIf="(propFormControls.prop_rent.dirty || propFormControls.prop_rent.touched) && propFormControls.prop_rent.hasError('pattern')">
                          {{'Rent should be integer' | translate}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="preview-hr">
              <div class="row gy-4">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="form-label" for="prop-description">{{'Description' | translate}}</label>
                    <div class="form-control-wrap">
                      <textarea class="form-control" [formControl]="propFormControls.prop_description" name="prop_description" placeholder="Enter description" id="prop-description" style="min-height:70px;"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="preview-hr">
              <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label">{{'File Upload' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="custom-file">
                          <input type="file" name="image" ng2FileSelect (change)="propertyFilesUpload($event)" class="custom-file-input">
                          <label class="custom-file-label">{{'Choose file' | translate}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 row">
                    <div class="col-sm-1" *ngFor="let files of propertyFilesList" style="border: 1px solid #e5e9f2;margin-left: 15px;">
                      <img src="{{files.path}}"  alt="Preview" *ngIf="files.type=='image'">
                      <video *ngIf="files.type=='video'" width="100%" height="100%" poster="assets/images/play-button.png">
                            <source src="{{files.path}}" type="video/mkv">
                          </video>
                      <img *ngIf="files.type!='video' && files.type != 'image'" src="assets/file_icons/{{files.ext}}.png">
                      <span class="file-title">{{files.original_name}}</span>
                      <a class="remove-image" (click)="deletePropertyFile(files.id)" style="display: inline;">&#215;</a>
                    </div>
                  </div>
              </div>
              <hr class="preview-hr">
              <div class="row gy-4">
                <div class="col-sm-12">
                  <agm-map [latitude]="propXcoor" [longitude]="propYcoor">
                    <agm-marker
                      [latitude]="propXcoor"
                      [longitude]="propYcoor"
                      [markerDraggable]="true"
                      (dragEnd)="getLatlngFromMap($event)"
                    >
                    </agm-marker>
                  </agm-map>
                </div>
              </div>
              <hr class="preview-hr">
              <div class="row gy-4">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-primary" data-style="zoom-in" [ladda]="isBtnLoading" [disabled]="!propAddForm.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
