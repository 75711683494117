import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {CommonHelper} from '../../app-config/common-helper';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(public translate: TranslateService,
              public route: Router,
              public commonHelper: CommonHelper
  ) {
      translate.setDefaultLang("en");
  }
  localData: any = JSON.parse(localStorage.getItem("userData"));
  ngOnInit(): void {
      this.translate.use(localStorage.getItem("lang"));
  }

}
