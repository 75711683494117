<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'User Profile' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p>{{'Manage user profile details' | translate}}</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3">
                  <li class="nk-block-tools-opt"><a href="admin/dashboard" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-arrow-left"></em><span>{{'Back' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
          <div class="card-inner">
            <div class="preview-block">
              <span class="preview-title-lg overline-title"></span>
              <form [formGroup]="userManageForm" (submit)="manageUserProfile()">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="avatar-upload">
                      <div class="avatar-edit">
                        <input type='file' name="profile_image" [formControl]="userFormControls.profile_image" id="imageUpload" (change)="previewProfileImage($event)" accept=".png, .jpg, .jpeg" />
                        <label for="imageUpload"><em class="icon ni ni-pen2" style="position: absolute;top: 9px;right: 9px;"></em></label>
                      </div>
                      <div class="avatar-preview">
                        <div id="imagePreview" style="background-image: url({{bgImageProfile}});">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="contact-email">{{'Last Name' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-user"></em></div>
                        <input type="text" [formControl]="userFormControls.last_name" name="last_name" class="form-control" id="last_name" placeholder="Enter Last Name">
                        <span class="invalid" *ngIf="(userFormControls.last_name.dirty || userFormControls.last_name.touched)">
                            <span *ngIf="userFormControls.last_name.hasError('required')">{{'Last Name' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="contact-email">{{'First Name' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-user"></em></div>
                        <input type="text" [formControl]="userFormControls.first_name" name="first_name" class="form-control" id="first_name" placeholder="Enter First Name">
                        <span class="invalid" *ngIf="(userFormControls.first_name.dirty || userFormControls.first_name.touched)">
                            <span *ngIf="userFormControls.first_name.hasError('required')">{{'First Name' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="contact-email">{{'Email' | translate}}</label>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right"><em class="icon ni ni-emails"></em></div>
                        <input type="text" [formControl]="userFormControls.user_email" name="user_email" class="form-control" id="contact-email" placeholder="Enter contact email">
                        <span class="invalid" *ngIf="(userFormControls.user_email.dirty || userFormControls.user_email.touched)">
                        <span *ngIf="userFormControls.user_email.hasError('required')">{{'Email' | translate}} {{appConst.EMPTY_ERROR | translate}}</span>
                        <span *ngIf="userFormControls.user_email.hasError('email')">{{'Email should be valid' | translate}}</span>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="preview-hr">
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <button type="submit" class="btn btn-primary" data-style="zoom-in" [ladda]="isBtnLoading" [disabled]="!userManageForm.valid"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
                  </div>
                  <div class="col-sm-12">
                    <span class="text-warning">{{'Note' | translate}} :- {{'After modifying your profile, sign out and sign in so that you can see the changes.' | translate}}</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div><!-- .nk-block -->
    </div>
  </div>
</div>
