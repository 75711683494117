<div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">{{'Add Utility' | translate}}</h3>
              <div class="nk-block-des text-soft">
                <p>{{ 'Fill new utility details' | translate }}.</p>
              </div>
            </div><!-- .nk-block-head-content -->
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
                <div class="toggle-expand-content" data-content="pageMenu">
                  <ul class="nk-block-tools g-3">
                    <li class="nk-block-tools-opt"><a href="admin/utility" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-arrow-left"></em><span>{{'Back' | translate}}</span></a></li>
                  </ul>
                </div>
              </div>
            </div><!-- .nk-block-head-content -->
          </div><!-- .nk-block-between -->
        </div><!-- .nk-block-head -->



        <div class="nk-block">
            <div class="card card-bordered card-preview">
              <!-- <ngx-loading [show]="propFileLoader" [config]="{backdropBorderRadius: '3px'}"></ngx-loading> -->
              <div class="card-inner">
                <div class="preview-block">
                  <form [formGroup]="propUtilityForm" (submit)="addUtilityDetails()">
                  
                  <div class="row gy-4">                    
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="form-label" for="property">{{'Property' | translate}}</label>
                          <div class="form-control-wrap">
                              <ng-select formControlName="property_id" id="property_id" placeholder="Select Property">
                                <ng-option *ngFor = "let property of propertyData" value="{{ property.prop_id }}">{{ property.prop_name }}</ng-option>
                              </ng-select>
                            <!-- <span class="invalid" *ngIf="propUtilityForm.utility.hasError('required') && (propUtilityForm.utility.touched)"></span> -->
                         </div>
                        </div>
                      </div>
                  </div>
                  <div class="row gy-4">  
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="utility">{{ 'Utility' | translate }}</label>
                        <div class="form-control-wrap">
                          <!-- <input type="text" formControlName="utilityName" name="utilityName" class="form-control" id="utilityName" placeholder="Enter Utility name"> -->
                          <!-- <span class="invalid" *ngIf="propUtilityForm.utility.hasError('required') && (propUtilityForm.utility.touched)"></span> -->
                            <ng-select formControlName="utility" id="utility" placeholder="Select Utility" (change)="getUtilityParams()">
                              <ng-option value="Electricity">Electricity</ng-option>
                              <ng-option value="Water">Water</ng-option>
                              <ng-option value="Gas">Gas</ng-option>
                            </ng-select>
                            <!-- <span class="invalid-select" *ngIf="propFormControls.prop_city.hasError('required') && (propFormControls.prop_city.touched || propFormControls.prop_city.dirty)"> 
                              {{'City' | translate}} {{appConst.EMPTY_ERROR | translate}}
                            </span> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="board">{{'Board Name' | translate}}</label>
                        <div class="form-control-wrap">
                          <ng-select formControlName="board_name" id="board_name" placeholder="Select Board Name" (change)="fetchInfoByBoardName()">
                              <ng-option *ngFor = "let board of boardName" value="{{ board.biller_name }}">{{ board.biller_name }}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="row gy-4">                    
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="biller_Id">{{'Biller Id' | translate}}</label>
                      <div class="form-control-wrap">
                        <input type="text" [value]="biller_id" formControlName="biller_id" name="biller_id" class="form-control" id="biller_id" placeholder="Enter Biller Id">
                        <!-- <span class="invalid" *ngIf="propUtilityForm.utility.hasError('required') && (propUtilityForm.utility.touched)"></span> -->
                     </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="conum">{{'Consumer' | translate}} 1</label>
                      <div class="form-control-wrap">
                        <input type="text" [value]="consumer" formControlName="consumer" name="consumer" class="form-control" id="consumer" placeholder="Consumer">
                      </div>
                    </div>
                  </div>
              </div>

            <div class="row gy-4">                    
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="ivrs_no">{{'IVRS' | translate}}</label>
                    <div class="form-control-wrap">
                      <input type="text" [value]="ivrs_no" formControlName="ivrs" name="ivrs" class="form-control" id="ivrs" placeholder="Enter IVRS">
                      <!-- <span class="invalid" *ngIf="propUtilityForm.utility.hasError('required') && (propUtilityForm.utility.touched)"></span> -->
                   </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="mobil_no">{{'Mobile no.' | translate}}</label>
                    <div class="form-control-wrap">
                      <input type="text" formControlName="mobile_no" name="mobile_no" class="form-control" id="mobile_no" placeholder="Enter Mobile No.">
                      <!-- <span class="invalid" *ngIf="propUtilityForm.utility.hasError('required') && (propUtilityForm.utility.touched)"></span> -->
                   </div>
                  </div>
                </div>
            </div>


                <hr class="preview-hr">
              <div class="row gy-4">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-primary" data-style="zoom-in"><em class="icon ni ni-check"></em> <span>{{'Save' | translate}}</span></button>
                </div>
              </div>
            </form>
            </div>
        </div>
        



      </div>
    </div>
  </div>  
  </div>
  </div>