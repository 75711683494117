import {Component, OnInit} from '@angular/core';
import Chart from 'chart.js/auto';
import {CommonHelper} from '../../app-config/common-helper';
import {CommonService} from '../../services/common.service';
import {AppConstants} from '../../app-config/app-constants';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';

declare function $(str:string): any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  constructor(
    public commonHelper: CommonHelper,
    public commonService: CommonService,
    public appConstants: AppConstants,
    public datePipe: DatePipe,
    public ngxSpinner: NgxSpinnerService,
    public translate: TranslateService
  ) { translate.setDefaultLang("en"); }
  lastMonthValue: number = 1;
  tenantActiveCount: any = 0;
  propertyActiveCount: any = 0;
  totalRentCollection: number = 0;
  lastMonthFilter: string = 'Last 30 Days';
  localUser: any = JSON.parse(localStorage.getItem("userData"));
  rentCollection: any = Chart;
  propertyActiveGraph: any = Chart;
  tenantActiveGraph: any = Chart;
  lastFiveTransaction: any = this.appConstants.API_RESPONSE;
  tenantsList: any = this.appConstants.API_RESPONSE;

  ngOnInit(): void {
    this.ngxSpinner.show();
    // this.translate.use(localStorage.getItem("lang"));
    this.loadPropertyTenantsTabs(5);
    this.loadRentCollectionPieChart(1);
    this.loadRentYearChart(this.appConstants.CURRENT_YEAR);
    this.fetchLastFiveTransaction();
    this.fetchTenants('view');
    this.ngxSpinner.hide();
  }
  reloadDashboardGraphs(lastMonth: number, lastMonthString: string) {
    // this.propertyActiveGraph.destroy();
    // this.tenantActiveGraph.destroy();
    // this.loadPropertyTenantsTabs(lastMonth);
    this.rentCollection.destroy();
    this.loadRentCollectionPieChart(lastMonth);
    this.lastMonthFilter = lastMonthString;
  }
  loadPropertyTenantsTabs(lastMonth: number) {
    const labels = this.commonHelper.getLastMonthArrayByCount(lastMonth);
    const backgroundColor = this.commonHelper.graphBackgroundColor(lastMonth);
    const borderColor = this.commonHelper.graphBorderColor(lastMonth);

    const tenantData: any = {
      labels: labels,
      stacked: true,
      datasets: [{
        label: 'Active Tenants',
        data: [],
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      }]
    };
    const tenantConfig:any =  {
      type: 'bar',
      data: tenantData,
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false
          },
          x: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      },
    };
    const propertyData: any = {
      labels: labels,
      stacked: true,
      datasets: [{
        label: 'Active Properties',
        data: [],
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      }]
    };
    const propertyConfig:any =  {
      type: 'bar',
      data: propertyData,
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false
          },
          x: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      },
    };
    const paramData: any = {
        dbname: this.appConstants.DATABASE,
        tenantOwnerId: this.localUser.userid,
        lastMonth: lastMonth
    };
    // console.log("loadPropertyTenantsTabs paramData:", paramData);
    this.commonService.fetchTenantPropertyGraphData(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("loadPropertyTenantsTabs :", apiResponse);
        this.tenantActiveCount = apiResponse.data.tenant;
        this.propertyActiveCount = apiResponse.data.property;
        console.log("tenantGraphData :", apiResponse.data.tenantGraphData);
        console.log("propertyGraphData :", apiResponse.data.propertyGraphData);
        tenantData.datasets[0].data = apiResponse.data.tenantGraphData;
        tenantConfig.data = tenantData;
        const tenantCanvas: any = document.getElementById('tenant_count_tab');
        const tenantCanvasCtx: any = tenantCanvas.getContext('2d');
        this.tenantActiveGraph = new Chart(tenantCanvasCtx, tenantConfig);
        //****************Properties Graph********************************//
        propertyData.datasets[0].data = apiResponse.data.propertyGraphData;
        propertyConfig.data = propertyData;
        const propertyCanvas: any = document.getElementById('property_count_tab');
        const propertyCanvasCtx: any = propertyCanvas.getContext('2d');
        this.propertyActiveGraph = new Chart(propertyCanvasCtx, propertyConfig);
      },
      Error => {
        console.log("loadPropertyTenantsTabs Error :", Error);
      }
    );


  }
  loadRentCollectionPieChart(lastMonth: number) {
    const data: any = {
      labels: [
        'Collected',
        'Not Collected'
      ],
      datasets: [{
        label: 'Rent Collection Data',
        data: [0, 0],
        backgroundColor: [
          'rgba(115, 41, 154, 0.49)',
          'rgb(54, 162, 235)'
        ]
      }]
    };
    const config: any = {
      type: 'doughnut',
      data: data,
      options: {

      }
    };
    const paramData: any = {
      dbname: this.appConstants.DATABASE,
      tenantOwnerId: this.localUser.userid,
      lastMonth: lastMonth
    };
    this.commonService.fetchRentCollectionPieGraphData(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        console.log("loadRentYearChart :", apiResponse);
        const total_rent_count = apiResponse.data[0].total_rent_count;
        config.data.datasets[0].data = [total_rent_count, parseInt(this.tenantActiveCount)-parseInt(total_rent_count)];
        const canvas: any = document.getElementById('rent_collection_pie');
        const canvasCtx: any = canvas.getContext('2d');
        // console.log("rentCollection :", this.rentCollection);
        this.rentCollection = new Chart(canvasCtx, config);
      },
      Error => {
        console.log("loadRentYearChart Error :", Error);
      }
    );
  }
  loadRentYearChart(year: number) {
    const labels = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const data = {
      labels: labels,
      datasets: [{
        label: 'Rent',
        data: [0, 0, 0, 0, 0, 0, 0],
        fill: false,
        borderColor: 'rgba(115, 41, 154, 0.49)',
        tension: 0.1
      }]
    };
    const config: any = {
      type: 'line',
      data: data,
    };
    const paramData: any = {
      dbname: this.appConstants.DATABASE,
      tenantOwnerId: this.localUser.userid
    };
    this.commonService.fetchRentCollectionGraphData(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        // console.log("loadRentYearChart :", apiResponse);
        config.data.datasets[0].data = apiResponse.data.data;
        this.totalRentCollection = apiResponse.data.total_amount;
        const canvas: any = document.getElementById('rent_collection_line_chart');
        const canvasCtx: any = canvas.getContext('2d');
        new Chart(canvasCtx, config);
      },
      Error => {
        console.log("loadRentYearChart Error :", Error);
      }
    );
  }
  fetchLastFiveTransaction() {
    const paramData: any = {
      dbname: this.appConstants.DATABASE,
      tenantOwnerId: this.localUser.userid
    };
    this.commonService.fetchLastFiveTransaction(paramData).subscribe(
      Response => {
        this.lastFiveTransaction = Response;
        console.log("lastFiveTransaction :", this.lastFiveTransaction);
      },
      Error => {
        console.log("loadRentYearChart Error :", Error);
      }
    );
  }
  fetchTenants (type='view') {
    const paramData: any = {
      dbname: this.appConstants.DATABASE,
      type: type,
      limitData: 5
    }
    if (this.localUser.user_role == "OWNER")
      paramData.ownerId = this.localUser.userid;
    else if (this.localUser.user_role == "TENANT")
      paramData.tenantId = this.localUser.tenant_id;
    this.ngxSpinner.show();
    this.commonService.fetchTenantsService(paramData).subscribe(
      Response => {
        const apiResponse: any = Response;
        this.tenantsList = apiResponse;
        console.log("tenantsList :", this.tenantsList);
        this.ngxSpinner.hide();
      },
      Error => {
        console.log("fetchTenants :", Error);
        this.ngxSpinner.hide();
      }
    );
  }
}
