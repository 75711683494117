<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Properties' | translate}}</h3>
            <div class="nk-block-des text-soft">
              <p *ngIf="propertiesList">{{ 'You have total' | translate}} {{propertiesList.numRows}} {{'have properties' | translate}}.</p>
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3" *ngIf="localUser.user_role == 'ROOT' || localUser.user_role == 'OWNER'">
                  <li class="nk-block-tools-opt"><a href="javascript:void(0)" (click)="fetchProperties('excel')" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-download"></em><span>{{'Export' | translate}}</span></a></li>
                  <li class="nk-block-tools-opt"><a href="admin/addProperty/{{commonHelper.encodeURIParam(0)}}" class="btn btn-primary"><em class="icon ni ni-plus"></em><span>{{'New' | translate}} {{'Property' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->
      <div class="nk-block">
        <div class="card card-bordered card-preview">
            <div class="card-inner">
              <table class="nk-tb-list nk-tb-ulist">
                <thead>
                  <tr class="nk-tb-item nk-tb-head">
                    <th class="nk-tb-col"><span class="sub-text">{{'Name' | translate}}</span></th>
<!--                    <th class="nk-tb-col tb-col-md"><span class="sub-text">{{'Contact No' | translate}}</span></th>-->
                    <th class="nk-tb-col tb-col-lg"><span class="sub-text">{{'Address' | translate}}</span></th>
                    <th class="nk-tb-col tb-col-md"><span class="sub-text">{{'Status' | translate}}</span></th>
                    <th class="nk-tb-col tb-col-md"><span class="sub-text">{{'Occupancy' | translate}}</span></th>
                    <th class="nk-tb-col nk-tb-col-tools text-right">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="nk-tb-item" *ngFor="let property of propertiesList.data">
                    <td class="nk-tb-col">
                      <div class="user-card">
                        <div class="user-avatar {{commonHelper.getThumbnailBG(property.prop_name)}} d-none d-sm-flex">
                          <span>{{commonHelper.getNameThumbnail(property.prop_name)}}</span>
                        </div>
                        <div class="user-info">
                          <span class="tb-lead">{{property.prop_name}}
                            <span class="dot dot-success d-md-none ml-1" *ngIf="property.prop_is_active == 1"></span>
                            <span class="dot dot-danger d-md-none ml-1" *ngIf="property.prop_is_active == 0"></span>
                          </span>
                          <span>{{property.prop_contact_email}}</span>
                        </div>
                      </div>
                    </td>
                    <!--<td class="nk-tb-col tb-col-mb">
                      <span>{{property.prop_contact_no}}</span>
                    </td>-->
                    <td class="nk-tb-col tb-col-md">
                      <span>{{property.prop_address_1 +","+ property.prop_address_2 +"-"+property.prop_pincode}}</span>
                    </td>
                    <td class="nk-tb-col tb-col-md">
                      <span class="tb-status text-success" *ngIf="property.prop_is_active == 1">Active</span>
                      <span class="tb-status text-danger" *ngIf="property.prop_is_active == 0">Inactive</span>
                    </td>
                    <td class="nk-tb-col tb-col-md">
                      <span class="tb-status text-success" *ngIf="property.prop_status == 'VACANT' else occupStatusElse">{{property.prop_status | translate}}</span>
                      <ng-template #occupStatusElse>
                        <span class="tb-status">{{property.tenant_name | translate}}</span>
                      </ng-template>
                    </td>
                    <td class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                          <div class="drodown">
                            <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <ul class="link-list-opt no-bdr">
                                <li><a href="admin/viewProperty/{{commonHelper.encodeURIParam(property.prop_id)}}"><em class="icon ni ni-eye"></em><span>{{'View Details' | translate}}</span></a></li>
                                <li *ngIf="localUser.user_role == 'ROOT' || localUser.user_role == 'OWNER'"><a href="admin/addProperty/{{commonHelper.encodeURIParam(property.prop_id)}}"><em class="icon ni ni-edit"></em><span>{{'Edit Details' | translate}}</span></a></li>
                                <li class="divider" *ngIf="localUser.user_role == 'ROOT' || localUser.user_role == 'OWNER'"></li>
                                <li><a href="javascript:void(0)" (click)="openAddCareTakerPopup(property.prop_id)"><em class="icon ni ni-user-add"></em><span>{{'Assign Care Taker' | translate}}</span></a></li>
                                <li *ngIf="property.tenant_id == null else assignTenantElse"><a href="javascript:void(0)" (click)="openAssignTenantPopup(property.prop_id, 'assign', property)"><em class="icon ni ni-user-check"></em><span>{{'Assign Tenant' | translate}}</span></a></li>
                                <ng-template #assignTenantElse><li><a href="javascript:void(0)" (click)="openAssignTenantPopup(property.prop_id, 'unassign', property)"><em class="icon ni ni-user-cross"></em><span>{{'Un-assign Tenant' | translate}}</span></a></li></ng-template>
                                <li class="divider" *ngIf="localUser.user_role == 'ROOT' || localUser.user_role == 'OWNER'"></li>
<!--                                <li *ngIf="localUser.user_role == 'ROOT' || localUser.user_role == 'OWNER'"><a href="javascript:void(0)" (click)="deleteProperty(property.prop_id)"><em class="icon ni ni-delete"></em><span>{{'Delete' | translate}}</span></a></li>-->
                                <li *ngIf="localUser.user_role == 'ROOT' || localUser.user_role == 'OWNER'"><a href="javascript:void(0)" *ngIf="property.prop_is_active == 1" (click)="changePropertyStatus(0, property.prop_id)"><em class="icon ni ni-property-remove"></em><span>{{'Deactivate' | translate}}</span></a></li>
                                <li *ngIf="localUser.user_role == 'ROOT' || localUser.user_role == 'OWNER'"><a href="javascript:void(0)" *ngIf="property.prop_is_active == 0" (click)="changePropertyStatus(1, property.prop_id)"><em class="icon ni ni-property-add"></em><span>{{'Activate' | translate}}</span></a></li>
                              </ul>
                            </div>
                          </div>
                      </ul>
                    </td>
                  </tr><!-- .nk-tb-item  -->
                </tbody>
              </table>
            </div><!-- .card-inner -->
        </div><!-- .card -->
      </div><!-- .nk-block -->
    </div>
  </div>
</div>


