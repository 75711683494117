import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { SidebarComponent } from './admin/sidebar/sidebar.component';
import {DialogProfilePictureChange, HeaderComponent} from './admin/header/header.component';
import { StartPageComponent } from './start-page/start-page.component';
import { LoginComponent } from './start-page/login/login.component';
import { SignUpComponent } from './start-page/sign-up/sign-up.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AppConstants } from './app-config/app-constants';
import { DbTables } from './app-config/db-tables';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import { Md5 } from 'ts-md5';
import { CommonHelper } from './app-config/common-helper';
import { LaddaModule } from 'angular2-ladda';
import { ForgotPasswordComponent } from './start-page/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './start-page/forgot-password/reset-password.component';
import { VerifyUserComponent } from './start-page/sign-up/verify-user.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AddCareTakerComponent,
  AddPropertyComponent, AssignTenantComponent,
  PropertiesComponent,
  ViewPropertyComponent
} from './admin/properties/properties.component';
import { FooterComponent } from './admin/footer/footer.component';
import { NgxLoadingModule } from 'ngx-loading';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import {Error404Component, ErrorComponent, ErrorUnderDevelopmentComponent} from './admin/error/error.component';
import {AddTenantComponent, TenantsComponent, ViewTenantComponent} from './admin/tenants/tenants.component';
import {DatePipe} from '@angular/common';
import { UserProfileComponent } from './admin/user-profile/user-profile.component';
import {MatDialogModule} from '@angular/material/dialog';
import {AddExpenseComponent, ExpenseComponent, ViewExpenseComponent} from './admin/expense/expense.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { TestAppComponent } from './admin/test-app/test-app.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AddRentComponent, RentComponent, SendPaymentLinkPopup, ViewRentComponent} from './admin/rent/rent.component';
import {ChangePasswordPopup, SettingsComponent} from './admin/settings/settings.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatButtonModule} from '@angular/material/button';
import {AgmCoreModule} from '@agm/core';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import {SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider} from 'angularx-social-login';
import { UtilityComponent,AddUtilityComponent } from './admin/utility/utility.component';
import { SafePipe } from './pipes/safe.pipe';
import { MainPipe } from './main-pipe.module';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    SidebarComponent,
    HeaderComponent,
    LoginComponent,
    StartPageComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyUserComponent,
    PropertiesComponent,
    FooterComponent,
    AddPropertyComponent,
    ViewPropertyComponent,
    ErrorComponent,
    Error404Component,
    TenantsComponent,
    AddTenantComponent,
    ViewTenantComponent,
    UserProfileComponent,
    DialogProfilePictureChange,
    ExpenseComponent,
    TestAppComponent,
    AddExpenseComponent,
    ViewExpenseComponent,
    RentComponent,
    AddRentComponent,
    ViewRentComponent,
    SettingsComponent,
    ChangePasswordPopup,
    ErrorUnderDevelopmentComponent,
    AddCareTakerComponent,
    AssignTenantComponent,
    DashboardComponent,
    SendPaymentLinkPopup,
    UtilityComponent,
    AddUtilityComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    LaddaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatSelectModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgxLoadingModule.forRoot({}),
    GalleryModule.forRoot(),
    MatDialogModule,
    MatDatepickerModule, MatNativeDateModule,
    NgxSpinnerModule,
    NgbModule, MatButtonModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDJI_hu_PP9VQWeaWpavrvS1wZ3OUFuIYo"
    }),
    SocialLoginModule,
    MainPipe
  ],
  providers: [
    AppConstants,
    DbTables,
    Md5,
    CommonHelper,
    DatePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '126683466139-u21c08ehs8cqge8k9tvcigc1tk7gbgks.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2495376803930295')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    SafePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
