import { Component, OnInit } from '@angular/core';
import {CommonService} from '../services/common.service';
import {AppConstants} from '../app-config/app-constants';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  public data1: number[] = [1,2,3];

  constructor(
    public commonService: CommonService,
    public appConst:AppConstants,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.use(localStorage.getItem("lang"));
    const userData: any = JSON.parse(localStorage.getItem("userData"));
    // console.log("Local userData :", userData);
    if (userData == null) {
      Swal.fire("", this.translate.instant("Your session is not valid!"),"warning").then((r)=>{
        localStorage.clear();
        window.location.href = "/";
      });
    }
    this.checkUserIsAuthenticated();
  }
  checkUserIsAuthenticated() {
    console.log("Calling Admin.....");
    this.commonService.checkUserIsAuthenticatedService({dbname: this.appConst.DATABASE}).subscribe(
      Response => {
        const apiResponse: any = Response;
        // console.log("Admin apiResponse :", apiResponse);
        if (apiResponse.opcode === 1997) {
          Swal.fire("", this.translate.instant(apiResponse.message),"warning").then((r)=>{
            localStorage.clear();
            window.location.href = "/";
          });
        }
      },
      Error => {
        Swal.fire("", this.translate.instant("Connection Error"),"warning").then((r)=>{
          localStorage.clear();
          window.location.href = "/";
        });
      }
    );
  }
  getDataFromChild(event) {
    console.log("event :::", event);
  }
}
