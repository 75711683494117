import { Injectable } from '@angular/core';
import {AppConstants} from '../app-config/app-constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private appConstant: AppConstants, private http: HttpClient) { }

  loginAuthentication(username, password) {
    return this.http.post(this.appConstant.API_END+"auth/loginUser", {username: username, password: password});
  }
  getAuthHeader() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
    });
    return headers;
  }
  fetchUserData(paramData) {
    return this.http.post(this.appConstant.API_END+"common/fetch", paramData);
  }
  signUpUser(paramData) {
    return this.http.post(this.appConstant.API_END+"auth/signupUser", paramData);
  }
  sendForgotPasswordService(paramData) {
    return this.http.post(this.appConstant.API_END+"auth/forgotPassword", paramData);
  }
  resetPasswordService(paramData) {
    return this.http.post(this.appConstant.API_END+"auth/resetPassword", paramData);
  }
  verifyUser(paramData) {
    return this.http.post(this.appConstant.API_END+"auth/verifyUser", paramData);
  }
  socialLoginAuthentication(paramData) {
    return this.http.post(this.appConstant.API_END+"auth/loginUserSocial", paramData);
  }
}
