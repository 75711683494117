<div class="card-inner card-inner-lg">
  <div class="nk-block-head">
    <div class="nk-block-head-content">
      <h4 class="nk-block-title">{{'Sign-In' | translate}}</h4>
      <div class="nk-block-des">
        <p>Access the RENTALFLO portal using your email and password.</p>
      </div>
    </div>
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="userLogin()">
    <div class="form-group">
      <div class="form-label-group">
        <label class="form-label" for="useremail">Email</label>
      </div>
      <input type="text" [formControl]="loginFormControl.userName" name="userName" class="form-control form-control-lg" id="useremail" placeholder="Enter your email address">
      <span id="fv-useremail-error" class="invalid" *ngIf="loginFormControl.userName.hasError('required') && loginFormControl.userName.touched">Username is <strong>required</strong></span>
    </div>
    <div class="form-group">
      <div class="form-label-group">
        <label class="form-label" for="password">Password</label>
        <a class="link link-primary link-sm" (click)="route.navigateByUrl('forgot')">Forgot Password?</a>
      </div>
      <div class="form-control-wrap">
        <a class="form-icon form-icon-right passcode-switch" data-target="password">
          <em class="passcode-icon icon-show icon ni ni-eye"></em>
          <em class="passcode-icon icon-hide icon ni ni-eye-off"></em>
        </a>
        <input type="password" [formControl]="loginFormControl.password" name="password" class="form-control form-control-lg" id="password" placeholder="Enter your passcode">
        <span id="fv-password-error" class="invalid" *ngIf="loginFormControl.password.hasError('required') && loginFormControl.password.touched">Password is <strong>required</strong></span>
      </div>
    </div>
    <div class="form-group" style="display: none;">
      <div class="form-label-group">
        <label class="form-label">Language</label>
      </div>
      <div class="form-control-wrap">
          <ng-select [formControl]="loginFormControl.language" name="language" [searchable]="false">
              <ng-option *ngFor="let lang of langList" [value]="lang.id">{{lang.name}}</ng-option>
          </ng-select>
      </div>
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-lg btn-primary btn-block" [disabled]="!loginForm.valid">Sign in</button>
    </div>
  <span *ngIf="googleFlag===true">
    <div class="text-center pb-3">
      <h6 class="overline-title overline-title-sap"><span>OR</span></h6>
    </div>
    <ul class="nav justify-center gx-4">
<!--      <li class="nav-item" data-toggle="tooltip" data-placement="top" title="Facebook"><button type="button" (click)="loginWithGoogle('FACEBOOK')" class="btn btn-round btn-icon" style="background-color: #3B5998;color: white;"><em class="icon ni ni-facebook-f"></em></button></li>-->
      <li class="nav-item"><a class="btn btn-block btn-social btn-lg btn-google" (click)="loginWithGoogle('GOOGLE')"><i class="icon ni ni-google"></i>Sign in with Google</a></li>
    </ul>
  </span>
  </form>
  <div class="form-note-s2 text-center pt-4"> New on our platform? <a (click)="openSignupPage()">Create an account</a>
  </div>
</div>
