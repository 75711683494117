<!-- <span class="d-md-block">
    <button type="button" data-style="zoom-in" (click)="gotoUtilityPayment()" class="btn btn-primary"><em class="icon ni ni-check"></em> <span>{{'Go' | translate}}</span></button>
  </span> -->
<div class="container-fluid">
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">{{'Utility' | translate}}</h3>
            <div class="nk-block-des text-soft">
              
            </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
            <div class="toggle-wrap nk-block-tools-toggle">
              <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
              <div class="toggle-expand-content" data-content="pageMenu">
                <ul class="nk-block-tools g-3" *ngIf="localUser.user_role == 'ROOT' || localUser.user_role == 'OWNER'">
                  <li class="nk-block-tools-opt"><a href="javascript:void(0)" (click)="fetchProperties('excel')" class="btn btn-white btn-dim btn-outline-light"><em class="icon ni ni-download"></em><span>{{'Show History' | translate}}</span></a></li>
                  <li class="nk-block-tools-opt"><a href="admin/addUtility/{{commonHelper.encodeURIParam(0)}}" class="btn btn-primary"><em class="icon ni ni-plus"></em><span>{{'Add Utility' | translate}}</span></a></li>
                </ul>
              </div>
            </div>
          </div><!-- .nk-block-head-content -->
        </div><!-- .nk-block-between -->
      </div><!-- .nk-block-head -->

      <div class="nk-block">
        <div class="card card-bordered card-preview">
            <div class="card-inner">

              <table class="nk-tb-list nk-tb-ulist">
                <thead>
                  <tr class="nk-tb-item nk-tb-head">
                    <th class="nk-tb-col"><span class="sub-text">{{'Sr no.' | translate}}</span></th>
                    <th class="nk-tb-col"><span class="sub-text">{{'Property Name' | translate}}</span></th>
                    <th class="nk-tb-col tb-col-lg"><span class="sub-text">{{'Utility Type' | translate}}</span></th>
                    <th class="nk-tb-col tb-col-md"><span class="sub-text">{{'Biller Id' | translate}}</span></th>
                    <th class="nk-tb-col tb-col-md"><span class="sub-text">{{'Mobile no.' | translate}}</span></th>
                    <th class="nk-tb-col nk-tb-col-tools">{{'Provider' | translate}}</th>
                    <th class="nk-tb-col nk-tb-col-tools">{{'Action' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ut of utilityList; let sno = index;" class="nk-tb-item">
                    <td class="nk-tb-col">{{ sno+1 }}</td>
                    <td class="nk-tb-col">{{ ut.prop_name }}</td>
                    <td class="nk-tb-col">{{ ut.utility_type }}</td>
                    <td class="nk-tb-col">{{ ut.biller_id }}</td>
                    <td class="nk-tb-col">{{ ut.mobile_no }}</td>
                    <td class="nk-tb-col">{{ ut.provider }}</td>
                    <td class="nk-tb-col"><button type="button" class="btn btn-primary" data-style="zoom-in" [ladda]="isBtnLoading" (click)="getDataForUtilityAmount(ut.ut_id)"><em class="icon ni ni-check"></em> <span>{{'Pay' | translate}}</span></button></td>
                  </tr>
                </tbody>
              </table>


            </div>
        </div>
      </div>


    </div>
  </div>
</div>

 
  